@import "../../Animation.scss";
@import "../../Layout.scss";

.modal {
    width: 576px;
    margin: auto;
    padding: 32px;
    box-sizing: border-box;
    background: rgb(30, 30, 30);

    @include mobile {
        width: 100%;
        max-width: 576px;
    }

    h1 {
        margin: 0px;

        @include design {
            color: var(--color-main);
            font-family: "Bebas Neue";
            font-size: 32px;
            font-weight: bold;
            letter-spacing: 0.78px;
            line-height: 38px;
        }
    }

    button.close img {
        width: 12px;
        height: 12px;
    }

    .content {
        @include grid($cols: 1fr 1fr, $rows: auto 1fr);
        gap: 16px;
        grid-template-areas:
            "search search"
            "back save";

        .search {
            @include in-grid($horizontal: stretch, $col-span: 2);
            grid-area: search;
            padding: 32px 0;

            input {
                background: #161616;
                border: none;
                box-sizing: border-box;
                padding: 10px 4px 10px 10px;
                width: 100%;

                @include design {
                    color: #FFFFFF;
                    font-family: Roboto;
                    font-size: 1em;
                    font-weight: 300;
                    letter-spacing: 0.23px;
                    line-height: 24px;
                }
            }

            .suggestions {
                background: #fff;

                .item {
                    color: var(--color-text-1);
                    cursor: pointer;
                    line-height: 24px;
                    padding: 0 16px;

                    &.active {
                        background: var(--color-stroke);
                    }
                }
            }
        }

        button {
            @include fast-transition();

            height: 44px;
            border-radius: 2px;

            &.back {
                @include in-grid($horizontal: stretch, $vertical: stretch, $row-start: 3);
                border: 1px solid rgb(98, 98, 98);
                grid-area: back;
                @include design {
                    color: #FFFFFF;
                }
            }

            &.save {
                @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 3);
                background: rgb(255, 205, 0);
                grid-area: save;
                @include design {
                    color: #000000;
                }
            }

            img {
                vertical-align: middle;
                margin: 1.5px 0px 0px 0px;
            }

            .text {
                &:not(:first-child) {
                    margin: 0px 0px 0px 8px;
                }
                vertical-align: middle;
                @include design {
                    font-family: Roboto;
                    font-size: 14px;
                    letter-spacing: 0.2px;
                    line-height: 16px;
                    text-align: center;
                }
            }
        }
    }
}
