:root {
    --color-main: #FFCD00;
    --color-bg: #FAFAFA;
    --color-stroke: #E3E7EC;
    --color-field-default: #909090;
    --color-text-1: #484848;
    --color-text-2: #484848;
    --color-text-3: #909090;
    --color-black: #1E1E1E;
}

@mixin grid-cols($cols: 1fr) {
    \-ms-grid-columns: $cols;
    grid-template-columns: $cols;
}

@mixin grid-rows($rows: 1fr) {
    \-ms-grid-rows: $rows;
    grid-template-rows: $rows;
}

@mixin grid($cols: 1fr, $rows: 1fr) {
    display: -ms-grid;
    \-ms-grid-columns: $cols;
    \-ms-grid-rows: $rows;
    display: grid;
    grid-template-columns: $cols;
    grid-template-rows: $rows;
}

@mixin in-grid-col($col-start: 1, $col-span: 1) {
    \-ms-grid-column: $col-start;
    \-ms-grid-column-span: $col-span;
    grid-column: $col-start / span $col-span;
}

@mixin in-grid-row($row-start: 1, $row-span: 1) {
    \-ms-grid-row: $row-start;
    \-ms-grid-row-span: $row-span;
    grid-row: $row-start / span $row-span;
}

@mixin in-grid-align($horizontal: null, $vertical: null) {
    \-ms-grid-column-align: $horizontal;
    justify-self: $horizontal;
    \-ms-grid-row-align: $vertical;
    align-self: $vertical;
}

@mixin in-grid($horizontal: center, $vertical: center, $col-start: 1, $col-span: 1, $row-start: 1, $row-span: 1) {
    \-ms-grid-column: $col-start;
    \-ms-grid-column-span: $col-span;
    \-ms-grid-row: $row-start;
    \-ms-grid-row-span: $row-span;
    \-ms-grid-column-align: $horizontal;
    \-ms-grid-row-align: $vertical;
    grid-column: $col-start / span $col-span;
    grid-row: $row-start / span $row-span;
    justify-self: $horizontal;
    align-self: $vertical;
}

@mixin mobile {
    @media only screen and (max-width: 600px) {
        @content;
    }
}

@mixin mobile-s {
    @media only screen and (max-width: 374px) {
        @content;
    }
}

@mixin not-mobile {
    @media only screen and (min-width: 601px) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (max-width: 1024px) {
        @content;
    }
}

@mixin sm-screen {
    @media only screen and (max-width: 1280px) {
        @content;
    }
}

@mixin design {
    @content;
}
