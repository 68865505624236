.pdf-viewer {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  background: #171717 url('./texture.png');

  .react-pdf {
    &__Document {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: transparent;
      overflow: hidden;
    }

    &__Page {
      max-width: 100%;
      box-shadow: 0 0 8px rgba(0, 0, 0, .15);
      margin: 1em;

      canvas {
        max-width: 100%;
        height: auto !important;
      }
    }

    &__message {
      padding: 20px;
      color: white;
    }
  }
}
