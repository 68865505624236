@import "../../Layout.scss";
@import "../../Animation.scss";

.page {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 1, $col-span: 3, $row-start: 2);
}

.orders {
    @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: auto 1fr);

    position: relative;
    background: rgb(250, 250, 250);

    @include mobile {
        @include grid($cols: auto 1fr auto, $rows: auto auto 1fr);
    }

    .tableHeaderRow {
        background: #fafafa;
        border-bottom: 1px solid #e7e7e7;
        border-top: 1px solid #e7e7e7;
        box-sizing: border-box;

        &:global(.background) {
            width: 100%;
        }

        &.fixed {
            position: fixed;
            top: 152px;
            @include mobile {
                top: 132px;
                width: 1024px;
            }
            &.background {
                left: 0px;
                right: 0px;
            }
            &.animated {
                @include fast-transition(transform, opacity);
            }
            &.alt {
                transform: translateY(-72px);
                @include mobile {
                    transform: translateY(-52px);
                }
            }
        }

        &.hidden {
            display: none;
        }
    }

    :global(.table-header-row) {
        &.hidden {
            display: none;
        }
    }

    .headerPlaceholder {
        @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3);
        height: 72px;
        background: white;
        @include mobile {
            height: 52px;
        }
    }

    .headerStatic {
        @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: 1fr);
        @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3);
        @include mobile {
            @include grid($cols: auto 1fr auto, $rows: 1fr);
        }

        box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.05);
        z-index: 2;

        &.fixed {
            position: fixed;
            position: sticky; //override with sticky on browsers which support it
            left: 0px;
            right: 0px;
            top: 80px;
            &.animated {
                @include fast-transition(transform);
            }
            &.hiding {
                transform: translateY(-100%);
            }
        }

        .background {
            @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3);
            background: white;
        }

        .header {
            @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2);
            height: 72px;
            padding: 0px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include mobile {
                height: auto;
            }

            .items {
                display: flex;
                align-items: center;

                @include mobile {
                    display: none;
                }

                &.left {
                    justify-content: flex-start;
                }

                &.right {
                    justify-content: flex-end;
                }
            }
        }
    }
}

.headerMobileFilters {
    @include mobile {
        @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 2);
    }
}

.bodyBackground {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3, $row-start: 2);
    display: flex;
    flex-direction: column;
}

.body {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 2);
    display: flex;
    flex-direction: column;
    z-index: 1;
}

.loader {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 2);
}

.pagination {
    margin-bottom: 48px;

    @include mobile {
        margin-bottom: 24px;
    }
}

.filterItems {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include default-transition();

    .enter {
        opacity: 0.01;
        max-width: 0px;

        &.filter-enter-active {
            opacity: 1;
            max-width: 256px;
            @include default-transition();
        }
    }

    .exit {
        opacity: 1;
        max-width: 256px;

        &.filter-exit-active {
            opacity: 0.01;
            max-width: 0px;
            padding: 0px;
            @include default-transition();
        }
    }
}
