@import "../../../Layout.scss";
@import "../../../Animation.scss";

.virtual_family_page-header {
    @include grid($cols: 1fr, $rows: 1fr);
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2);
    height: 80px;
    box-sizing: border-box;
    padding: 12px 0px 20px 0px;
    background: #ffcd00;
    z-index: 1;
    @include mobile {
        padding: 16px;
    }
}

.virtual_family_page-header-title {
    @include in-grid($horizontal: start);
    margin: 0px;
    background: none;
    border: none;
    user-select: none;
    box-sizing: border-box;
    text-decoration: none;
    white-space: nowrap;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 40px;
        font-weight: 300;
        letter-spacing: 0.8px;
        line-height: 48px;
    }
    cursor: default;
    &.clickable {
        cursor: pointer;
    }
    &:hover {
        .virtual_family_page-header-title-img {
            opacity: 0.9;
        }
    }
    @include fast-transition();
}

.virtual_family_page-header-title-img {
    @include in-grid();
    width: 0px;
    margin: 0px;
    vertical-align: middle;
    transform: rotateZ(90deg);
    opacity: 0.3;
    &.visible {
        width: 14px;
        height: 14px;
        vertical-align: text-top;
        margin: 14px 8px;
    }
    @include fast-transition();
}

.virtual_family_page-header-tabs {
    @include in-grid($horizontal: center);
    @include mobile {
        @include in-grid($horizontal: end);
    }
}

.virtual_family_page-header-actions {
    height: 48px;
    display: flex;
    align-items: center;
    @include default-transition();
    min-width: 0%;
    &.open {
        min-width: 100%;
        .virtual_family_page-header-actions-spacer {
            display: none;
        }
        .virtual_family_page-header-actions-new_button {
            display: none;
        }
    }
    @include mobile {
        display: none;
    }
}

.virtual_family_page-header-actions-spacer {
    width: 1px;
    height: 24px;
    background: rgba(0, 0, 0, 0.25);
    margin: 0px 24px 0px 24px;
    @include mobile {
        display: none;
    }
}

.virtual_family_page-header-actions-new_button {
    height: 24px;
    white-space: nowrap;
    @include mobile {
        display: none;
    }
}

.virtual_family_page-header-actions-new_button-img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
}

.virtual_family_page-header-actions-new_button-text {
    margin: 0px 0px 0px 8px;
    vertical-align: middle;
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
    }
}

.virtual_family_page-header-actions {
    @include in-grid($horizontal: end);
}