@import '../../../Layout.scss';
@import '../../../Animation.scss';

.electricity-page-contracts{
  .electricity-metering-points{
    .electricity-metering-points-checkbox{
      transition: background-color $fast-animation-speed $default-animation-curve, border-color $fast-animation-speed $default-animation-curve;
      background-color: white;
      border: 2px solid #dddddd;
      border-color: #dddddd;
      border-radius: 2px;
      width: 28px;
      height: 28px;
      position: relative;
      @include mobile() {
        display: none;
      }
      .checkmark-circle{
        transition: opacity $fast-animation-speed $default-animation-curve;
        opacity: 0;
        border: 2px solid #ffcd00;
        width: 20px;
        height: 20px;
        box-sizing: border-box;
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .checkmark-img{
        transition: opacity $fast-animation-speed $default-animation-curve;
        opacity: 0;
        width: 12px;
        height: 9px;
        box-sizing: border-box;
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -51%);
        background-image: url("../../../assets/Icon_Check_Yellow-16px.svg");
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    .electricity-metering-points-checkbox.checked{
      background-color: black;
      border-color: black;
      .checkmark-circle{
        opacity: 1;
      }
      .checkmark-img{
        opacity: 1;
      }
    }
    .electricity-metering-points-header{
      display: flex;
      flex-direction: row;

      .electricity-metering-points-title{
        padding-left: 16px;
        padding-right: 16px;
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 1.25em;
        line-height: 32px;
        font-weight: 600;
        letter-spacing: 0.8px;

        @include mobile() {
          padding-left: 0;
        }
      }
      .electricity-metering-points-count{
        background-color: #e7e7e7;
        border-radius: 2px;
        width: 32px;
        height: 32px;
        position: relative;
        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #000000;
          font-family: "Bebas Neue";
          font-size: 1.25em;
          font-weight: 600;
        }
      }
    }
  }
  .co-expandable-content_title{
    @include mobile() {
      padding: 4px 0;
    }
  }

  .co-expandable-content_content{
    @include mobile() {
      padding-bottom: 0;
    }
  }

  .co-expandable-content_content-clipping {
    @include mobile() {
      //width: calc(100% - 32px);
      padding: 0;
      transform: translateX(0);
    }
  }
}

.electricity-body-metering-points{
  @include mobile() {
    padding-bottom:0 !important;
  }
}
