@import '../../../Layout.scss';
@import '../../../Animation.scss';

.contract-item {
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    margin: 12px 0px 0px 0px;
    background-color: white;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
    display: flex;
    flex-direction: column;
    border: 2px solid transparent;
    border-radius: 3px;
    max-width: 1020px; //due to 2px border
    .contract-item-header {
        display: flex;
        flex-direction: row;
        padding: 24px;
        cursor: pointer;

        .contract-item-icon{
            width: 56px;
            height: 56px;
            background-color: black;
            border-radius: 50%;
            position: relative;
            margin-right: 16px;
            img{
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .contract-item-information{
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            .contract-item-col-name,
            .contract-item-col-package,
            .contract-item-col-metering-point,
            .contract-item-col-address{
                padding-left: 16px;
                .contract-item-bottom-row,
                .contract-item-top-row{
                    top: 26px;
                    position: relative;
                    div {
                        text-align: right;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                .contract-item-top-row {
                    div {
                        color: #909090;
                        font-family: Roboto;
                        font-size: 0.77em;
                        font-weight: 500;
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                    }
                }
                .contract-item-bottom-row {
                    font-family: Roboto;
                    font-weight: lighter;
                }
            }
            .contract-item-col-name{
                flex-grow: 1;
                padding-left: 0;
                .contract-item-top-row{
                    div {
                        font-family: "Bebas Neue";
                        font-size: 18px;
                        font-weight: bold;
                        letter-spacing: 0.59px;
                        text-align: left;
                    }
                }
                .contract-item-bottom-row{
                    div {
                        font-family: "Bebas Neue";
                        font-size: 24px;
                        font-weight: bold;
                        letter-spacing: 0.59px;
                        text-align: left;
                    }
                 }
            }
            .contract-item-col-package,
            .contract-item-col-address,
            .contract-item-col-metering-point{
                width: 20%;
                .contract-item-bottom-row{
                    line-height: 1.7em;
                }
            }
        }
    }
    .contract-item-body {
        padding: 24px;
        display: flex;
        flex-direction: row;
        height: 3rem;
        cursor: pointer;
    }

    .contract-item-button-container{
        padding-left: 24px;
        padding-top: 16px;
        button{
            img{
                pointer-events: none;
            }
        }
    }

    .contract-item-middle{
        background-color: #fafafa;
        border-top: 1px solid #efefef;
        border-bottom: 1px solid #efefef;
        padding: 24px;
        .contract-item-mid-header{
            display: flex;
            flex-direction: row;
            .contract-item-col-price,
            .contract-item-col-start,
            .contract-item-col-end{
                display: flex;
                flex-direction: row;
                width: 33.333%;
                .contract-item-col-icon{
                    padding-right: 16px;
                }
                .contract-item-col-rows{
                    width: 86%;
                    .contract-item-bottom-row,
                    .contract-item-top-row{
                        height: 1.1em;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        line-height: 1.1em;
                    }
                    .contract-item-top-row {
                        color: #909090;
                        font-family: Roboto;
                        font-size: 0.8em;
                    }
                    .contract-item-bottom-row {
                        font-family: Roboto;
                        font-weight: lighter;
                    }
                }
            }
        }
        .contract-item-mid-body{
            margin-top: 24px;
            .contract-item-metering-point{
                display: flex;
                flex-direction: row;

                padding: 16px;
                background-color: #ffffff;
                border: 1px solid #e7e7e7;
                border-radius: 2px;
                margin-top: 8px;

                .contract-item-metering-point-img{
                    padding-right: 16px;

                }
                .contract-item-metering-point-columns{
                    display: flex;
                    flex-direction: row;
                    flex-grow: 1;
                    .contract-item-metering-point-name{
                        flex-grow: 1;
                        .contract-item-metering-point-top{
                            div{
                                max-width: 738px;
                                font-family: "Bebas Neue";
                                font-size: 24px;
                                font-weight: bold;
                                letter-spacing: 0.59px;
                                text-align: left;
                            }
                        }
                        .contract-item-metering-point-bottom{
                            div{
                                max-width: 738px;
                                color: #909090;
                                font-family: "Bebas Neue";
                                font-size: 18px;
                                font-weight: bold;
                                letter-spacing: 0.59px;
                                text-align: left;
                            }
                        }
                    }
                }
                .contract-item-metering-point-code{
                    text-align: right;
                    .contract-item-metering-point-top{
                        div{
                            max-width: 140px;
                            line-height: 2.2em;
                            color: #909090;
                            font-family: Roboto;
                            font-size: 0.77em;
                            font-weight: 500;
                            bottom: 0;
                            width: 100%;
                        }
                    }
                    .contract-item-metering-point-bottom{
                        div{
                            max-width: 140px;
                            line-height: 1em;
                            font-family: Roboto;
                            font-weight: lighter;
                        }
                    }
                }
                .contract-item-metering-point-code,
                .contract-item-metering-point-name {
                    .contract-item-metering-point-top,
                    .contract-item-metering-point-bottom{
                        div{
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }
}

.contract-item.contract-item-open{
    border: 2px solid #565656;
}

.electricity-body-container {
    @include mobile() {
        .contract-item {
            max-width: calc(100vw - 36px);
            .contract-item-header{
                .contract-item-icon{
                    display: inline-block;
                }
                .contract-item-button-container{
                    width: calc(100% - 72px);
                    display: inline-flex;
                    padding: 0;
                    justify-content: flex-end;
                }
            }

            .contract-item-header-mobile-row{
                display: flex;
                flex-direction: row;
                padding: 24px;
                cursor: pointer;
                flex-wrap: wrap;
                .contract-item-col-package,
                .contract-item-col-metering-point{
                    width: 50%;
                }
                .contract-item-col-address{
                    padding-top: 8px;
                    width: 100%;
                }
                .contract-item-col-package,
                .contract-item-col-metering-point,
                .contract-item-col-address{
                    .contract-item-top-row{
                        color: #909090;
                        font-family: Roboto;
                        font-size: 0.77em;
                        font-weight: 500;
                    }
                    .contract-item-bottom-row{
                        font-family: Roboto;
                        font-weight: lighter;
                    }
                }
            }

            .contract-item-middle{
                background-color: white;
                padding: 0;
                .contract-item-mid-header{
                    flex-direction: column;
                    padding: 24px;
                    .contract-item-col-price,
                    .contract-item-col-start,
                    .contract-item-col-end {
                        padding: 6px 0;
                        width: 100%;
                        .contract-item-col-rows{
                            .contract-item-top-row{
                                height: 14px;
                                line-height: 14px;
                                font-size: 12px;
                                font-weight: 500;
                            }
                            .contract-item-bottom-row {
                                padding-top: 4px;
                                font-size: 16px;
                                font-weight: 300;
                                height: 19px;
                                line-height: 19px;
                            }
                        }

                    }
                }

                .contract-item-mid-body{
                    margin: 0;
                    .contract-item-metering-point{
                        border: 0;
                        border-top: 1px solid #ebebeb;
                        margin: 0;
                        background-color: #f9f9f9;
                        padding: 22px;
                        .contract-item-metering-point-img{
                            width: 56px;
                            height: 56px;
                        }
                        .contract-item-metering-point-columns{
                            flex-direction: column;
                            .contract-item-metering-point-name{
                                display: grid;
                                .contract-item-metering-point-top{
                                    div {
                                        font-size: 20px;
                                        height: 24px;
                                    }
                                }
                                .contract-item-metering-point-bottom{
                                    div{
                                        font-size: 18px;
                                        height: 23px;
                                    }
                                }
                            }
                            .contract-item-metering-point-code{
                                .contract-item-metering-point-top,
                                .contract-item-metering-point-bottom{
                                    div{
                                        text-align: left;
                                    }
                                }
                            }
                        }
                    }
                }
            }




            // TODO temporary solution until an actual graph exists
            .contract-item-body{
                max-width: 100%;
                margin-right: 24px;
                overflow: hidden;
                cursor: pointer;
                padding: 0 0 0 24px;
                img{
                    width: 100%;
                    object-fit: none;
                }
            }
        }
    }
}

.contract-item:hover{
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.12);
}
