@import "../../Layout.scss";
@import "../../Animation.scss";

.co-tab-link {
    padding: 9px 0px 9px 0px;
    margin: 0px 16px 0px 16px;
    background: none;
    user-select: none;
    cursor: pointer;
    box-sizing: border-box;
    opacity: 0.5;
    text-decoration: none;
    white-space: nowrap;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    &.active {
        opacity: 1;
        border-top: 2px solid white;
        border-bottom: 2px solid white;
    }
    &.active-manual {
        opacity: 1;
        border-top: 2px solid white;
        border-bottom: 2px solid white;
    }
    &:hover {
        opacity: 1;
    }
    @include fast-transition();
}

.co-tab-link_text {
    margin: 0px;
    @include design {
        color: #1E1E1E;
        font-family: "Bebas Neue";
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.49px;
        line-height: 20px;
    }
}

.co-tab-link_img-mobile {
    display: none;
    width: 8px;
    height: 8px;
    margin: 0px 0px 0px 8px;
    vertical-align: middle;
    @include mobile {
        display: initial;
    }
}
