@import "../../../Layout.scss";
@import "../../../Animation.scss";

.cardItemDropdownCol {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
    grid-template-areas:
        "text"
        "button";

    justify-content: space-between;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% / 5 - 24px);

    height: 106px;

    @include tablet {
        height: 46px;
        grid-template-rows: auto;
        grid-template-columns: auto auto;
        grid-template-areas: "text button";

        justify-content: space-between;
    }

    .cardItemDropdownColText {
        grid-area: text;
    }

    .cardItemDropdownColButton {
        grid-area: button;
    }
}

.cardItemDropdownColButton {
    @include in-grid($horizontal: stretch, $vertical: end, $row-start: 3);
    height: 46px;
    background: white;
    border: 1px solid rgb(231, 231, 231);
    box-sizing: border-box;
    @include tablet {
        @include in-grid($horizontal: stretch, $vertical: center, $col-start: 2, $row-span: 2);
        width: 175px;
        margin: 0px;
    }

    .img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
    }

    .text {
        margin: 0px 0px 0px 12px;
        vertical-align: middle;
        @include design {
            color: #000000;
            font-family: Roboto;
            font-size: 14px;
            letter-spacing: 0.2px;
            line-height: 16px;
        }
    }
}

.cardItemDropdownColText {
    display: grid;
    grid-template-rows: auto auto;
    height: 40px;
    gap: 4px;

    .label {
        margin: 0;

        @include design {
            color: #909090;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.17px;
            line-height: 14px;
        }

        @include tablet {
            @include in-grid($horizontal: start, $vertical: end);
        }
    }

    .text {
        margin: 0;

        @include design {
            color: #000000;
            font-family: Roboto;
            font-size: 16px;
            letter-spacing: 0.23px;
            line-height: 19px;
        }

        @include tablet {
            @include in-grid($horizontal: start, $vertical: start,  $row-start: 2);
        }
    }
}

.discountCardItemDropdown {
    align-items: center;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 170px 1fr 170px;
    grid-template-areas: "editName expiry extend";
    gap: 14px;

    @include tablet {
        grid-template-rows: 46px 46px;
        grid-template-columns: 1fr 170px;
        grid-template-areas:
            ". editName"
            "expiry extend";

        justify-content: space-between;
    }

    .editNameButton {
        grid-area: editName;
    }

    .expiryText {
        grid-area: expiry;
        justify-self: end;
        text-align: right;

        @include tablet {
            justify-self: start;
            text-align: left;
        }
    }

    .extendButton {
        grid-area: extend;
    }
}
