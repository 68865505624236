@import "../Layout.scss";
@import "../Animation.scss";
.table {
    width: 100%;
    border-collapse: collapse;
    box-sizing: border-box;
    position: relative;
    &.small {
        box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
    }
}

.table-header-row {
    height: 48px;
    background: rgb(250, 250, 250);
    border-top: 1px solid rgb(231, 231, 231);
    border-bottom: 1px solid rgb(231, 231, 231);
    box-sizing: border-box;
    &.users {
        border-top: none;
        background: white;
    }
    &.hidden {
        display: none;
        opacity: 0;
    }
}

.table-body-row {
    border-bottom: 1px solid rgb(231, 231, 231);
    @include fast-transition(background-color);
    &:hover {
        background: rgb(255, 255, 255);
    }
    &.hover {
        background: rgb(255, 255, 255);
    }
    &.users {
        &:not(:hover) {
            background: rgb(250, 250, 250);
        }
    }
}

.table-header-cell {
    height: 48px;
    box-sizing: border-box;
    text-align: left;
    @include mobile-s {
        white-space: nowrap;
    }
}

.table-header-cell_filter-button {
    line-height: 14px;
    &:hover {
        .table-header-cell_filter-button_text {
            color: #000000;
        }
        .table-header-cell_filter-button_img {
            &.inactive {
                display: none;
                opacity: 0;
            }
        }
    }
    &:not(:hover) {
        &.selected {
            .table-header-cell_filter-button_text {
                color: #000000;
            }
            .table-header-cell_filter-button_img {
                &.inactive {
                    display: none;
                    opacity: 0;
                }
            }
        }
        &:not(.selected) {
            .table-header-cell_filter-button_img {
                &.active {
                    display: none;
                    opacity: 0;
                }
            }
        }
    }
}

.table-header-cell_filter-button_text {
    @include fast-transition(background-color);
    vertical-align: middle;
    text-transform: uppercase;
    @include design {
        color: #909090;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 900;
        letter-spacing: 0.6px;
    }
    @include fast-transition(color);
}

.table-header-cell_filter-button_img {
    width: 3px;
    height: 13px;
    margin: 0px 0px 0px 12px;
    vertical-align: middle;
    &.selected {
        background: #F0B423;
        border-radius: 50%;
    }
    @include fast-transition(opacity);
}

.table-header-cell_filter-tippy {
    width: 188px;
    height: 144px;
    &.no-search {
        height: 96px;
    }
    display: flex;
    flex-direction: column;
}

.table-header-cell_filter-tippy_input {
    height: 48px;
    box-sizing: border-box;
    padding: 8px 0px 8px 42px;
    background-image: url(../assets/Icon_Search_Gray-16px.svg);
    background-size: 16px auto;
    background-position: center left 16px;
    background-repeat: no-repeat;
    border: none;
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
    }
    &::placeholder {
        color: #C1C1C1;
    }
}

.table-header-cell_filter-tippy_button {
    height: 48px;
    border-top: 1px solid rgb(231, 231, 231);
    box-sizing: border-box;
    text-align: start;
    line-height: 18px;
    &:hover {
        .table-header-cell_filter-tippy_button-text {
            color: #000000;
        }
        .table-header-cell_filter-tippy_button-img {
            &.inactive {
                display: none;
                opacity: 0;
            }
        }
    }
    &:not(:hover) {
        &.selected {
            .table-header-cell_filter-tippy_button-text {
                color: #000000;
            }
            .table-header-cell_filter-tippy_button-img {
                &.inactive {
                    display: none;
                    opacity: 0;
                }
            }
        }
        &:not(.selected) {
            .table-header-cell_filter-tippy_button-img {
                &.active {
                    display: none;
                    opacity: 0;
                }
            }
        }
    }
}

.table-header-cell_filter-tippy_button-img {
    width: 16px;
    height: 16px;
    margin: 0px 0px 0px 16px;
    vertical-align: top;
    @include fast-transition(opacity);
}

.table-header-cell_filter-tippy_button-text {
    margin: 0px 0px 0px 8px;
    vertical-align: top;
    @include fast-transition(color);
    @include design {
        color: #909090;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
    }
}

.table-data-cell {
    max-width: 180px;
    height: 80px;
    padding: 0px 2px 0px 0px;
    box-sizing: border-box;
}

.table-text-24px-bold {
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.59px;
        line-height: 29px;
        text-align: right;
    }
    @include mobile {
        font-size: 18px;
    }
}

.table-text-14px-bold {
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.2px;
        line-height: 16px;
    }
}

.table-text-14px-regular {
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
    }
}

.table-text-12px-subtitle {
    @include design {
        color: #909090;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.17px;
        line-height: 14px;
    }
    @include mobile-s {
        white-space: nowrap;
    }
}
