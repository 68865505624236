@import "../../../Layout.scss";
.olerex-plus-modal {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    overflow-y: auto;
    max-height: 100vh;
    z-index: 2;
    background: rgb(250, 250, 250);
}

.olerex-plus-modal-content {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-span: 5);
    @include grid($cols: 1fr auto auto, $rows: auto auto 1fr);
    position: relative; // overlap green checkboxes
    z-index: 3;
    padding: 64px 0px 0px 0px;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    @include mobile {
        padding: 64px 16px 0px 16px;
    }
    >.olerex-plus-collection {
        @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3, $row-start: 3);
        @include mobile {
            flex-wrap: wrap;
        }
        >.olerex-plus-cell {
            @media only screen and (min-width: 0px) {
                flex: 0 0 100%;
            }
            @media only screen and (min-width: 700px) {
                flex: 0 0 calc(25% - 12px);
            }
            @media only screen and (min-width: 1000px) {
                flex: 0 0 calc(20% - 12px);
            }
            @media only screen and (min-width: 1300px) {
                flex: 0 0 calc(16.66% - 12px);
            }
        }
    }
}

.olerex-plus-modal-title {
    @include in-grid($horizontal: start, $col-span: 2);
    margin: 0px 0px 0px 6px;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 48px;
        font-weight: 300;
        letter-spacing: 1.44px;
        line-height: 58px;
    }
}

.olerex-plus-modal-text {
    @include in-grid($horizontal: start, $col-span: 2, $row-start: 2);
    margin: 0px 0px 0px 6px;
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.23px;
        line-height: 22px;
    }
}

.olerex-plus-modal-close-button {
    @include in-grid($horizontal: end, $col-start: 3);
    background: none;
    border: none;
    margin: 0px 6px 0px 0px;
    padding: 0px;
    cursor: pointer;
    @include design {
        color: #C1C1C1;
        font-family: "Bebas Neue";
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.49px;
        line-height: 24px;
        text-align: right;
    }
}