@import "../../../Layout.scss";
.fuel-usage {
    @include grid($cols: auto 1fr, $rows: auto 1fr);
    padding: 48px 0px 48px 0px;
    position: relative;
    .co-btn-dropdown {
        padding: 0px;
    }
}

.fuel-usage-tippy-dropdown {
    .co-btn-dropdown_text {
        @include design {
            height: 29px;
            width: 41px;
            color: #000000;
            font-family: "Bebas Neue";
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 0.59px;
            line-height: 29px;
            text-align: right;
        }
        &.expanded {
            color: white;
        }
    }
    .co-btn-dropdown_img-left {
        display: none;
    }
    .co-dropdown-filter_dropdown_option-button {
        text-align: right;
    }
    @include tablet {
        margin: 0px 16px 0px 0px;
    }
}

.fuel-usage_title {
    @include in-grid($horizontal: start);
    margin: 0px;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.59px;
        line-height: 29px;
    }
    @include tablet {
        margin: 0px 0px 0px 16px;
    }
}

.fuel-usage-dropdown-button_text {
    margin: 0px;
    vertical-align: middle;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.59px;
        line-height: 29px;
        text-align: right;
    }
}

.fuel-usage-dropdown-button_img {
    width: 8px;
    height: 8px;
    margin: 0px 0px 0px 8px;
    vertical-align: middle;
}

.fuel-chart {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 2, $row-start: 2);
}

.fuel-chart-scroll {
    @include grid($cols: 1fr, $rows: 1fr);
    width: 1024px;
    overflow: hidden;
    @include tablet {
        width: 100%;
        overflow-x: scroll;
    }
}

.fuel-chart-svg-scroll {
    @include in-grid($horizontal: stretch, $vertical: stretch);
    z-index: 1;
    @include tablet {
        width: 1024px;
        height: 210px;
        padding: 0px 16px 6px 16px;
    }
}

.fuel-chart-wrap {
    @include in-grid($horizontal: stretch, $vertical: stretch);
    width: 1024px;
    height: 210px;
    @include tablet {
        padding: 0px 16px 6px 16px;
    }
}

.fuel-usage-loader-container {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}