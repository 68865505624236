@import "../../../Layout.scss";
@import "../../../Animation.scss";

.virtual_family-members {
    @include in-grid($horizontal: stretch, $vertical: stretch);
    @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: auto auto 1fr);
    -ms-grid-rows: auto auto auto; //flexbox in 1fr row has 0 height on ie11
    background: rgb(250, 250, 250);
    // min-height: 100%;
    @include mobile {
        @include grid($cols: auto 1fr auto, $rows: auto auto 1fr);
    }
}

.virtual_family-members-info_header-background {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3);
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.05);
    z-index: 1;
}

.family_info_header {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2);
    display: flex;
    flex-wrap: wrap;
    z-index: 1;
    padding: 12px 12px 12px 12px;
}

.family_info_header-item {
    @include grid($cols: auto 1fr, $rows: 1fr 1fr);
    background: black;
    flex-basis: calc(25% - 24px);
    height: 110px;
    padding: 24px;
    box-sizing: border-box;
    margin: 12px;
    border-radius: 2px;
}

.family_info_header-item-icon {
    @include in-grid($row-span: 2, $horizontal: start);
}

.family_info_header-item-text {
    @include in-grid($col-start: 2, $horizontal: start);
    margin: 0px 0px 0px 16px;
    @include design {
        color: #FFFFFF;
        font-family: "Bebas Neue";
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.44px;
        line-height: 23px;
    }
}

.family_info_header-item-value {
    @include in-grid($col-start: 2, $row-start: 2, $horizontal: start);
    margin: 0px 0px 0px 16px;
    @include design {
        color: #FFCD00;
        font-family: "Bebas Neue";
        font-size: 28px;
        letter-spacing: 0.68px;
        line-height: 31px;
    }
}

.virtual_family-members-loader {
    @include in-grid($col-start: 2, $row-span: 3);
}

.virtual_family-members-no_content {
    @include in-grid($col-start: 2, $row-start: 2, $horizontal: center, $vertical: end);
}

.virtual_family-members-background_table {
    @include in-grid($horizontal: stretch, $vertical: start, $col-span: 3, $row-start: 2);
}

.virtual_family-members-table {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 2);
}

.virtual_family-members-pagination {
    @include in-grid($horizontal: center, $vertical: start, $col-start: 2, $row-start: 3);
    margin-bottom: 48px;
    @include mobile {
        margin-bottom: 24px;
    }
}

.virtual_family-members-selection_footer {
    height: 92px;
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    @include mobile {
        display: none;
    }
    background: #222222;
    @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: auto);
}

.virtual_family-members-selection_footer-buttons {
    @include in-grid($horizontal: stretch, $col-start: 2);
    display: flex;
    justify-content: space-between;
}

.virtual_family-members-selection_footer-close_button {
}

.virtual_family-members-selection_footer-close_button-text {
    vertical-align: middle;
    @include design {
        color: #FFCD00;
        font-family: "Bebas Neue";
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.44px;
    }
}

.virtual_family-members-selection_footer-close_button-img {
    margin: 0px 0px 0px 16px;
    vertical-align: middle;
    width: 12px;
    height: 12px;
}

.virtual_family-members-selection_footer-button {
    width: 248px;
    height: 44px;
    margin: 0px 0px 0px 24px;
    background: rgb(34, 34, 34);
    user-select: none;
    cursor: pointer;
    @include design {
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 2px;
        box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
    }
}

.virtual_family-members-selection_footer-button-img {
    width: 16px;
    height: 16px;
    vertical-align: middle;
}

.virtual_family-members-selection_footer-button-text {
    margin: 0px 0px 0px 12px;
    vertical-align: middle;
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
    }
}

.table-header-cell {
    &.members_table-header_row-checkbox {
        width: 86px;
    }
    &.members_table-header_row-name {
        width: 282px;
    }
    &.members_table-header_row-personalCode {
        width: 163px;
    }
    &.members_table-header_row-email {
        width: 322px;
    }
    &.members_table-header_row-status {
        width: 171px;
    }
}

.table-body-row {
    &.members {
        &.left {
            opacity: 0.33;
            .members_table-data_row-checkbox {
                display: none;
            }
        }
    }
}