@import "../../../Layout.scss";
@import "../../../Animation.scss";

.new_link_item-text_field {
    width: 272px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.new_link_item-text_field-label {
    margin: 0px;
}

.new_link_item-text_field-label-left {
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 24px;
    }
}

.new_link_item-text_field-label-right {
    float: right;
    @include design {
        color: rgba(255, 255, 255, 0.5);
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 24px;
        text-align: right;
    }
}

.new_link_item-text_field-input {
    background: rgb(22, 22, 22);
    border: none;
    padding: 10px 4px 10px 40px;
    @include design {
        color: #FFCD00;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.23px;
        line-height: 24px;
    }
}

.new_link_item-text_field-date_picker_input {
    width: 272px;
    box-sizing: border-box;
    background: rgb(22, 22, 22);
    border: none;
    padding: 10px 4px 10px 10px;
    @include design {
        color: #FFCD00;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.23px;
        line-height: 24px;
    }
}

.date_picker-header {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.new_link_item-text_field  {
    .react-datepicker {
        border: none;
    }
    .react-datepicker__triangle {
        border-bottom-color: #000000!important;
        &::before {
            border-bottom-color: #000000!important;
        }
    }
    .react-datepicker__month-container {
        background: #000000;
    }
    .react-datepicker__header {
        background: #000000;
        border-bottom: 1px solid #1E1E1E;
    }
    .react-datepicker__day-names {
        
    }
    .react-datepicker__day-name {
        @include design {
            font-family: "Bebas Neue";
            font-size: 16px;
            color: #484848;
            letter-spacing: 0.23px;
            text-align: center;
        }
    }

    .react-datepicker__day {
        border-radius: 2px;
        @include design {
            font-family: Roboto;
            font-size: 14px;
            color: #FFFFFF;
            letter-spacing: 0.2px;
            text-align: center;
        }
        &.react-datepicker__day--outside-month {
            @include design{
                color: #484848;
            }
        }
        &.react-datepicker__day--selected, &.react-datepicker__day--keyboard-selected {
            background-color: #FFCD00;
            @include design {
                font-weight: 500;
                color: #000000;
            }
        }
        &.react-datepicker__day--disabled {
            @include design{
                color: #242424;
            }
        }
        &:not(.react-datepicker__day--disabled) {
            &:hover {
                background-color: #FFCD00;
                @include design {
                    font-weight: 500;
                    color: #000000;
                }
            }
        }
        @include fast-transition();
    }
}

.date_picker-header-button {
    width: 32px;
    height: 32px;
    &:hover {
        .date_picker-header-button-img {
            opacity: 0.7;
        }
    }
}
.date_picker-header-button-img {
    opacity: 1;
    @include fast-transition();
}

.date_picker-header-title {
    @include design {
        font-family: "Bebas Neue";
        font-size: 16px;
        color: #FFCD00;
        letter-spacing: 1px;
        text-align: center;
    }
}