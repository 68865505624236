@import "../../../Layout.scss";
@import "../../../Animation.scss";
.cards-header {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2);
    @include grid($cols: auto 1fr auto, $rows: 1fr);
    margin: 12px 0px 20px 0px;
    @include tablet {
        margin: 16px;
    }
}

.cards-header-title {
    @include in-grid($horizontal: start);
    margin: 0px;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 40px;
        font-weight: 300;
        letter-spacing: 0.8px;
        line-height: 48px;
    }
}

.cards-header-balance {
    @include in-grid($vertical: center, $col-span: 3);
    margin: 0px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    @include tablet {
        display: none;
    }
}

.cards-header-balance-fills {
    @include grid($cols: 1fr, $rows: 1fr);
}

.cards-header-balance-fill-1 {
    @include in-grid($horizontal: start, $vertical: stretch);
    height: 2px;
    background: rgba(0, 0, 0, 0.05);
    @include transition-800ms();
}

.cards-header-balance-fill-2 {
    @include in-grid($horizontal: start, $vertical: stretch);
    height: 2px;
    background: white;
    @include transition-800ms();
}

.cards-header-balance-fill-3 {
    @include in-grid($horizontal: start, $vertical: stretch);
    height: 2px;
    @include transition-800ms();
    background: black;
}

.cards-header-balance-labels {
    width: 480px;
    display: flex;
    justify-content: space-between;
}

.cards-header-balance-label {
    margin: 10px 0px 0px 0px;
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.17px;
        line-height: 14px;
    }
}

.cards-header-balance-label-text {
    color: rgba(0, 0, 0, 0.5);
}

.cards-header-balance-label-value {}

.cards-header-actions {
    @include in-grid($vertical: start, $horizontal: end, $col-span: 3);
    display: flex;
    gap: 24px;
    align-items: center;
    @include default-transition();
    min-width: 0%;
    &.open {
        min-width: 100%;
        .new-card-button {
            display: none;
        }
        .cards-header-spacer {
            display: none;
        }
        .cards_header-download_button {
            display: none;
        }
    }
    &:not(.open) {
        .search-input-form {
            display: none;
        }
        .search-close-button {
            display: none;
        }
    }
    background: rgb(255, 205, 0);
}

.new-card-button {
    @include tablet {
        display: none;
    }
}

.new-card-button-img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
}

.new-card-button-text {
    margin: 0px 0px 0px 8px;
    vertical-align: middle;
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
    }
}

.cards-header-spacer {
    width: 1px;
    height: 24px;
    background: rgba(0, 0, 0, 0.25);
    @include tablet {
        display: none;
    }
}

.cards-header-search {
    height: 48px;
    flex: 1;
    display: flex;
}

.search-button {
    vertical-align: middle;
}

.search-button_img {
    width: 25px;
    height: 25px;
    vertical-align: middle;
}

.search-input-form {
    margin: 0px 16px 0px 16px;
    flex: 1 1 100%;
}

.search-input-field {
    width: 100%;
    margin: 0px;
    background: none;
    border: none;
    vertical-align: middle;
    @include design {
        color: black;
        font-family: "Bebas Neue";
        font-size: 40px;
        font-weight: 300;
        letter-spacing: 0.8px;
        line-height: 48px;
    }
    &::placeholder {
        @include design {
            color: #CC9206;
        }
    }
}

.search-close-button {
    margin: 0px 0px 0px auto;
    padding: 12px;
    vertical-align: middle;
}

.search-close-button_img {
    width: 12px;
    height: 12px;
}
