.loader-outer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    display: inline-block;
    width: 32px;
    height: 32px;
    border: 2px solid #DCDCDC;
    animation: loader 4s infinite cubic-bezier(.4, 0, 0, 1);
}

.loader-inner {
    vertical-align: top;
    display: inline-block;
    width: 100%;
    background-color: #DCDCDC;
    animation: loader-inner 4s infinite cubic-bezier(.4, 0, 0, 1);
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(180deg);
    }
    50% {
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes loader-inner {
    0% {
        height: 0%;
    }
    25% {
        height: 0%;
    }
    50% {
        height: 100%;
    }
    75% {
        height: 100%;
    }
    100% {
        height: 0%;
    }
}