@import '../Layout.scss';

.button {
    border-radius: 2px;
    font-family: Bebas Neue;
    font-size: 1.125em;
    font-weight: 700;
    letter-spacing: 0.205px;
    line-height: 52px;
    text-align: center;

    &.primary {
        background: var(--color-main);
        box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.07);
        color: #000;

        &:disabled {
            opacity: 0.4;
        }
    }

    &.secondary {
        background: #FFF;
        border: 2px solid var(--color-main);
        color: var(--color-black);

        &:disabled {
            opacity: 0.2;
        }
    }
}
