@import "../Layout.scss";
.filters-button-mobile {
    padding: 16px;
    margin: 0px; // display: flex;
    background: white;
    border: none;
    user-select: none;
    cursor: pointer;
    box-sizing: border-box;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
    display: none;
    @include mobile {
        display: flex;
    }
}

.filters-button-mobile_left-img {
    max-width: 20px;
    max-height: 20px;
    vertical-align: middle;
}

.filters-button-mobile_text {
    margin: 0px 0px 0px 12px;
    vertical-align: middle;
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 20px;
    }
}

.filters-button-mobile_right-img {
    max-width: 20px;
    max-height: 20px;
    margin: 0px 0px 0px auto;
    padding: 4px;
    box-sizing: border-box;
    vertical-align: middle;
}
