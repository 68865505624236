@import "../Layout.scss";
.co-no-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-top: 64px;
    margin-top: auto;
}

.co-no-content_text {
    margin: 0px;
}

.co-no-content_text-middle {
    margin: 0px 24px 0px 24px;
    vertical-align: middle;
    @include design {
        color: #C1C1C1;
        font-family: "Bebas Neue";
        font-size: 40px;
        font-weight: 300;
        letter-spacing: 0.8px;
        line-height: 48px;
    }
    @include mobile-s {
        @include design {
            font-size: 20px;
        }
    }
}

.co-no-content_text_img {
    vertical-align: middle;
}

.co-no-content_bottom-img {
    margin: 24px 0px 0px 0px;
}