@import "../Animation.scss";
@import "../Layout.scss";

.header {
    @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: auto 1fr auto);
    @include mobile {
        @include grid($cols: auto 1fr auto, $rows: auto 1fr auto);
    }

    background: #ffcd00;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.05);
    height: 80px;

    .inner {
        @include grid($cols: 1fr, $rows: 1fr);
        @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2);

        height: 80px;
        padding: 12px 0px 20px 0px;
        box-sizing: border-box;

        @include mobile {
            padding: 16px;
        }

        h1 {
            @include in-grid($horizontal: start);
            @include design {
                color: #000000;
                font-family: "Bebas Neue";
                font-size: 40px;
                font-weight: 300;
                letter-spacing: 0.8px;
                line-height: 48px;
            }

            margin: 0;
        }

        .tabs {
            @include in-grid();
            @include mobile {
                @include in-grid($horizontal: end);
            }

            &.hasSearch {
                padding-right: 25px;
            }
        }

        .search {
            @include in-grid($horizontal: end);
        }
    }
}

:global(.private) .header {
    height: 96px;
    box-shadow: none;

    .inner {
        height: 96px;
    }
}
