.tippy-tooltip {
    &.dropdown-filter-theme {
        max-width: unset;
        padding: 0px;
        background: black;
        border: none;
        border-radius: 4px;
        box-sizing: border-box;
        -moz-transition: none; //jumps around on scroll on firefox
    }
    &.dropdown-filter-light-theme {
        max-width: unset;
        padding: 0px;
        background: #161616;
        border: none;
        border-radius: 4px;
        box-sizing: border-box;
        -moz-transition: none; //jumps around on scroll on firefox
    }
}