@import "../../Layout.scss";
@import "../../Animation.scss";

.cards-page {
    @include in-grid($horizontal: stretch, $vertical: stretch, $row-start: 2);
    @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: auto auto 1fr auto);
    background: rgb(250, 250, 250);
    @include tablet {
        @include grid($cols: auto 1fr auto, $rows: auto auto 1fr auto);
    }
}

.cards-page-header-placeholder {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3);
    height: 152px;
    @include tablet {
        height: 132px;
    }
}

.cards-page-header-static {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3, $row-start: 1);
    @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: auto 1fr);
    @include tablet {
        @include grid($cols: auto 1fr auto, $rows: auto auto 1fr auto);
    }
    z-index: 1;
    &.fixed {
        position: fixed;
        position: sticky; //override with sticky on browsers which support it
        left: 0px;
        right: 0px;
        top: 80px;
        transform: translateY(-80px);
        &.animated {
            @include fast-transition(transform);
        }
        &.hiding {
            transform: translateY(calc(-100% - 80px));
        }
    }
}

.cards-page-header-background {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3);
    background: #ffcd00;
}

.cards-page-filters-background {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3, $row-start: 2);
    background: white;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.05);
}

.cards-page-header-mobile {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 2);
    @include tablet {
        display: flex;
    }
}

.cards-page-body {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 3);
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    // padding: 0px 0px 287px 0px;
    @include tablet {
        padding: 0px 16px 16px 16px;
    }
}

.cards-page-loader {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 3);
}

.cards-page-select-footer {
    height: 92px;
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    @include tablet {
        display: none;
    }
}
