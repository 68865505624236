@import "../../Layout.scss";
@import "../../Animation.scss";

.wrapper {
    @include mobile {
        width: 100vw;
        overflow-y: auto;

        .marginWrapper {
            margin: 0px 16px 0px 16px;
            display: inline-block;
        }

        .table {
            width: 1024px;
        }
    }
}

.table {
    border-collapse: collapse;
}

.headerRow {
    background: #fafafa;
    border-bottom: 1px solid #e7e7e7;
    border-top: 1px solid #e7e7e7;
    box-sizing: border-box;
    height: 48px;

    &.small {
        border-left: 3px solid black;
        border-right: 3px solid black;
    }
}

.bodyRow {
    &.small {
        border-left: 3px solid black;
        border-right: 3px solid black;
        &:last-child {
            border-bottom: 3px solid black;
        }
    }
}

:global(.table-header-cell) {
    &.left {
        text-align: left;
    }
    &.right {
        text-align: right;
    }
    &.fuel {
        width: 100px;
    }
    &.address {
        width: 195px;
    }
    &.comment {
        width: 195px;
    }
    &.status {
        width: 90px;
    }
    &.time {
        width: 86px;
    }
    &.quantity {
        width: 86px
    }
    &.sum {
        width: 136px;
    }
    &.sumvat {
        width: 136px;
    }
}

:global(.table-data-cell) {
    max-width: 180px;
    height: 80px;
    padding: 0px 2px 0px 0px;
    box-sizing: border-box;

    &.left {
        text-align: left;
    }

    &.right {
        text-align: right;
        padding: 0px 16px 0px 0px;
    }

    &.address,
    &.comment {
        box-sizing: border-box;
        padding-right: 1em;
    }
}
