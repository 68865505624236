@import "../../../Layout.scss";
@import "../../../Animation.scss";
.fuel-prices {
    flex: 0 0 100%;
    @include mobile {
        flex: 0 0 auto;
    }
}
.vertical-align-text-top {
    vertical-align: text-top;
}
.fuel-prices-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 18px 0;
    @include mobile {
        flex-direction: column;
        align-items: flex-start;
        gap: 6px 0;
    }
}

.fuel-prices_title {
    height: 23px;
    margin: 0px;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.59px;
        line-height: 29px;
    }
}

.fuel-prices-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    @include mobile {
        margin: 4px 0px 0px 0px;
        margin-left: -16px;
        margin-right: -16px;
        justify-content: stretch;
        flex-wrap: nowrap;
        overflow: auto;
        max-width: 100vw;
        &::after {
            content: '';
            position: absolute;
            right: 0;
            width: 22px;
            height: 60px;
            background: linear-gradient(90deg, transparent 0%, #FAFAFA 100%);
        }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            width: 22px;
            height: 60px;
            background: linear-gradient(270deg, transparent 0%, #FAFAFA 100%);
        }
    }
}

.fuel-prices-button {
    height: 32px;
    min-width: 102px;
    margin: 0px 8px 0px 0px;
    padding: 0 1em;
    &:last-child {
        margin: 0px;
    }
    @include mobile {
        margin: 8px 8px 0px 0px;
        padding: 0;
        &:last-child {
            margin: 8px 16px 0px 0px;
        }
        &:first-child {
            margin-left: 16px;
        }
        height: 48px;
        min-width: 124px;
    }
    background: rgb(231, 231, 231);
    &:hover, &.active {
        background: rgb(30, 30, 30);
        .fuel-prices-button_text {
            color: #FFFFFF;
        }
    }
    @include fast-transition();
}

.fuel-prices-button_text {
    height: 23px;
    vertical-align: middle;
    @include design {
        color: #909090;
        font-family: "Bebas Neue";
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.59px;
        line-height: 29px;
        text-align: center;
    }
    @include fast-transition();
}

.fuel-prices-content {
    @include grid($cols: 1fr, $rows: 1fr);
}

.fuel-prices-loader {
    @include in-grid();
    height: 256px;
}

.fuel-price-items {
    @include in-grid($horizontal: stretch, $vertical: stretch);
    margin: 32px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    @include mobile {
        margin: 16px 0px 0px 0px;
        flex-direction: column;
    }
}

.fuel-price-item {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    @include mobile {
        flex: 0 0 auto;
        margin: 8px 0px 0px 0px;
    }
    @include not-mobile {
        flex: 0 0 calc(25% - 12px); //16px * 3 / 4 = 12px (3 inside margins for 4 elements)
        &:nth-child(4n+2) {
            margin: 0px 8px 16px 16px;
        }
        &:nth-child(4n+3) {
            margin: 0px 16px 16px 8px;
        }
    }
}

.fuel-price-item_card {
    @include grid($cols: 1fr, $rows: auto auto 1fr);
    padding: 24px 24px 18px 24px;
    box-sizing: border-box;
    border-radius: 2px;
    background: white;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
    @include mobile {
        @include grid($cols: auto 1fr, $rows: 1fr 1fr);
    }
}

.fuel-price-item_img {
    @include in-grid();
    width: 64px;
    height: 64px;
    @include mobile {
        @include in-grid($horizontal: stretch, $vertical: stretch, $row-span: 2);
    }
}

.fuel-price-item_title {
    @include in-grid($row-start: 2);
    margin: 24px 0px 0px 0px;
    @include mobile {
        @include in-grid($horizontal: start, $vertical: start, $col-start: 2);
        margin: 0px 0px 0px 24px;
        @include design {
            color: #000000;
            font-family: Roboto;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0.23px;
            line-height: 19px;
        }
    }
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
        text-align: center;
    }
}

.fuel-price-item_price, .fuel-price-item_price button {
    @include in-grid($row-start: 3);
    margin: 12px 0px 0px 0px;
    @include mobile {
        @include in-grid($horizontal: start, $vertical: end, $col-start: 2, $row-start: 2);
        margin: 0px 0px 0px 24px;
    }
    @include design {
        color: #E7A300;
        font-family: "Bebas Neue";
        font-size: 22px;
        font-weight: bold;
        letter-spacing: 0.8px;
        line-height: 28px;
        text-align: center;
    }
}
.fuel-price-item_price button {
    margin-top: 0;
}

.fuel-price-item_locations {
    padding: 8px 24px 0px 24px;
    box-sizing: border-box;
    @include mobile {
        margin: 1px 0px 0px 0px;
        padding: 24px;
        background: rgb(250, 250, 250);
        box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
    }
}

.fuel-price-item_location {
    display: flex;
    @include mobile {
        &:first-child {
            margin: 0px;
        }
        &:not(:first-child) {
            margin: 8px 0px 0px 0px;
        }
    }
}

.fuel-price-item_location_title {
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.23px;
        line-height: 19px;
    }
}

.fuel-price-item_location_price {
    margin: 0px 0px 0px auto;
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.23px;
        line-height: 19px;
        text-align: right;
    }
}
