@import "../../../Layout";
.modals-card-limits-fuel-types-switch {
    margin: 48px 0px 0px 0px;
}

.modals-block-card-info-text {
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 16px;
        letter-spacing: 0.23px;
        line-height: 24px;
    }
}

.modals-extend-card-info-text {
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 16px;
        letter-spacing: 0.23px;
        line-height: 24px;
    }
}

.cards-modal-cards-dropdown {
    &.refueling {
        @include in-grid($horizontal: stretch, $col-span: 2, $row-start: 3);
        .co-btn-dropdown {
            width: 512px;
        }
    }
}

.order-card-modal-dropdown {
    &.co-btn-dropdown {
        &.refueling {
            width: 512px;
        }
    }
}