@import "../../Layout.scss";
@import "../../Animation.scss";

.join-virtual-family-modal {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    overflow-y: auto;
    max-height: 100vh;
    z-index: 2;
    background: rgb(240, 180, 36);
    @include grid($cols: 1fr 1024px 1fr, $rows: 1fr);
}

.join-virtual-family-modal_left-img {
    @include in-grid($horizontal: start, $col-start: 2);
    margin: 42px;
}

.join-virtual-family-modal_right-img {
    @include in-grid($horizontal: end, $col-start: 2);
    margin: 42px;
}

.join-virtual-family {
    @include in-grid($col-start: 2);
    @include grid($cols: 1fr, $rows: auto auto auto auto auto auto);
    width: 616px;
}

.join-virtual-family-logo {
    @include in-grid($horizontal: start, $col-span: 2);
}

.join-virtual-family-title {
    @include in-grid($col-span: 2, $row-start: 2);
    margin: 32px 0px 0px 0px;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 64px;
        font-weight: 300;
        letter-spacing: 1.28px;
        line-height: 77px;
    }
}

.join-virtual-family-card-top {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 2, $row-start: 3);
    margin: 32px 8px 0px 8px;
    border-radius: 2px 2px 0px 0px;
    background: white;
    padding: 32px;
    box-sizing: border-box;
}
.join-virtual-family-card-top_text {
    margin: 0px;
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.23px;
    }
}

.join-virtual-family-card-mid {
    @include in-grid($horizontal: stretch, $vertical: stretch,$col-span: 2, $row-start: 4);
    @include grid($cols: 1fr 1fr, $rows: 1fr 1fr);
    border-radius: 2px;
    background: white;
    border: 1px solid #E7E7E7;
    box-sizing: border-box;
    .name-field {
        @include in-grid($horizontal: stretch);
        margin: 0px 0px 0px 8px;
        border-right: 1px solid #E7E7E7;
    }
    .personalcode-field {
        @include in-grid($horizontal: stretch, $col-start: 2);
        margin: 0px 8px 0px 0px;
    }
    .email-field {
        @include in-grid($horizontal: stretch, $col-span: 2, $row-start: 2);
        margin: 0px 8px 0px 8px;
        border-top:  1px solid #E7E7E7;
    }
}
.join-virtual-family_text-field {
    display: flex;
    flex-direction: column;
    padding: 24px 32px 24px 32px;
    box-sizing: border-box;
}

.join-virtual-family_text-field-label {
    margin: 0px;
    @include design {
        color: #909090;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 24px;
    }
}

.join-virtual-family_text-field-text {
    margin: 4px 0px 0px 0px;
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 24px;
        font-weight: 300;
        letter-spacing: 0.34px;
        line-height: 27px;
    }
}

.join-virtual-family-card-bot {
    @include in-grid($horizontal: stretch, $vertical: stretch,$col-span: 2, $row-start: 5);
    margin: 0px 8px 0px 8px;
    border-radius: 0px 0px 2px 2px;
    background: white;
    display: flex;
    align-items: center;
    padding: 32px;
    box-sizing: border-box;
}

.join-virtual-family_checkbox-text {
    margin: 0px 0px 0px 16px;
}

.join-virtual-family-modal_cancel-button {
    @include in-grid($horizontal: start, $row-start: 6);
    height: 48px;
    margin: 32px 0px 0px 8px;
    border-radius: 2px;
    padding: 16px 32px 16px 32px;
    border: 1px solid black;
    box-sizing: border-box;
}
.join-virtual-family-modal_cancel-button_img {

}

.join-virtual-family-modal_cancel-button_text {
    margin: 0px 0px 0px 12px;
    @include design {
        color: black;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
        text-align: center;
    }
}

.join-virtual-family-modal_join-button {
    @include in-grid($horizontal: end, $row-start: 6);
    height: 48px;
    margin: 32px 8px 0px 0px;
    border-radius: 2px;
    padding: 16px 32px 16px 32px;
    background: black;
}


.join-virtual-family-modal_join-button_text {
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
        text-align: center;
    }
}

.join-virtual-family-modal_join-button_img {
    margin: 0px 0px 0px 12px;
}