@import '../../../Layout.scss';
@import '../../../Animation.scss';

.electricity-page-contracts .electricity-metering-points {
    .metering-point-item-container{
        position: relative;
        .metering-point-item {
            transition: border-color 0.3s ease-in-out;
            margin: 12px 0px 0px 0px;
            background-color: white;
            box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
            display: flex;
            flex-direction: column;
            border: 2px solid transparent;
            border-radius: 3px;
            .metering-point-item-header {
                display: flex;
                flex-direction: row;
                padding: 24px 24px 24px 24px;
                transition: padding $default-animation-speed $default-animation-curve;

                .metering-point-item-icon{
                    width: 56px;
                    height: 56px;
                    border-radius: 50%;
                    position: relative;
                    margin-right: 16px;
                    img{
                        position: relative;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                .metering-point-item-icon.icon-black{
                    background-color: black;
                }
                .metering-point-item-icon.icon-gray{
                    background-color: #e7e7e7;
                }

                .metering-point-item-information{
                    display: flex;
                    flex-direction: row;
                    flex-grow: 1;
                    .metering-point-item-col-contract,
                    .metering-point-item-col-code,
                    .metering-point-item-col-address{
                        padding-left: 16px;
                        .metering-point-item-bottom-row,
                        .metering-point-item-top-row{
                            top: 26px;
                            position: relative;
                            div {
                                text-align: right;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                        .metering-point-item-top-row {
                            div {
                                color: #909090;
                                font-family: Roboto;
                                font-size: 0.77em;
                                font-weight: 500;
                                position: absolute;
                                bottom: 0;
                                width: 100%;
                            }
                        }
                        .metering-point-item-bottom-row {
                            font-family: Roboto;
                            font-weight: lighter;
                        }
                    }
                    .metering-point-item-col-address{
                        flex-grow: 1;
                        padding-left: 0;
                        .metering-point-item-bottom-row{
                            div {
                                color: #7d7d7d;
                                font-family: "Bebas Neue";
                                font-size: 18px;
                                font-weight: bold;
                                letter-spacing: 0.59px;
                                text-align: left;
                            }
                        }
                        .metering-point-item-top-row{
                            div {
                                color: #2e2e2e;
                                font-family: "Bebas Neue";
                                font-size: 24px;
                                font-weight: bold;
                                letter-spacing: 0.59px;
                                text-align: left;
                                @include mobile() {
                                    font-size: 18px;
                                }
                            }
                        }
                    }
                    .metering-point-item-col-code,
                    .metering-point-item-col-contract{
                        width: 20%;
                        .metering-point-item-bottom-row{
                            line-height: 1.7em;
                        }
                    }
                }
            }

            .metering-point-item-button-container{
                padding-left: 24px;
                display: flex;
                flex-direction: column;
                button{
                    margin-top: auto;
                    margin-bottom: auto;
                    img{
                        pointer-events: none;
                    }
                }
            }


            .metering-point-mobile-bottom-row{
                display: flex;
                padding: 0 24px 24px 24px;

                .metering-point-item-button-container{
                    padding-left: 0;
                    width: 50%;
                    margin-right: 16px;
                }

                .metering-point-item-col-code,
                .metering-point-item-col-contract {
                    width: 50%;
                    .metering-point-item-top-row {
                        div {
                            color: #909090;
                            font-family: Roboto;
                            font-size: 0.77em;
                            font-weight: 500;
                            bottom: 0;
                            width: 100%;
                        }
                    }
                    .metering-point-item-bottom-row {
                        font-family: Roboto;
                        font-weight: lighter;
                    }
                }
            }
        }
        .electricity-metering-points-checkbox.card-checkbox{
            opacity: 0;
            position: absolute;
            top: 50%;
            transform: translate(0,-50%);
            transition: transform $default-animation-speed $default-animation-curve, opacity $default-animation-speed $default-animation-curve;

            .checkmark-circle,
            .chekmark-img{
                opacity: 1;
            }
        }

        .electricity-metering-points-checkbox.card-checkbox.checked{
            opacity: 1;
            transform: translate(-16px,-50%);
        }
    }
    .metering-point-item-container.checked{
        .metering-point-item{
            border: 2px solid black;
            box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.12);
            .metering-point-item-header{
                padding: 24px 24px 24px 32px;
            }
        }
    }
}
