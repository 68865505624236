@import '../Animation.scss';
@import '../Layout.scss';

.page {
    @include in-grid($horizontal: stretch, $vertical: stretch, $row-start: 2);
    @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: auto 1fr auto);

    -ms-grid-rows: auto auto auto; //flexbox in 1fr row has 0 height on ie11
    background: rgb(250, 250, 250);

    @include mobile {
        @include grid($cols: auto 1fr auto, $rows: auto 1fr auto);
        background: white;
    }
}

.header {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 1, $col-span: 3);
}
