@import "../../../Layout.scss";
@import "../../../Animation.scss";
.modal-done-text {
    margin: 32px 0px 0px 0px;
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.23px;
        line-height: 24px;
    }
}

.modal-error-text {
    margin: 32px 0px 0px 0px;
    @include design {
        color: red;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.23px;
        line-height: 24px;
    }
}

.modal-input-text {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    &:first-child {
        margin: 32px 0px 0px 0px;
    }
    &:not(:first-child) {
        margin: 8px 0px 0px 0px;
    }
}

.modal-input-text_label {
    margin: 0px;
}

.modal-input-text_label-left {
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 24px;
    }
}

.modal-input-text_label-right {
    float: right;
    @include design {
        color: rgba(255, 255, 255, 0.5);
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 24px;
        text-align: right;
    }
}

.modal-input-text_input {
    background: rgb(22, 22, 22);
    border: none;
    padding: 10px 4px 10px 40px;
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.23px;
        line-height: 24px;
    }
    background-size: 16px auto;
    background-position: center left 16px;
    background-repeat: no-repeat;
    &.card {
        background-image: url(../../../assets/Icon_Card_Small_Gray.svg);
    }
    &.undefined {
        padding: 10px 4px 10px 10px;
    }
}

.modal-input-text_limit {
    background: rgb(22, 22, 22);
    border: none;
    display: flex;
    justify-content: stretch;
    align-items: center;
}

.modal-input-text_limit-input {
    width: 96px;
    background: none;
    border: none;
    padding: 10px 4px 10px 4px;
    text-align: right;
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.23px;
        line-height: 24px;
    }
}

.modal-input-text_limit-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 auto;
}

.modal-input-text_limit-period {
    margin: 0px 0px 0px 4px;
    @include design {
        color: rgba(255, 255, 255, 0.5);
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.23px;
        line-height: 24px;
    }
}

.modal-input-text_limit-company {
    margin: 0px 16px 0px 0px;
    @include design {
        color: rgba(255, 255, 255, 0.5);
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.23px;
        line-height: 24px;
    }
}

.manage-modal {
    width: 576px;
    margin: auto;
    padding: 32px;
    box-sizing: border-box;
    background: rgb(30, 30, 30);
    @include mobile {
        width: 100%;
        max-width: 576px;
    }
}

.manage-modal_title {
    margin: 0px;
    @include design {
        color: #FFCD00;
        font-family: "Bebas Neue";
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 0.78px;
        line-height: 38px;
    }
}

.manage-modal_close-button_img {
    width: 12px;
    height: 12px;
}

.manage-modal-content {
    @include grid($cols: 1fr 1fr, $rows: auto 1fr);
}

.manage-modal-content_content {
    @include in-grid($horizontal: stretch, $col-span: 2);
    @include default-transition();
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.23px;
        line-height: 24px;
    }
}

.modal-text {
    margin: 32px 0px 0px 0px;
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.23px;
        line-height: 24px;
    }
}

.manage-modal_button {
    height: 44px;
    border-radius: 2px;
    @include fast-transition();
    &.back {
        @include in-grid($horizontal: stretch, $vertical: stretch, $row-start: 3);
        margin: 48px 16px 0px 0px;
        border: 1px solid rgb(98, 98, 98);
        @include design {
            color: #FFFFFF;
        }
    }
    &.save {
        @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 3);
        margin: 48px 0px 0px 16px;
        background: rgb(255, 205, 0);
        @include design {
            color: #000000;
        }
    }
    &.delete {
        @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 3);
        margin: 48px 0px 0px 16px;
        background: rgb(241, 57, 69);
        @include design {
            color: #FFFFFF;
        }
    }
    &.close {
        @include in-grid($horizontal: stretch, $vertical: stretch, $row-start: 3, $col-span: 2);
        margin: 48px 0px 0px 0px;
        border: 1px solid rgb(98, 98, 98);
        @include design {
            color: #FFFFFF;
        }
        .manage-modal_button_img {
            display: none;
        }
    }
}

.manage-modal_button_img {
    vertical-align: middle;
    margin: 1.5px 0px 0px 0px;
}

.manage-modal_button_text {
    &:not(:first-child) {
        margin: 0px 0px 0px 8px;
    }
    vertical-align: middle;
    @include design {
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
        text-align: center;
    }
}

.manage-modal-loader {
    @include in-grid($horizontal: center, $vertical: center, $col-start: 2, $row-start: 3);
    margin: 48px 0px 0px 0px;
}

.modal-input-switch-3 {
    @include grid($cols: 1fr 1fr 1fr, $rows: auto 1fr);
    margin: 8px 0px 0px 0px;
}

.modal-input-switch-3_label {
    @include in-grid($horizontal: start, $col-span: 3);
}

.modal-input-switch-3_button {
    &:nth-child(2) {
        @include in-grid($horizontal: stretch, $row-start: 2);
    }
    &:nth-child(3) {
        @include in-grid($horizontal: stretch, $col-start: 2, $row-start: 2);
        border-left: 1px solid rgb(57, 57, 57);
    }
    &:nth-child(4) {
        @include in-grid($horizontal: stretch, $col-start: 3, $row-start: 2);
        border-left: 1px solid rgb(57, 57, 57);
    }
    height: 44px;
    margin: 0px;
    padding: 10px 0px 10px 0px;
    border: none;
    background: rgb(22, 22, 22);
    user-select: none;
    &:not(:empty) {
        cursor: pointer;
    }
    @include design {
        color: rgba(255, 255, 255, 0.5);
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.23px;
        line-height: 24px;
        text-align: center;
    }
    &.active {
        @include design {
            color: #FFCD00;
        }
    }
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}
