@import '../../Layout.scss';
@import '../../Animation.scss';

.electricity-page-contracts{
  @include in-grid($horizontal: stretch, $vertical: stretch, $row-start: 2);
  @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: auto auto 1fr);
  position: relative;
  background: rgb(250, 250, 250);
  @include mobile {
    @include grid($cols: auto 1fr auto, $rows: auto auto 1fr);
  }

  .electricity-header{
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3);
    @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: 1fr);
    z-index: 1;
    @include mobile {
      @include grid($cols: auto 1fr auto, $rows: 1fr);
      height: 80px;
    }
    &.fixed {
      position: fixed;
      position: sticky; //override with sticky on browsers which support it
      left: 0px;
      right: 0px;
      top: 80px;
      @include not-mobile {
        &.animated {
          @include fast-transition(transform);
        }
        &.hiding {
          transform: translateY(-100%);
        }
      }
      @include mobile {
        transform: translateY(-80px);
        &.hiding {
          transform: translateY(-80px);
        }
      }
    }

    &-placeholder{
      @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 1, $col-span: 3);
      background-color: #ffcd00;
      height: 96px;
      width: 100%;
      @include mobile() {
        height: 80px;
      }
    }

    &-shadow {
      @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3);
      background: rgb(255, 205, 0);
    }

    &-container {
      @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2);
      height: 96px;
      padding: 24px 0px 24px 0px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include mobile() {
        height: 80px;
        padding: 16px 16px 16px 16px;
      }

      .electricity-header-title{
        @include in-grid($horizontal: start);
        margin: 0px;
        @include design {
          color: #000000;
          font-family: "Bebas Neue";
          font-size: 40px;
          font-weight: 300;
          letter-spacing: 0.8px;
          line-height: 48px;
        }
      }

      .electricity-header-middle{
        @include in-grid();
        display: flex;
        justify-content: space-between;
        height: 42px;
        @include mobile {
          display: none;
        }
      }
      .electricity-header-right{
        @include in-grid($horizontal: end);
        @include mobile {
          display: none;
        }
        .electricity-add-contract-button{
          cursor: pointer;
          display: flex;
          flex-direction: row;
          color: black;
          text-decoration: none;
          .white-circle-24px{
            height: 24px;
            width: 24px;
            margin-right: 8px;
          }
          .electricity-add-contract-text{
            font-family: Roboto;
            font-size: 0.9em;
            line-height: 1.8em;
          }
        }
      }
    }

    &-title {
      margin: 0px;
      @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 40px;
        font-weight: 300;
        letter-spacing: 0.8px;
        line-height: 48px;
      }
    }
  }

  .electricity-body-container{
    display: grid;
    grid-column: 1/span 3;
    justify-self: stretch;
    align-self: stretch;
    grid-template-columns: minmax(16px, 1fr) 1024px minmax(16px, 1fr);
    grid-template-rows: 1fr;
    @include mobile() {
      display: initial;
    }
  }

  @include in-grid($horizontal: stretch, $vertical: stretch, $row-start: 2);
  @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: auto auto 1fr);
  position: relative;
  background: rgb(250, 250, 250);
  @include mobile {
    @include grid($cols: auto 1fr auto, $rows: auto auto 1fr);
  }

  .electricity-body {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 2);
    @include grid($cols: 1fr, $rows: 1fr);
    position: relative;
    padding-bottom: 32px;
    @include mobile() {
      margin: 0px 16px;
      padding-bottom: 16px;
    }
  }

  .electricity-body-content {
    @include in-grid($horizontal: stretch, $vertical: stretch);
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    @include mobile() {
      margin-top: 8px;
    }
  }
}


@keyframes graphTooltipOpen {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


@keyframes graphTooltipClose {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
  }
}
