@import '../../../../Layout';
@import '../../../../Animation';

.confirmation-modal {
  //width: 760px;
  width: 514px;
  margin: auto;
  box-sizing: border-box;
  background: white;

  @include mobile() {
    width: 100%;
    min-height: 100%;
  }

  @include default-transition(width);

  &.small {
    //width: 576px;
    box-sizing: border-box;
  }
  .confirmation-modal_title {
    color: black;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 42px;
    line-height: 1;
    font-weight: 300;
    padding-left: 32px;
    padding-top: 32px;
    padding-bottom: 32px;
    margin: 0;
    letter-spacing: 1px;
  }
  .confirmation-modal_close-button {
    margin-right: 32px;
    padding: 14px;
    @include mobile() {
      margin-right: 10px;
    }
  }

  .confirmation-modal_close-button_img {
    width: 18px;
    height: 18px;
  }
  .confirmation-modal-content {
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #E7E7E7;
    background: #fafafa;
    min-height: 172px;

    .confirmation-modal-content_content{
      padding: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 124px;
      height: 100%;
    }
  }
  .confirmation-modal-footer {
    background: white;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .metering-points-selected {
      font-family: 'Bebas Neue', sans-serif;
      font-weight: bold;
      font-size: 18px;
      display: flex;
      img {
        margin-left: 24px;
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
    }

    @include mobile() {
      //flex-direction: column;
      button{
        padding: 14px 14px;
        min-width: 80px !important;
        width: auto;
        margin-top: 0;
      }
    }
  }

  .black-link{
    color: black;
  }

  .btn-confirmation{
    height: 80px;
    background-color: #dfdfdf;
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    text-align: left;
    width: 100%;
    display: flex;
    transition: box-shadow $default-animation-speed $default-animation-curve;

    .btn-confirmation-chevron{
      position: relative;
      svg{
        top: 0;
        position: absolute;
      }
      #chevron-right-id{
        transition: stroke $default-animation-speed $default-animation-curve;
      }
    }

    &:active,
    &:hover {
      box-shadow: 0 12px 10px 0 rgba(0, 0, 0, 0.07);
      .btn-confirmation-chevron{
        #chevron-right-id{
          stroke: #000;
        }
      }
    }

    .btn-confirmation-label{
      flex-grow: 1;
      margin: auto 0;
    }
    .btn-confirmation-chevron{
      #chevron-right-id{
        stroke: #C1C1C1;
      }
      height: 11px;
      width: 8px;
      margin: auto 0;
    }

    @include design {
      color: #000000;
      font-family: "Bebas Neue";
      font-size: 24px;
      letter-spacing: 0.59px;
      line-height: 29px;
    }

    &:disabled {
      box-shadow: none !important;
      #chevron-right-id{
        stroke: #696969 !important;
        transition: stroke $default-animation-speed $default-animation-curve;
      }
    }
  }

  .confirmation-modal-content-inner{
    height: 512px;
    width: 100%;
    padding-bottom: 16px;
  }

  .pb-16px{
    padding-bottom: 16px;
  }
}
