@import "../../Layout.scss";
@import "../../Animation.scss";
.co-btn-image-24px {
    flex: 0 0 auto;
    &.inactive {
        pointer-events: none;
        opacity: 0;
    }
    @include fast-transition(opacity);
    opacity: 0.24;
    &:hover {
        opacity: 1;
    }
}

.co-btn-image-24px_img {
    width: 24px;
    height: 24px;
}