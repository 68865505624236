@import "../../Layout.scss";
@import "../../Animation.scss";
.transactions {
    @include in-grid($horizontal: stretch, $vertical: stretch, $row-start: 2);
    @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: auto auto 1fr);
    position: relative;
    background: rgb(250, 250, 250);
    @include mobile {
        @include grid($cols: auto 1fr auto, $rows: auto auto 1fr);
        overflow-x: hidden;
    }
    .table-header-row {
        &.fixed {
            z-index: 0;
            position: fixed;
            top: 176px;
            @include mobile {
                top: 132px;
                width: 1024px;
            }
            &.background {
                left: 0px;
                right: 0px;
            }
            &.animated {
                @include fast-transition(transform, opacity);
            }
            &.alt {
                transform: translateY(-96px);
                @include mobile {
                    transform: translateY(0px);
                }
            }
        }
    }
}

.transactions-header-placeholder {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2);
    height: 96px;
    width: 100%;
    @include mobile {
        height: 132px;
    }
}

.transactions-header-static {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3);
    @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: 1fr);
    z-index: 1;
    @include mobile {
        @include grid($cols: auto 1fr auto, $rows: 1fr);
    }
    &.fixed {
        position: fixed;
        position: sticky; //override with sticky on browsers which support it
        left: 0px;
        right: 0px;
        top: 80px;
        @include not-mobile {
            &.animated {
                @include fast-transition(transform);
            }
            &.hiding {
                transform: translateY(-100%);
            }
        }
        @include mobile {
            transform: translateY(-80px);
            &.hiding {
                transform: translateY(-80px);
            }
        }
    }
}

.transactions-header-background {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3);
    background: rgb(255, 205, 0);
}

.transactions-header {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2);
    height: 96px;
    padding: 24px 0px 24px 0px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mobile {
        height: 80px;
        padding: 16px 16px 16px 16px;
    }
}

.transactions-header-title {
    margin: 0px;
    flex: 1 0 auto;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 40px;
        font-weight: 300;
        letter-spacing: 0.8px;
        line-height: 48px;
    }
}

.transactions-header-items {
    margin: 0px 0px 0px 24px;
    flex: 1 0 67%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include mobile {
        display: none;
    }
}

.transactions-header-mobile-filters {
    @include mobile {
        @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 2);
    }
}

.transactions-body-background {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3, $row-start: 3);
    display: flex;
    flex-direction: column;
}

.transactions-body {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 3);
    display: flex;
    flex-direction: column;
}

.transactions-loader {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 3);
}

.transactions-pagination {
    margin-bottom: 48px;
    @include mobile {
        margin-bottom: 24px;
    }
}