@import "../Layout.scss";
@import "../Animation.scss";

$org: 0.166rem;
$mar: 2px;
$rad: 2px;
$pad: calc(#{$org} - #{$mar});
$select-bg: #FFCD00;
$select-col: black;

@mixin left {
    padding: $pad $org $pad $pad ;
    margin: $mar 0 $mar $mar;
    border-radius: $rad 0 0 $rad;
};

@mixin right {
    padding: $pad $pad $pad $org;
    margin: $mar $mar $mar 0;
    border-radius: 0 $rad $rad 0;
}

@mixin mid {
    padding: $pad $org $pad $org;
    margin: $mar 0 $mar 0;
    border-radius: 0 0 0 0;
}

@mixin none {
    padding: $pad $pad $pad $pad;
    margin: $mar $mar $mar $mar;
    border-radius: $rad $rad $rad $rad;
}

@mixin selected {
    background-color: $select-bg;
    @include design {
        font-weight: 500;
        color: $select-col;
    }
}

.co-dropdown-calendar-filter-tippy-content{
    display: flex;
    flex-direction: row;
    @include mobile
}


.dropdown-calendar{
    .react-datepicker {
        border: none;
    }
    .react-datepicker__triangle {
        border-bottom-color: #000000!important;
        &::before {
            border-bottom-color: #000000!important;
        }
    }
    .react-datepicker__month-container {
        background: #000000;
    }
    .react-datepicker__header {
        background: #000000;
        border-bottom: 1px solid #1E1E1E;
    }
    .react-datepicker__day-name {
        @include design {
            font-family: "Bebas Neue";
            font-size: 16px;
            color: #484848;
            letter-spacing: 0.23px;
            text-align: center;
        }
    }

    .react-datepicker__day {
        @include none;
        &:hover{
            @include none;
        }
        @include design {
            font-family: Roboto;
            font-size: 14px;
            color: #FFFFFF;
            letter-spacing: 0.2px;
            text-align: center;
        }
        &.react-datepicker__day--outside-month {
            @include design{
                color: #484848;
            }
        }
        &.react-datepicker__day--selected, &.react-datepicker__day--keyboard-selected {
            @include selected;
        }
        &--in-selecting-range{
            @include mid;
            transition: none;
        }
        &--in-range,&--in-range:hover,&--range-start,&--range-end,&--selected{
            @include selected;
            @include mid;
        }
        &--selected{
          @include none;
        }
        &--range-end,
        &--range-end:hover,
        &--selecting-range-end,
        &--selecting-range-end:hover,
        &--range-end.react-datepicker__day--in-selecting-range{
          @include right;
        }
        &.react-datepicker__day--disabled {
          @include design{
            color: #242424;
          }
        }
        &--range-start,
        &--range-start:hover,
        &--selecting-range-start,
        &--selecting-range-start:hover,
        &--range-start.react-datepicker__day--in-selecting-range{
            @include left;
        }

        &--range-start.react-datepicker__day--selected,
        &--selecting-range-start:hover.react-datepicker__day--selected.react-datepicker__day--selecting-range-end,
        &--range-start.react-datepicker__day--range-end{
            @include none;
        }

        //:not(.react-datepicker__day--in-range)
        &:first-child.react-datepicker__day--in-range:not(.react-datepicker__day--selected),
        &:first-child.react-datepicker__day--in-selecting-range{
            @include left;
        }
        &:last-child.react-datepicker__day--in-range:not(.react-datepicker__day--selected),
        &:last-child.react-datepicker__day--in-selecting-range{
            @include right;
        }

        &:first-child.react-datepicker__day--range-end.react-datepicker__day--in-range,
        &:first-child.react-datepicker__day--selecting-range-end.react-datepicker__day--in-selecting-range,
        &:last-child.react-datepicker__day--range-start.react-datepicker__day--in-range,
        &:last-child.react-datepicker__day--selecting-range-start.react-datepicker__day--in-selecting-range{
            @include none;
        }

        &:not(.react-datepicker__day--disabled) {
            &:hover {
                @include selected;
            }
        }
        @include fast-transition();
    }

    .§{
        display: flex;
        align-items: baseline;
        flex-direction: row;
        justify-content: space-between;
    }
}

.co-btn-dropdown{
    .date-filter-title{
        min-width: 124px;
    }
}

.co-btn-dropdown.tippy.filter-calendar-tippy{
    padding: 26px 40px 12px 32px;
    margin: 0 auto;
}

.tippy-tooltip.dropdown-filter-theme.dropdown-calendar-filter-theme{
    padding-top: 16px;
    padding-right: 24px;
    padding-bottom: 16px;
}

.tippy-popper{
    .co-dropdown-filter-left{
        height: 270px;
        .co-dropdown-calendar-filter_dropdown{
            display: flex;
            height: 100%;
            justify-content: space-between;
            border-right: 1px solid #171717;
            padding: 0;
            margin: 24px 0;
        }
    }
    .co-dropdown-filter-right{
        padding-left: 24px;
    }
}
