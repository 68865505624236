@import "../../Layout.scss";
.virtual-family-page {
    @include in-grid($horizontal: stretch, $vertical: stretch, $row-start: 2);
    @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: auto auto 1fr auto);
    -ms-grid-rows: auto auto auto auto; //flexbox in 1fr row has 0 height on ie11
    background: rgb(250, 250, 250);
    @include mobile {
        @include grid($cols: auto 1fr auto, $rows: auto auto 1fr auto);
    }
}

.virtual-family-page-header-background {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3);
    background: #ffcd00;
    box-shadow: 0 7px 10px 0 rgba(0,0,0,.05);
    z-index: 1;
}

.virtual-family-page-body-background {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3, $row-start: 3);
}

.virtual-family-page-body {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3, $row-start: 3);
    @include grid($rows: 1fr, $cols: 1fr);
    padding: 0px 0px 64px 0px;
    @include mobile {
        padding: 0px 0px 24px 0px;
    }
}

.virtual-family-page-loader {
    @include in-grid($col-start: 2, $row-start: 3);
}

.virtual-family-page-ad-background {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3, $row-start: 4, $row-span: 2);
    background: black;
}

.virtual-family-page-ad {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 4, $row-span: 2);
    padding: 48px 0px 48px 0px;
    @include mobile {
        padding: 16px 0px 16px 0px;
    }
}

.virtual-family-page-ad_img {
    width: 100%;
    user-select: none;
    cursor: pointer;
}
