@import "../../../Layout.scss";
.dials {
    display: grid;
    @include grid($cols: auto auto auto, $rows: 1fr);
    @include mobile {
        // display: none;
        @include grid($cols: 1fr 1fr, $rows: 1fr auto);
        width: 100%;
        margin: 20px 0px 0px 0px;
    }
}

.dial {
    @include grid($cols: 1fr, $rows: 1fr auto 1fr);
    width: 132px;
    height: 132px;
    border-radius: 50%;
    background: rgb(30, 30, 30);
    @include mobile {
        background: none;
    }
    flex: 0 0 auto;
    position: relative;
    &.outlined {
        border: 4px solid rgb(255, 205, 0);
        padding: 6px;
        @include mobile {
            width: auto;
            height: auto;
            border: none;
            padding: 0px;
        }
    }
    &.clickable {
        user-select: none;
        cursor: pointer;
    }
    &.first {
        @include in-grid();
        @include mobile {
            @include in-grid($row-start: 2, $horizontal: stretch);
            width: auto;
            height: 92px;
            background: #1E1E1E;
            border-radius: 8px 8px 8px 46px;
            margin: 12px 4px 0px 0px;
        }
    }
    &.third {
        @include in-grid($col-start: 3);
        @include mobile {
            @include in-grid($col-start: 2, $row-start: 2, $horizontal: stretch);
            width: auto;
            height: 92px;
            background: #1E1E1E;
            border-radius: 8px 8px 46px 8px;
            margin: 12px 0px 0px 4px;
        }
    }
}

.dial-large {
    width: 168px;
    height: 168px;
    margin: 0px 24px 0px 24px;
    border-radius: 50%;
    background: rgb(30, 30, 30);
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    @include grid($cols: 1fr, $rows: 1fr);
    .dial {
        @include in-grid();
        @include mobile {
            @include in-grid($vertical: start);
        }
    }
    &.second {
        @include in-grid($col-start: 2);
        @include mobile {
            @include in-grid($col-span: 2, $horizontal: stretch);
            width: auto;
            height: 104px;
            background: #1E1E1E;
            border-radius: 46px 46px 8px 8px;
            margin: 0px;
        }
    }
}

.dial-large-completion {
    @include in-grid($horizontal: stretch, $vertical: stretch);
    width: 168px;
    height: 168px;
    @include mobile {
        display: none;
    }
    >.VictoryContainer {
        >svg {
            pointer-events: none!important; //override pie chart inline style
        }
    }
}

.dial-large-completion-bg-mobile {
    display: none;
    @include mobile {
        display: block;
        @include in-grid($vertical: end);
        width: calc(100% - 16px);
        height: 4px;
        background: #484848;
        border-radius: 2px;
        margin: 8px;
    }
}

.dial-large-completion-mobile {
    display: none;
    @include mobile {
        display: block;
        @include in-grid($vertical: end);
        width: calc(50% - 16px);
        height: 4px;
        background: #FFCD00;
        border-radius: 2px;
        margin: 8px;
    }
}

.dial-text-top {
    @include in-grid($vertical: end);
    margin: 12px 8px 0px 12px;
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.17px;
        line-height: 14px;
        text-align: center;
    }
    @include mobile {
        margin: 12p 24px 0px 24px;
    }
}

.dial-text-middle {
    @include in-grid($row-start: 2);
    margin: 4px 4px 0px 4px;
    position: relative;
    @include design {
        color: #FFCD00;
        font-family: "Bebas Neue";
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 0.78px;
        line-height: 38px;
        text-align: center;
    }
    @include mobile {
        margin: 4px 24px 0px 24px;
        @include design {
            font-size: 26px;
            letter-spacing: 0.64px;
        }
    }
}

.dial-text-floating {
    position: absolute;
    top: -6px;
    right: -12px;
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #FFCD00;
    z-index: 1;
    @include design {
        color: black;
        font-family: "Bebas Neue";
        font-size: 22px;
        font-weight: bold;
        letter-spacing: 0.78px;
        line-height: 38px;
        text-align: center;
    }
    &::before {
        content: '+';
        color: black;
    }
    @include mobile {
        right: -40px;
        top: 12px;
    }    
}

.dial-text-bottom {
    @include in-grid($vertical: start, $row-start: 3);
    margin: 4px 12px 8px 12px;
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.17px;
        line-height: 14px;
        text-align: center;
    }
    @include mobile {
        margin: 4px 24px 0px 24px;
    }
}