@import "../../../Layout.scss";
@import "../../../Animation.scss";
.manage-users {
    margin: 16px 0px 0px 0px;
    background: white;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
    position: relative;
    @include mobile {
        box-shadow: none;
        margin: 48px 16px 0px 0px;
    }
}

.manage-users-content {
    @include grid($cols: 1fr, $rows: 1fr);
    @include mobile {
        margin: 16px 0px 0px 0px;
    }
}

.manage-users-loader {
    @include in-grid();
    height: 256px;
}

.manage-users_label {
    @include in-grid($horizontal: start);
    margin: 32px 0px 0px 32px;
    @include mobile {
        margin: 0px 0px 0px 16px;
    }
}

.manage-users_label-img {
    width: 38px;
    height: 38px;
    vertical-align: middle;
}

.manage-users_label-text {
    @include in-grid($horizontal: start, $col-start: 2);
    margin: 0px 0px 0px 12px;
    vertical-align: middle;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.5px;
        line-height: 24px;
    }
}

.manage-users-table {
    @include in-grid($horizontal: stretch, $vertical: stretch);
}

.manage-users_button-add {
    @include in-grid($horizontal: end, $col-start: 2);
    margin: 32px 32px 0px 0px;
    @include mobile {
        margin: 0px;
        padding: 10px 32px 10px 32px;
    }
}

.users-table-data-row-actions {
    opacity: 0;
    @include default-transition(opacity);
}

.table-body-row {
    &.users {
        &:hover {
            .users-table-data-row-actions {
                opacity: 1;
            }
        }
    }
}

.users-table-data-row_button {
    @include fast-transition(opacity);
    &.delete {
        margin: 0px 0px 0px 16px;
    }
    opacity: 0.24;
    &:hover {
        opacity: 1;
    }
}

.users-table-data-row_button_img {
    width: 16px;
    height: 16px;
}