@import "../../../Layout.scss";
@import "../../../Animation.scss";
.cards {
    margin: 64px 0px 0px 0px;
    flex: 0 0 calc(50% - 32px);
    @include mobile {
        flex: 0 0 auto;
        margin: 48px 0px 0px 0px;
    }
    &.invisible { 
        visibility: hidden;
    }
}

.cards_title {
    margin: 0px;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.59px;
        line-height: 29px;
    }
}

.cards_show-all-button {
    &:hover {
        .cards_show-all-button_img {
            opacity: 1;
        }
        .cards_show-all-button_text {
            @include design {
                color: black;
            }
        }
    }
}

.cards_show-all-button_text {
    vertical-align: middle;
    @include fast-transition(color);
    @include design {
        color: #C1C1C1;
        font-family: "Bebas Neue";
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.44px;
        line-height: 23px;
        text-align: right;
    }
}

.cards_show-all-button_img {
    vertical-align: middle;
    margin: 0px 0px 0px 8px;
    opacity: 0.24;
    @include fast-transition(opacity);
}

.business-overview-cards-content {
    @include grid($cols: 1fr, $rows: 1fr);
}

.business-overview-cards-loader {
    @include in-grid();
    height: 256px;
}

.cards-collection {
    @include in-grid($horizontal: stretch, $vertical: stretch);
    margin: 24px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    @include mobile {
        margin: 16px 0px 0px 0px;
    }
}

.cards-collection-item-outer {
    @include grid($cols: 1fr, $rows: 1fr);
    &:not(:first-child) {
        margin: 8px 0px 0px 0px;
    }
}

.cards-collection-item {
    @include in-grid($horizontal: stretch, $vertical: stretch);
    padding: 24px;
    box-sizing: border-box;
    background: white;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
    &.fade {
        box-shadow: none;
    }
}

.cards-collection-item-fade {
    @include in-grid($horizontal: stretch, $vertical: stretch);
    background-image: linear-gradient(to bottom, rgba(250, 250, 250, 0), rgba(250, 250, 250, 1) 50%);
}

.cards-collection-item_text {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
}

.cards-collection-item_title {
    margin: 0px;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 0.78px;
        line-height: 38px;
    }
}

.cards-collection-item_number {
    margin: 0px 0px 0px 8px;
    @include design {
        color: #BDBDBD;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
    }
}

.cards-collection-seek {
    margin: 8px 0px 0px 0px;
    display: flex;
    align-items: center;
}

.cards-collection-seek_fill {
    height: 3px;
    flex: 0 0 auto;
    background: rgb(255, 95, 95);
    &.yellow {
        background: rgb(240, 180, 35);
    }
}

.cards-collection-seek_button {
    margin: 0px;
    padding: 2px 8px 2px 8px;
    background: rgb(255, 95, 95);
    border-radius: 4px;
    flex: 0 0 24px;
    @include design {
        color: #FFFFFF;
        font-family: "Bebas Neue";
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0.5px;
        line-height: 16px;
        text-align: center;
    }
    &.yellow {
        background: rgb(240, 180, 35);
        @include design {
            color: #212121;
            font-family: "Bebas Neue";
            font-size: 13px;
            font-weight: bold;
            letter-spacing: 0.5px;
            line-height: 16px;
            text-align: center;
        }
    }
}

.cards-collection-seek_nofill {
    height: 3px;
    background: rgb(224, 224, 224);
    flex: 1 1 100%;
}

.overview-cards-loader {
    height: 256px;
}