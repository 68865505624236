@import "../../../Layout.scss";
@import "../../../Animation.scss";

.virtual_family-families {
    @include in-grid($horizontal: stretch, $vertical: stretch);
    @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: 1fr);
    -ms-grid-rows: auto; //flexbox in 1fr row has 0 height on ie11
    background: rgb(250, 250, 250);
    // min-height: 100%;
    padding: 32px;
    @include mobile {
        @include grid($cols: auto 1fr auto, $rows: 1fr);
    }
}

.virtual_family-families-loader {
    @include in-grid($col-start: 2);
}

.virtual_family-families-no_content {
    @include in-grid($col-start: 2, $vertical: end);
}

.virtual_family-families-collection {    
    @include in-grid($horizontal: stretch, $vertical: start, $col-start: 2);
    display: flex;
    flex-wrap: wrap;
}

.virtual_family_cell {
    width: 496px;
    // height: 186px;
    padding: 24px;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: #FFFFFF;
    box-shadow: 0 7px 10px 0 rgba(0,0,0,0.07);
    &:nth-of-type(2n+2) {
        margin: 16px 0px 16px 16px;
    }
    &:nth-of-type(2n+1) {
        margin: 16px 16px 16px 0px;
    }
    @include mobile {
        width: 100%;
        &:nth-of-type(2n+2) {
            margin: 16px 0px 0px 0px;
        }
        &:nth-of-type(2n+1) {
            margin: 16px 0px 0px 0px;
        }
    }
}

.virtual_family_cell-title_button {
    width: 100%;
    background: none;
    border: none;
    user-select: none;
    cursor: pointer;
    box-sizing: border-box;
    opacity: 0.5;
    text-decoration: none;
    white-space: nowrap;
}

.virtual_family_cell-title_bar {
    @include grid($cols: 1fr auto, $rows: 1fr);
    &:hover {
        .virtual_family_cell-title_bar-img {
            opacity: 1;
        }
    }
}

.virtual_family_cell-title_bar-text {
    @include in-grid($horizontal: start);
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 28px;
        letter-spacing: 0.68px;
        line-height: 31px;
    }
}

.virtual_family_cell-title_bar-img {
    @include in-grid($col-start: 2, $horizontal: end);
    opacity: 0.3;
    @include fast-transition();
}

.virtual_family_cell-info {
    @include grid($cols: 1fr auto, $rows: 1fr);
}
.virtual_family_cell-info-collection {
    display: flex;
    flex-wrap: wrap;
    margin: 24px 0px 0px 0px;
}

.virtual_family_info_field {
    @include grid($cols: auto 1fr, $rows: auto 1fr);
    width: 114px;
    height: 54px;
    margin: 24px 24px 0px 0px;
    @include mobile {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: calc(50% - 24px);
        width: unset;
    }
}

.virtual_family_info_field-title {
    @include in-grid($col-span: 2, $horizontal: start);
    @include design {
        color: #909090;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 900;
        letter-spacing: 0.6px;
        line-height: 14px;
    }
}

.virtual_family_info_field-img {
    @include in-grid($row-start: 2);
    margin: 8px 0px 0px 0px;
    user-select: none;
    pointer-events: none;
}

.virtual_family_info_field-text {
    @include in-grid($row-start: 2, $col-start: 2);
    margin: 8px 0px 0px 8px;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 24px;
        font-weight: 300;
        letter-spacing: 0.59px;
        line-height: 31px;
    }
}

.virtual_family_cell-info-button {
    @include in-grid($col-start: 2, $horizontal: end, $vertical: end);
    height: 32px;
    width: 32px;
    border: 1px solid #E7E7E7;
    border-radius: 2px;
    box-shadow: 0 7px 10px 0 rgba(0,0,0,0.07);
    box-sizing: border-box;
}

.virtual_family_progress_bar {
    height: 4px;
    width: 100%;
    margin: 24px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.virtual_family_progress_bar-first {
    display: block;
    width: 100%;
    height: 100%;
    background: #FFCD00;
    border-radius: 2px;
}

.virtual_family_progress_bar-second {
    display: block;
    height: 100%;
    background: #E7E7E7;
    border-radius: 2px;
}

.virtual_family_cell-info-hover-edit_button {
    @include design {
        font-family: Roboto;
        font-size: 14px;
        color: #FFFFFF;
        letter-spacing: 0.2px;
        text-align: center;
        opacity: 0.3;
    }
    &:hover {
        opacity: 1;
    }
    @include fast-transition();
}