@import "../../../Layout.scss";
@import "../../../Animation.scss";
.person-overview-cards {
    margin: 32px 12px 0px 0px;
    @include tablet {
        margin: 48px 16px 0px 16px;
    }
}

.person-overview-cards_title {
    vertical-align: middle;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.59px;
        line-height: 29px;
    }
}

.person-overview-cards_content {
    @include grid($cols: 1fr, $rows: 1fr);
    @include tablet {
        width: 100%;
    }
}

.person-overview-cards_loader_container {
    @include in-grid($horizontal: stretch, $vertical: stretch);
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.person-overview-cards_collection {
    @include in-grid($horizontal: stretch, $vertical: stretch);
    display: flex;
    flex-direction: column;
    //margin: 0px 12px 0px 0px;
    @include tablet {
        margin: 0px;
    }
}

.person-overview-cards_collection_item {
    @include grid($cols: auto auto 1fr auto, $rows: 1fr 1fr);
    margin: 8px 0px 0px 0px;
    padding: 16px 24px 16px 24px;
    background: white;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
    box-sizing: border-box;
    border: 1px solid transparent;
    &:hover {
        border: 1px solid black;
        .person-overview-cards_collection_item_delete-button, .person-overview-cards_collection_item_dropdown {
            visibility: visible;
        }
    }
    @include tablet {
        .person-overview-cards_collection_item_delete-button, .person-overview-cards_collection_item_dropdown {
            visibility: visible;
        }
    }
    @include fast-transition();
}

.person-overview-cards_collection_item_card-img {
    &.img1 {
        @include in-grid($row-span: 2);
        width: 50px;
    }
    &.img2 {
        @include in-grid($col-start: 2, $row-span: 2);
        margin: 0px 0px 0px 8px;
        width: 50px;
    }
    &.img1, &.img2 {
        &:not([src]) {
            display: none;
        }
    }
    &.img-cred {
      @include in-grid($row-span: 2);
      border-radius: 3px;
      width: 50px;
    }
}

.person-overview-cards_collection_item_card-number {
    @include in-grid($horizontal: start, $col-start: 3);
    margin: 0px 0px 0px 16px;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.73px;
        line-height: 24px;
        text-align: center;
    }
    @include mobile-s {
        white-space: nowrap;
    }
}

.person-overview-cards_collection_item_text {
    @include in-grid($horizontal: start, $col-start: 3, $row-start: 2);
    margin: 0px 0px 0px 16px;
    @include design {
        color: #909090;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0.2px;
    }
}

.person-overview-cards_collection_item_delete-button {
    @include in-grid($col-start: 4, $row-span: 2);
    visibility: hidden;
    padding: 12px;
    &:hover {
        .person-overview-cards_collection_item_delete-button_img  {
            opacity: 1;
        }
    }
}
.person-overview-cards_collection_item_dropdown {
  @include in-grid($col-start: 4, $row-span: 2);
  visibility: hidden;
  padding: 0 !important;
  .co-btn-dropdown_text {
    height: 32px;
    width: 32px;
    border: 1px solid #E7E7E7;
    border-radius: 2px;
    box-shadow: 0 7px 10px 0 rgba(0,0,0,0.07);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: #000;
    }
  }
}

.person-overview-cards_collection_item_delete-button_img {
    width: 16px;
    height: 16px;
    opacity: 0.24;
    @include fast-transition(opacity);
}
