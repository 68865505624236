@import "../Layout.scss";

.container {
    display: flex;
    flex-direction: column;
    gap: 6px;

    textarea {
        box-sizing: border-box;
        outline: none;
        padding: 10px 14px;
        resize: none;
        width: 100%;

        @include design {
            background: #FFF;
            border-radius: 2px;
            border: 1px solid #E4E7EC;
            box-shadow: 0px 0px 6px 0px rgba(20, 20, 20, 0.07);
            color: #909090;
            font-family: Roboto;
            font-size: 0.875em;
            font-style: normal;
            font-weight: 300;
            line-height: 18px;
            letter-spacing: 0.2px;
        }
    }

    .hint {
        @include design {
            color: #909090;
            font-family: Roboto;
            font-size: 0.875em;
            font-weight: 300;
            line-height: 18px;
            letter-spacing: 0.2px;
        }
    }
}
