@import "../../../Layout.scss";
@import "../../../Animation.scss";
.welcome {
    @include grid($cols: auto 1fr, $rows: auto auto);
    max-width: 492px;
    @include mobile {
        max-width: unset;
    }
}

.welcome-title {
    @include in-grid($horizontal: start, $col-span: 2);
    min-height: 58px;
    margin: 0px 0px 8px 0px;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 48px;
        font-weight: 300;
        letter-spacing: 1.44px;
        line-height: 58px;
    }
    @include default-transition(opacity, transform);
    &:not(:empty) {
        opacity: 1;
        transform: translate(0px);
    }
    &:empty {
        opacity: 0;
        transform: translateX(16px);
    }
    @include mobile {
        margin: 0px 0px 4px 0px;
    }
}

.welcome-img-shield {
    @include in-grid($row-start: 2);
    width: 64px;
    height: 64px;
}

.welcome-img {
    @include in-grid($row-start: 2);
    &.in-small {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        position: absolute;
        left: 8px;
    }
}

.welcome-notifications {
    display: flex;
    flex-direction: column;
}

.welcome-text {
    @include in-grid($horizontal: start, $col-start: 2, $row-start: 2);
    margin: 0px 0px 0px 8px;
    @include default-transition(opacity, transform);
    &:empty {
        opacity: 0;
        transform: translateX(16px);
    }
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
        text-decoration: none;
        font-weight: 500;
    }
}