@import "../../../Layout.scss";
.top_loyalty_ad {
    @include grid($cols: auto 1fr auto, $rows: auto 1fr);
    padding: 32px;
    background: rgb(30, 30, 30);
    border-radius: 2px;
}

.top_loyalty_ad-icon {
    @include in-grid($horizontal: start);
    margin: 0px 12px 0px 0px;
}

.top_loyalty_ad-title {
    @include in-grid($horizontal: start, $col-start: 2);
    white-space: nowrap;
    @include design {
        height: 24px;
        width: 284px;
        color: #FFFFFF;
        font-family: "Bebas Neue";
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.59px;
    }
}

.top_loyalty_ad-close_button {
    @include in-grid($horizontal: end, $col-start: 3);
    width: 16px;
    height: 16px;
}

.top_loyalty_ad-close_button-img {
    width: 16px;
    height: 16px;
}

.top_loyalty_ad-text {
    @include in-grid($horizontal: start, $row-start: 2, $col-span: 2);
    margin: 12px 0px 0px 0px;
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.2px;
        line-height: 22px;
    }
}

.top_loyalty_ad-open_button-container {
    @include in-grid($horizontal: end, $vertical: end, $row-start: 2, $col-start: 3);
}

.top_loyalty_ad-open_button {
    display: block;
    background: rgb(255, 205, 0);
    border: none;
    margin: 0px 0px 0px 16px;
    padding: 14px 32px 14px 32px;
    text-decoration: none;
    cursor: pointer;
    @include design {
        color: #1E1E1E;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
        text-align: center;
    }
}