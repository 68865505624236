.tippy-tooltip.light-border-theme {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #F0B423;
    color: #26323d;
    box-shadow: 0 3px 14px -.5px rgba(0, 8, 16, .08);
    padding: 0px;
    border-radius: 0px;
}

.tippy-tooltip.light-border-theme .tippy-backdrop {
    background-color: #fff
}

.tippy-tooltip.light-border-theme .tippy-arrow:after,
.tippy-tooltip.light-border-theme .tippy-arrow:before,
.tippy-tooltip.light-border-theme .tippy-roundarrow:after,
.tippy-tooltip.light-border-theme .tippy-roundarrow:before {
    content: "";
    position: absolute;
    z-index: -1
}

.tippy-tooltip.light-border-theme .tippy-roundarrow {
    fill: #fff
}

.tippy-tooltip.light-border-theme .tippy-roundarrow:after {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAyNCA4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zOnNlcmlmPSJodHRwOi8vd3d3LnNlcmlmLmNvbS8iIHN0eWxlPSJmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MS40MTQyMTsiPjxwYXRoIGQ9Ik0zLDhjMCwwIDIuMDIxLC0wLjAxNSA1LjI1MywtNC4yMThjMS4zMzEsLTEuNzMxIDIuNTQ0LC0yLjc3NSAzLjc0NywtMi43ODJjMS4yMDMsLTAuMDA3IDIuNDE2LDEuMDM1IDMuNzYxLDIuNzgyYzMuMjUxLDQuMjIzIDUuMjM5LDQuMjE4IDUuMjM5LDQuMjE4bC0xOCwwWiIgc3R5bGU9ImZpbGw6IzAwMDgxNjtmaWxsLW9wYWNpdHk6MC4yMDM5MjI7ZmlsbC1ydWxlOm5vbnplcm87Ii8+PC9zdmc+);
    background-size: 24px 8px;
    width: 24px;
    height: 8px;
    left: 0;
    top: 0;
    fill: rgba(0, 8, 16, .15)
}

.tippy-popper[x-placement^=top] .tippy-tooltip.light-border-theme .tippy-roundarrow:after {
    top: 1px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.tippy-popper[x-placement^=top] .tippy-tooltip.light-border-theme .tippy-arrow {
    border-top-color: #fff
}

.tippy-popper[x-placement^=top] .tippy-tooltip.light-border-theme .tippy-arrow:after {
    border-top: 7px solid #fff;
    top: -7px
}

.tippy-popper[x-placement^=top] .tippy-tooltip.light-border-theme .tippy-arrow:before {
    border-top: 7px solid #F0B423;
    bottom: -1px
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.light-border-theme .tippy-roundarrow:after {
    top: -1px
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.light-border-theme .tippy-arrow {
    border-bottom-color: #fff
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.light-border-theme .tippy-arrow:after {
    border-bottom: 7px solid #fff;
    bottom: -7px
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.light-border-theme .tippy-arrow:before {
    border-bottom: 7px solid #F0B423;
    bottom: -6px
}

.tippy-popper[x-placement^=left] .tippy-tooltip.light-border-theme .tippy-roundarrow:after {
    left: 1px;
    top: 0;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.tippy-popper[x-placement^=left] .tippy-tooltip.light-border-theme .tippy-arrow {
    border-left-color: #fff
}

.tippy-popper[x-placement^=left] .tippy-tooltip.light-border-theme .tippy-arrow:after {
    border-left: 7px solid #fff;
    left: -7px
}

.tippy-popper[x-placement^=left] .tippy-tooltip.light-border-theme .tippy-arrow:before {
    border-left: 7px solid #F0B423;
    left: -6px
}

.tippy-popper[x-placement^=right] .tippy-tooltip.light-border-theme .tippy-roundarrow:after {
    left: -1px;
    top: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg)
}

.tippy-popper[x-placement^=right] .tippy-tooltip.light-border-theme .tippy-arrow {
    border-right-color: #fff
}

.tippy-popper[x-placement^=right] .tippy-tooltip.light-border-theme .tippy-arrow:after {
    border-right: 7px solid #fff;
    right: -7px
}

.tippy-popper[x-placement^=right] .tippy-tooltip.light-border-theme .tippy-arrow:before {
    border-right: 7px solid #F0B423;
    right: -6px
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.light-border-theme .tippy-arrow,
.tippy-popper[x-placement^=bottom] .tippy-tooltip.light-border-theme .tippy-roundarrow,
.tippy-popper[x-placement^=top] .tippy-tooltip.light-border-theme .tippy-arrow,
.tippy-popper[x-placement^=top] .tippy-tooltip.light-border-theme .tippy-roundarrow {
    -webkit-transform: translateX(-1px);
    transform: translateX(-1px)
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.light-border-theme .tippy-arrow:after,
.tippy-popper[x-placement^=bottom] .tippy-tooltip.light-border-theme .tippy-arrow:before,
.tippy-popper[x-placement^=top] .tippy-tooltip.light-border-theme .tippy-arrow:after,
.tippy-popper[x-placement^=top] .tippy-tooltip.light-border-theme .tippy-arrow:before {
    left: -7px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent
}

.tippy-popper[x-placement^=left] .tippy-tooltip.light-border-theme .tippy-arrow,
.tippy-popper[x-placement^=left] .tippy-tooltip.light-border-theme .tippy-roundarrow,
.tippy-popper[x-placement^=right] .tippy-tooltip.light-border-theme .tippy-arrow,
.tippy-popper[x-placement^=right] .tippy-tooltip.light-border-theme .tippy-roundarrow {
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px)
}

.tippy-popper[x-placement^=left] .tippy-tooltip.light-border-theme .tippy-arrow:after,
.tippy-popper[x-placement^=left] .tippy-tooltip.light-border-theme .tippy-arrow:before,
.tippy-popper[x-placement^=right] .tippy-tooltip.light-border-theme .tippy-arrow:after,
.tippy-popper[x-placement^=right] .tippy-tooltip.light-border-theme .tippy-arrow:before {
    top: -7px;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent
}