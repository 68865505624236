@import "../../../Layout.scss";
@import "../../../Animation.scss";
.order-card-modal {
    width: 1024px;
    margin: auto;
    box-sizing: border-box;
    background: rgb(30, 30, 30);
    @include default-transition(width);
    &.small {
        width: 576px;
        box-sizing: border-box;
    }
    &.error {
        border: 2px solid tomato;
    }
}

.order-card-content-size-anim {
    @include default-transition(height);
    overflow: hidden;
    @include grid($cols: 1fr, $rows: 1fr);
    .order-card-modal-page-1 {
        @include in-grid($horizontal: stretch, $vertical: stretch)
    }
    .order-card-modal-page-2 {
        @include in-grid($horizontal: stretch, $vertical: stretch)
    }
    .order-card-modal-page-3 {
        @include in-grid($horizontal: stretch, $vertical: stretch)
    }
}

.order-card-modal_title {
    margin: 32px 0px 0px 32px;
    @include design {
        color: #FFCD00;
        font-family: "Bebas Neue";
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 0.78px;
        line-height: 38px;
    }
    &.error {
        color: tomato;
    }
}

.order-card-modal_close-button {
    margin: 32px 32px 0px 0px;
    padding: 14px;
}

.order-card-modal_close-button_img {
    width: 12px;
    height: 12px;
}

.order-card-modal-content {
    @include grid($cols: 1fr 1fr, $rows: auto 1fr);
}

.order-card-modal-content_content {
    @include in-grid($horizontal: stretch, $col-span: 2);
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.23px;
        line-height: 24px;
    }
}

.order-card-modal-loader {
    @include in-grid($horizontal: center, $vertical: center, $col-start: 2, $row-start: 3);
    margin: 48px 0px 0px 0px;
}

.order-card-modal-top-text {
    margin: 32px 32px 0px 32px;
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.23px;
        line-height: 24px;
    }
}

.cards-modal-input-name {
    width: 272px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 0px 0px 0px 32px;
}

.cards-modal-input-name_label {
    margin: 0px;
}

.cards-modal-input-name_label-left {
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 24px;
    }
}

.cards-modal-input-name_label-right {
    float: right;
    @include design {
        color: rgba(255, 255, 255, 0.5);
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 24px;
        text-align: right;
    }
}

.cards-modal-input-name_input {
    background: rgb(22, 22, 22);
    border: none;
    padding: 10px 4px 10px 40px;
    @include design {
        color: #FFCD00;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.23px;
        line-height: 24px;
    }
    background-size: 16px auto;
    background-position: center left 16px;
    background-repeat: no-repeat;
    &.card {
        background-image: url(../../../assets/Icon_Card_Small_Gray.svg);
    }
}

.cards-modal-input-name_limit {
    background: rgb(22, 22, 22);
    border: none;
    display: flex;
    justify-content: stretch;
    align-items: center;
}

.cards-modal-input-name_limit-input {
    background: none;
    border: none;
    padding: 10px 4px 10px 4px;
    text-align: right;
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.23px;
        line-height: 24px;
    }
}

.cards-modal-input-name_limit-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 auto;
}

.cards-modal-input-name_limit-month {
    margin: 0px 0px 0px 4px;
    @include design {
        color: rgba(255, 255, 255, 0.5);
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.23px;
        line-height: 24px;
    }
}

.cards-modal-input-name_limit-company {
    margin: 0px 16px 0px 0px;
    @include design {
        color: rgba(255, 255, 255, 0.5);
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.23px;
        line-height: 24px;
    }
}

.order-card-modal-content-cards {
    display: flex;
    flex-direction: column;
    margin: 48px 0px 0px 0px;
}

.order-card-modal-content-card {
    display: flex;
    justify-content: stretch;
    align-items: flex-start;
    padding: 12px 0px 24px 0px;
    &:nth-child(1n) {
        border-top: 1px solid rgb(64, 64, 64);
        border-bottom: 1px solid rgb(64, 64, 64);
    }
}

.cards-modal-cards-limit {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin: 0px 0px 0px 32px;
    width: 190px;
}

.cards-modal-cards-limit_top-label {
    margin: 0px;
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 24px;
    }
}

.cards-modal-cards-limit_input-container {
    height: 44px;
    background: rgb(22, 22, 22);
    padding: 0px 16px 0px 16px;
    box-sizing: border-box;
}

.cards-modal-cards-limit_input-input {
    background: none;
    border: none;
    padding: 10px 4px 10px 0px;
    @include design {
        color: #FFCD00;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.23px;
        line-height: 24px;
    }
}

.cards-modal-cards-limit_input-label {
    margin: 0px;
    @include design {
        color: rgba(255, 255, 255, 0.5);
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.23px;
        line-height: 24px;
    }
}

.order-card-modal_delete-button {
    padding: 14px;
    margin: 25px 32px 0 auto;
}

.order-card-modal_delete-button_img {
    width: 12px;
    height: 12px;
}

.order-card-modal-buttons {
    margin: 24px 32px 32px 32px;
    display: flex;
}

.order-card-modal-add-card-button {
    width: 272px;
    height: 44px;
    padding: 0px;
    border: dashed 1px rgb(60, 60, 60);
    border-radius: 2px;
    box-sizing: border-box;
    text-align: left;
}

.order-card-modal-add-card-button_img {
    margin: 0px 0px 0px 18px;
}

.order-card-modal-add-card-button_text {
    margin: 0px 0px 0px 8px;
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
    }
}

.order-card-modal-next-button {
    width: 240px;
    height: 44px;
    margin: 0px 0px 0px auto;
    background: rgb(255, 205, 0);
    border-radius: 2px;
}

.order-card-modal-next-button_text {
    vertical-align: middle;
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
        text-align: center;
    }
}

.order-card-modal-next-button_img {
    margin: 0px 0px 0px 8px;
    vertical-align: middle;
}

.cards-modal-cards-dropdown {
    &.fuel {
        width: 188px;
        margin: 0px 0px 0px 32px;
    }
    &.product {
        width: 188px;
        margin: 0px 0px 0px 2px;
    }
    &.station {
        margin: 10px 0px 0px 0px;
        @include in-grid($horizontal: stretch, $col-span: 2, $row-start: 4);
        .co-btn-dropdown {
            width: 100%;
        }
    }
}

.cards-modal-cards-dropdown_label {
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 24px;
    }
}

.order-card-modal-dropdown {
    &.co-btn-dropdown {
        width: 188px;
        &.station {
            width: 464px;
        }
        height: 44px;
        background: rgb(22, 22, 22);
        padding: 0px;
        margin: 0px;
        justify-content: flex-start;
        .co-btn-dropdown_text {
            margin: 0px 0px 0px 16px;
        }
        .co-btn-dropdown_img-left {
            display: none;
        }
        .co-btn-dropdown_img-right {
            margin: 0px 16px 0px auto;
        }
    }
    &.co-btn-dropdown_text {
        @include design {
            color: #FFCD00;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 300;
            letter-spacing: 0.23px;
            line-height: 24px;
        }
    }
}

.order-card-modal-page-2 {
    @include grid($cols: 1fr 1fr, $rows: auto);
    margin: 48px 32px 32px 32px;
}

.order-card-modal-page-2-inputs {
    @include in-grid($horizontal: stretch, $vertical: stretch);
    @include grid($cols: 1fr 1fr, $rows: auto auto auto auto 1fr);
    margin: 0px 16px 0px 0px;
}

.order-card-modal-page-2-map {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2);
    margin: 0px 0px 0px 16px;
}

.order-card-modal-page-2-top-text {
    @include in-grid($col-span: 2);
    margin: 0px;
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.23px;
        line-height: 24px;
    }
}

.cards-modal-input {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    &.name {
        @include in-grid($horizontal: stretch, $row-start: 2);
        margin: 48px 1px 0px 0px;
    }
    &.personal-code {
        @include in-grid($horizontal: stretch, $col-start: 2, $row-start: 2);
        margin: 48px 0px 0px 1px;
    }
    &.personal-email {
        margin: 10px 0px 0px 0px;
        @include in-grid($horizontal: stretch, $col-span: 2, $row-start: 3);
    }
    &.location {
        margin: 48px 0px 0px 0px;
        @include in-grid($horizontal: stretch, $col-span: 2, $row-start: 4);
    }
}

.cards-modal-input_label {
    margin: 0px;
}

.cards-modal-input_label-left {
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 24px;
    }
}

.cards-modal-input_label-right {
    float: right;
    @include design {
        color: rgba(255, 255, 255, 0.5);
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 24px;
        text-align: right;
    }
}

.cards-modal-input_input {
    background: rgb(22, 22, 22);
    border: none;
    padding: 10px 4px 10px 10px;
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.23px;
        line-height: 24px;
    }
}

.order-card-modal-page-2_location-info-text {
    @include in-grid($horizontal: stretch, $col-span: 2, $row-start: 5);
    margin: 8px 0px 0px 0px;
    @include design {
        color: rgba(255, 255, 255, 0.5);
        font-family: Roboto;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.2px;
        line-height: 24px;
    }
}

.order-card-modal-page-2_error {
    @include in-grid($horizontal: stretch, $col-span: 2, $row-start: 5);
    margin: 16px 0px 0px 0px;
    @include design {
        color: red;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.2px;
        line-height: 24px;
    }
}

.order-card-modal-page-2_back-button {
    @include in-grid($horizontal: start, $row-start: 6);
    width: 213px;
    height: 44px;
    margin: 48px 0px 0px 0px;
    border: solid 1px rgb(60, 60, 60);
    border-radius: 2px;
    box-sizing: border-box;
    text-align: center;
}

.order-card-modal-page-2_back-button_text {
    margin: 0px 0px 0px 8px;
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
        text-align: center;
    }
}

.order-card-modal-page-2_order-button {
    @include in-grid($horizontal: end, $row-start: 6, $col-start: 2);
    width: 213px;
    height: 44px;
    margin: 48px 0px 0px 0px;
    background: rgb(255, 205, 0);
    border-radius: 2px;
}

.order-card-modal-page-2_order-button_text {
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
        text-align: center;
    }
}

.order-card-modal-map-marker {
    width: 44px;
    height: 44px;
    transform: translateX(-22px) translateY(-22px);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
}

.order-card-modal-page-3 {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
}

.order-card-modal-page-3_info {
    margin: 48px 32px 0px 32px;
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.23px;
        line-height: 24px;
    }
}

.order-card-modal-page-3_close-button {
    height: 44px;
    border: 1px solid rgb(98, 98, 98);
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
    border-radius: 2px;
    margin: 48px 32px 32px 32px;
    text-align: center;
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
        text-align: center;
    }
}
