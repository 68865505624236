@import "../../Layout.scss";
.manage-page {
    @include in-grid($horizontal: stretch, $vertical: stretch, $row-start: 2);
    @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: auto 1fr auto);
    -ms-grid-rows: auto auto auto; //flexbox in 1fr row has 0 height on ie11
    background: rgb(250, 250, 250);
    @include mobile {
        @include grid($cols: auto 1fr auto, $rows: auto 1fr auto);
        background: white;
    }
}

.manage-page-header-placeholder {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3, $row-span: 1);
    height: 80px;
}

.manage-page-header-background {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3);
    background: #ffcd00;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.05);
}

.manage-header-static {
    @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: 1fr);
    position: fixed;
    left: 0;
    right: 0;
    top: 80;
    z-index: 1;
    height: 80px;
    @include mobile {
        @include grid($cols: auto 1fr auto, $rows: 1fr);
    }
}

.manage-page-body {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 2);
    padding: 48px 0px 48px 0px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    @include mobile {
        padding: 48px 0px 48px 0px;
    }
}

.manage-page-ad-background {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3, $row-start: 3);
    background: black;
}

.manage-page-ad {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 3);
    padding: 48px 0px 48px 0px;
    @include mobile {
        padding: 16px 0px 16px 0px;
    }
}

.manage-page-ad_img {
    width: 100%;
    user-select: none;
    cursor: pointer;
}