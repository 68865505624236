@import "../../Layout.scss";

.invoicing-page {
    @include in-grid($vertical: stretch, $horizontal: stretch, $row-start: 2);
    @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: auto 1fr);
    background: rgb(250, 250, 250);
    @include mobile {
        @include grid($cols: 16px 1fr 16px, $rows: auto 1fr);
    }
}
