@import "../../Layout.scss";
@import "../../Animation.scss";
.co-btn-text-outline {
    padding: 12px 32px 12px 32px;
    background: rgb(254, 254, 254);
    border: 1px solid rgb(231, 231, 231);
    box-sizing: border-box;
    @include fast-transition(color, border-color);
    @include design {
        color: #909090;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
        text-align: center;
    }
    &:hover {
        color: black;
        border: 1px solid black;
    }
}