@import "../../../Layout.scss";
@import "../../../Animation.scss";

$gap: 12px;

.olerex-plus-business {
    width: 480px;
    margin: 64px 0 0 0;

    @include mobile {
        width: auto;
    }

    .olerex-plus_title-text {
        line-height: 29px;
    }

    .olerex-plus-collection {
        margin-top: 24px;
    }
}

.olerex-plus-content {
    @include grid($cols: 1fr, $rows: 1fr);
    @include mobile {
        width: 100vw;
        margin-left: -16px;
        margin-right: -16px;
        overflow-x: scroll;
        overflow-y: none;
    }
}

.olerex-plus-loader-container {
    @include in-grid();
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.olerex-plus_title-text {
    vertical-align: middle;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.59px;
        line-height: 20px;
    }
}

.olerex-plus_help {
    width: 20px;
    height: 20px;
    margin: 0px 0px 0px $gap;
    vertical-align: middle;
}

.olerex-plus_show-all-button {
    margin: 0px $gap 0px 0px;
    @include mobile {
        margin: 0px 16px 0px 0px;
    }
    @include design {
        color: #C1C1C1;
        font-family: "Bebas Neue";
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.49px;
        line-height: 24px;
        text-align: right;
    }
    &:hover {
        color: black;
    }
}

.olerex-plus-collection {
    @include in-grid($vertical: start, $horizontal: stretch);
    margin: 16px (-$gap) (-$gap) (-$gap);
    padding: 0 0 16px $gap;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    gap: $gap;

    @include mobile {
        margin: 16px 0 0 0;
        padding: 0 0 16px 16px;
        flex-wrap: nowrap;
    }
}

.olerex-plus-cell {
    @include grid($cols: 1fr, $rows: 100px 1fr 1fr);

    width: 152px;
    height: 188px;
    flex: 0 0 auto;
    flex-basis: calc(100% / 3 - $gap);
    padding: 16px 0 16px 0px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);

    &.highlighted {
        background: rgb(255, 205, 0);
    }

    @include mobile {
        flex-basis: auto;
        width: 200px;
        &:last-child {
            margin-right: 12px;
        }
    }
}

@keyframes spin {
    from {
        transform: rotateZ(0deg);
    }
    to {
        transform: rotateZ(360deg);
    }
}

.olerex-plus-cell_img-shield {
    @include in-grid();
    width: 100px;
    height: 100px;
    border-radius: 50%;
    animation: spin 45s linear 0s infinite;
}

.olerex-plus-cell_img {
    @include in-grid();
    width: 84px;
    height: 84px;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    z-index: 0; //appear above the animated img-shield
    position: relative;
}

.olerex-plus-cell_counter {
    @include in-grid();
    width: 100px;
    height: 100px;
}

.olerex-plus-cell_title {
    @include in-grid($row-start: 2);
    margin: 0;
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.2px;
        line-height: 16px;
        text-align: center;
    }
}

.olerex-plus-cell_completion {
    @include in-grid($row-start: 3);
    margin: 0px;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.73px;
        line-height: 24px;
        text-align: center;
    }
}

.olerex-plus-cell_completion-alt_text {
    color: #E7A300;
}

.olerex-plus-cell_completion-alt_separator {
    color: rgb(203, 203, 203);
}

.olerex-plus-cell_percent {
    position: absolute;
    top: 0;
    right: -10px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Roboto;
    font-size: 11px;
    line-height: 1;
    font-weight: bold;
}
