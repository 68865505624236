@import "../../Layout.scss";
@import "../../Animation.scss";

.idCredit {
    @include in-grid($horizontal: stretch, $vertical: stretch, $row-start: 2);
    @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: auto auto auto 1fr);
    background: rgb(250, 250, 250);
    @include mobile {
        @include grid($cols: 16px 1fr 16px, $rows: auto auto auto 1fr);
    }
}

.headerPlaceholder {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2);
    height: 206px;
    width: 100%;
    @include mobile {
        height: 132px;
    }
}

.headerStatic {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3);
    @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: auto 1fr);
    @include mobile {
        @include grid($cols: auto 1fr auto, $rows: auto 1fr);
    }

    z-index: 1;

    &.fixed {
        position: fixed;
        position: sticky; // Override with sticky on browsers which support it
        left: 0px;
        right: 0px;
        top: 80px;

        @include not-mobile {
            &.animated {
                @include fast-transition(transform);
            }
            &.hiding {
                transform: translateY(-100%);
            }
        }

        @include mobile {
            transform: translateY(-80px);
            &.hiding {
                transform: translateY(-80px);
            }
        }
    }

    .background {
        @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3, $row-span: 2);
        height: 156px;
        background: rgb(255, 205, 0);

        @include mobile {
            height: auto;
        }
    }

    .header {
        @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2);
        height: 96px;
        padding: 24px 0px 24px 0px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include mobile {
            height: 80px;
            padding: 16px 16px 16px 16px;
        }

        .tabs {
            display: none;

            @include mobile {
                display: block;
            }
        }

        .title {
            margin: 0px;
            @include design {
                color: #000000;
                font-family: "Bebas Neue";
                font-size: 40px;
                font-weight: 300;
                letter-spacing: 0.8px;
                line-height: 48px;
            }
            @include mobile-s {
                @include design {
                    font-size: 28px;
                }
            }
        }

        .filters {
            @include mobile {
                display: none;
            }
        }
    }

    .stats {
        @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 2, $row-span: 2);
        @include mobile {
            display: none;
        }
    }

    .mobileFilters {
        @include mobile {
            @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 2);
        }
    }
}

.mobileStats {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 3);
    @include not-mobile {
        display: none;
    }

    box-sizing: border-box;
    overflow-x: auto;
    margin: 0 -16px;
    padding: 16px;

    .inner {
        padding-right: 16px;
        min-width: 1024px;
    }
}

.body {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 4);
    display: flex;
    flex-direction: column;

    @include mobile {
        @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 4);
    }
}

.loader {
    @include in-grid($horizontal: center, $vertical: center, $col-start: 2, $row-start: 4);
    @include mobile {
        @include in-grid($horizontal: center, $vertical: center, $col-start: 2, $row-start: 4);
    }
}

.noContent {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 4);
    margin: 64px 0px 0px 0px;
    @include mobile {
        margin: 64px 0px 0px 0px;
    }
}
