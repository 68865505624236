@import "../Layout.scss";
@import "../Animation.scss";
.nav-bar {
    @include in-grid($horizontal: stretch, $vertical: center, $col-start: 2);
    @include grid($cols: 1fr auto 1fr, $rows: 1fr);
    height: 80px;
    position: relative;
    @include tablet {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: stretch;
        padding: 16px 16px 0px 16px;
        &.drawer-open {
            height: 100vh;
            padding: 16px 16px 44px 16px;
            @supports (-webkit-overflow-scrolling: touch) {
                padding: 16px 16px 128px 16px;
            }
        }
        box-sizing: border-box;
        background: rgb(255, 205, 0);
        @include default-transition(height, padding);
    }
}

.nav-user-dropdown-bar-fade {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: hsla(0, 0%, 40%, 0.75);
    z-index: 2;
}