@import "./views/dashboard/Layout.scss";
.App {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

body {
    background: rgb(250, 250, 250);
    @media only screen and (max-width: 600px) {
        &.no-scroll {
            overflow: hidden;
        }
    }
}

.login-redirecting {
    height: 100%;
    @include grid($cols: 1fr, $rows: 1fr);
    background: rgb(250, 250, 250);
}

.login-redirecting-hidden-form {
    @include in-grid();
    display: none;
}

.login-redirecting-hidden-invite-form {
    @include in-grid();
    display: none;
}

.login-redirecting-loader {
    @include in-grid();
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('./fonts/BebasNeue\ Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('./fonts/BebasNeue\ Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: bold;
}

@font-face {
    font-family: 'Bebas Neue Light';
    src: url('./fonts/BebasNeue\ Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('./fonts/BebasNeue\ Book.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: bold;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

img {
    &:not([src]) {
        //hide empty images
        visibility: hidden;
    }
}

button {
    //button clear
    padding: 0px;
    background: none;
    border: none;
    user-select: none;
    cursor: pointer;
    &:focus {
        outline: none;
    }
    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}

input {
    &:focus {
        outline: none;
    }
}

a {
    &:focus {
        outline: none;
    }
}

@include not-mobile {
    .mobile {
        display: none;
    }
}

@include mobile {
    .desktop {
        display: none;
    }
}

.font-weight-bold {
    font-weight: bold !important;
}
.font-weight-normal {
    font-weight: 400 !important;
}