@import "../../../Layout.scss";
.manage-header {
    @include grid($cols: 1fr, $rows: 1fr);
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2);
    height: 80px;
    padding: 12px 0px 20px 0px;
    box-sizing: border-box;
    @include mobile {
        @include grid($cols: 1fr, $rows: 1fr auto);
        padding: 16px;
    }
}

.manage-header_title {
    @include in-grid($horizontal: start);
    margin: 0px;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 40px;
        font-weight: 300;
        letter-spacing: 0.8px;
        line-height: 48px;
    }
}

.manage-header_tabs {
    @include in-grid();
    display: flex;
    justify-content: space-between;
    height: 42px;
    @include mobile {
        display: none;
    }
}