@import "../Layout.scss";
@import "../Animation.scss";
.black-checkbox {
    @include grid($cols: 1fr, $rows: 1fr);
    width: 32px;
    height: 32px;
    &.large {
        width: 44px;
        height: 44px;
        .black-checkbox_img {
            width: 28px;
            height: 28px;
        }
    }
    margin: 0px;
    background: white;
    border: 2px solid rgb(221, 221, 221);
    border-radius: 2px;
    box-sizing: border-box;
    @include default-transition();
    .black-checkbox_input {
        @include in-grid($horizontal: stretch, $vertical: stretch);
        width: 100%;
        height: 100%;
        margin: 0px;
        opacity: 0;
        &:checked {
            +.black-checkbox_img {
                padding: 3px;
                border: 2px solid #ffcd00;
                transform: scaleX(1);
            }
        }
    }
    .black-checkbox_img {
        @include in-grid();
        width: 20px;
        height: 20px;
        transform: scaleX(0);
        box-sizing: border-box;
        border-radius: 50%;
        overflow: hidden;
        user-select: none;
        pointer-events: none;
    }
    &.checked {
        background: black;
        border: 2px solid black;
    }
}