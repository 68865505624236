@import '../../../../Layout';
@import '../../../../Animation';

.sign-contract-modal{
  height: 100%;

  .sign-contract-modal_close-button {
    z-index: 2;
    margin-top: 64px;
    margin-right: 48px;
    padding: 14px;
    padding-bottom: 0;
    position: relative;

    @include mobile() {
      padding: 0;
      margin: 32px 32px 16px 32px;
    }
  }
  .select-contract-modal_close-button_img {
    width: 26px;
    height: 26px;
  }

  .co-content-container_content {
    position: relative;
    top: -108px;
    height: 100vh;
    @include mobile() {
      top: -78px;
    }
  }

  .sign-contract-modal-content-container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    top: 50%;
    position: relative;
    height: 416px;
    transform: translateY(-50%);

    @include mobile() {
      height: 488px;
    }

    .sign-contract-modal-content{
      position: relative;

      display: flex;
      flex-direction: column;

      .sign-contract-modal-top{
        @include in-grid($col-span: 2, $row-start: 2, $horizontal: stretch, $vertical: stretch);
        margin: 0 8px 0 8px;
        padding: 32px 16px 32px 16px;
        border-radius: 2px 2px 0px 0px;
        box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
        box-sizing: border-box;
        background: white;
        width: 410px;
        max-width: 410px;
        z-index: 2;

        @include mobile() {
          margin: 0 16px 0 16px;
          width: calc(100% - 32px);
        }

        h1 {
          margin: 0px;
          @include design {
            color: #000000;
            font-family: "Bebas Neue";
            font-size: 40px;
            font-weight: 300;
            letter-spacing: 0.8px;
            line-height: 48px;
            text-align: center;
          }
        }

        div {
          margin: 16px 0px 0px 0px;
          @include design {
            color: #484848;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 300;
            letter-spacing: 0.23px;
            line-height: 27px;
            text-align: center;
          }
        }


      }
      .sign-contract-modal-middle{
        z-index: 3;
        @include in-grid($col-span: 2, $row-start: 3, $horizontal: stretch, $vertical: stretch);
        padding: 16px 16px 16px 0px;
        box-sizing: border-box;
        display: flex;
        background: rgb(250, 250, 250);
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);

        @include mobile {
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 0px 16px 16px 16px;
          margin: 0 8px;
          width: calc(100% - 16px);
        }

        .signing-type-select {
          width: 120px;
          height: 44px;
          padding: 0px;
          margin: 0px 0px 0px 16px;
          border: 1px solid rgba(0, 0, 0, 0.05);
          user-select: none;
          cursor: pointer;
          box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
          &.active {
            &.id-card {
              background: rgb(45, 95, 155);
            }
            &.mobiil-id {
              background: rgb(45, 95, 155);
            }
            &.smart-id {
              background: rgb(0, 181, 172)
            }
          }
          @include mobile() {
            flex: 0 0 calc(100%);
            margin: 16px 0px 0px 0px;
          }
        }

        .signing-type-select_img {
          vertical-align: middle;
        }
      }
      .sign-contract-modal-bottom{
        z-index: 2;
        width: 410px;
        max-width: 410px;

        @include mobile() {
          margin: 0 16px 0 16px;
          width: calc(100% - 32px);
        }

        animation-name: fadeOpacityIn;
        animation-duration: 0.6s;
        transition: height 0.05s linear;
        position: relative;

        @include in-grid($col-span: 2, $row-start: 4, $horizontal: stretch, $vertical: stretch);
        @include grid($cols: 1fr, $rows: 1fr);
        margin: 0px 8px 0px 8px;
        padding: 32px 16px 32px 16px;
        box-sizing: border-box;
        background: white;
        border-radius: 0px 0px 2px 2px;
        box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);


        &.idcard {
          height: 108px;
        }
        &.mobiilid {
          height: 134px;
        }
        &.smartid {
          height: 134px;
          @include mobile {
            height: 204px;
          }
        }
        &.smartid2 {
          height: 232px;
        }

        &.idcard.show-error{
          height: 164px;
        }

        &.mobiilid.show-error,
        &.smartid.show-error{
          height: 190px;
        }

        &.smartid.show-error{
          @include mobile() {
            height: 260px;
          }
        }

        &.smartid2.show-error{
          height: 278px;
        }

        .show-error{
          overflow: hidden;
        }

        .signing-card-bot {
          position:absolute;
          top: 0;
          left: 0;
          right: 0;
        }

        .signing-bot-container {
          position:absolute;
          top: 0;
          left: 0;
          right: 0;
          padding: 32px 16px;
          .signing-card-bot{
            position: relative;
          }
          .sign-contract-modal-error{
            padding-top: 16px;
            color: red;
            overflow: hidden;
          }
        }

        .signing-card-bot_id-card {
          @include in-grid($vertical: end, $horizontal: stretch);
          display: flex;
          align-items: center;
          .signing-card-bot_id-card_title {
            width: calc(100% - 54px);
            margin: 0px;
            @include design {
              color: #484848;
              font-family: Roboto;
              font-size: 14px;
              font-weight: 300;
              letter-spacing: 0.2px;
              line-height: 27px;
            }
          }
          .signing-card-bot_id-card_signing-button {
            width: 44px;
            height: 44px;
            margin: 0px 0px 0px auto;
            background: rgb(30, 30, 30);
            border: none;
            user-select: none;
            cursor: pointer;
            border-radius: 2px;
          }
        }

        .signing-card-bot_mobiil-id {
          display: flex;
          align-items: flex-end;
          //padding: 32px 16px 32px 16px;
          padding: 0;
          >.phone-number {
            flex: 1 0 auto;
          }
          .signing-card-bot_mobiil-id_signing-button {
            width: 44px;
            height: 44px;
            margin: 0px 0px 0px 16px;
            background: rgb(30, 30, 30);
            border: none;
            user-select: none;
            cursor: pointer;
            border-radius: 2px;
            flex: 0 0 auto;
          }
        }

        .signing-card-bot_smart-id {
          display: flex;
          align-items: flex-end;
          flex-wrap: wrap;
          >.personal-code,
          >.id-code {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(50% - 22px - 8px - 8px);
            @include mobile {
              flex-basis: 100%;
            }
          }
          >.phone-number {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(50% - 22px - 8px - 8px);
            margin: 0px 0px 0px 16px;
            @include mobile {
              flex-basis: calc(100% - 44px - 16px);
              margin: 0px;
            }
          }
          .signing-card-bot_mobiil-id_signing-button {
            width: 44px;
            height: 44px;
            margin: 0px 0px 0px 16px;
            background: rgb(30, 30, 30);
            border: none;
            user-select: none;
            cursor: pointer;
            border-radius: 2px;
            flex: 0 0 auto;
          }
        }

        .signing-card-bot_smart-id-2 {
          display: flex;
          justify-content: center;
          align-items: center;
          //padding: 26px;
          .signing-card-bot_smart-id-2_timer {
            width: 130px;
            height: 130px;
          }

          .signing-card-bot_smart-id-2_timer_label {
            position: absolute;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            .signing-card-bot_smart-id-2_timer_title {
              margin: 0px;
              @include design {
                color: #909090;
                font-family: Roboto;
                font-size: 12px;
                font-weight: 300;
                letter-spacing: 0.17px;
                line-height: 18px;
                text-align: center;
              }
            }
            .signing-card-bot_smart-id-2_timer_code {
              margin: 0px;
              @include design {
                color: #000000;
                font-family: "Bebas Neue";
                font-size: 32px;
                font-weight: 300;
                letter-spacing: 0.64px;
                line-height: 38px;
                text-align: center;
              }
            }
          }
        }

        .signing-card-bot_password {
          display: flex;
          align-items: flex-end;
          flex-wrap: wrap;
          >.username {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(50% - 22px - 8px - 8px);
            @include mobile {
              flex-basis: 100%;
            }
          }
          >.password {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(50% - 22px - 8px - 8px);
            margin: 0px 0px 0px 16px;
            @include mobile {
              flex-basis: calc(100% - 44px - 16px);
              margin: 0px;
            }
          }
          .signing-card-bot_mobiil-id_signing-button {
            width: 44px;
            height: 44px;
            margin: 0px 0px 0px 16px;
            background: rgb(30, 30, 30);
            border: none;
            user-select: none;
            cursor: pointer;
            border-radius: 2px;
            flex: 0 0 auto;
          }
        }
      }
    }
  }
}


@keyframes fadeOpacityIn {
  from {opacity: 0;}
  to {opacity: 1;}
}


.modal-bottom-enter {
  opacity: 0.01;
  &.modal-bottom-enter-active {
    opacity: 1;
    @include default-transition();
  }
}

.modal-bottom-exit {
  opacity: 1;
  &.modal-bottom-exit-active {
    opacity: 0.01;
    @include default-transition();
  }
}
