@import "../Animation.scss";
@import "../Layout.scss";

  .tabs {
      display: flex;
      justify-content: space-between;
      height: 42px;

      &.desktop {
          @include in-grid();
          @include mobile {
              display: none;
          }
      }

      &.mobile {
          @include in-grid($horizontal: end);
          @include mobile {
              display: flex;
          }

          display: none;
          margin-right: 36px;

          &:last-child {
              margin-right: 0;
          }

          button {
              @include fast-transition();

              background: none;
              border-top: 2px solid white;
              border-bottom: 2px solid white;
              box-sizing: border-box;
              cursor: pointer;
              margin: 0 16px;
              padding: 9px 0;
              user-select: none;
              text-decoration: none;
              white-space: nowrap;

              @include design {
                  color: var(--color-black);
                  font-family: "Bebas Neue";
                  font-size: 1.25em;
                  font-weight: bold;
                  letter-spacing: 0.49px;
                  line-height: 20px;
              }

              &::after {
                  background-image: url(../assets/Icon_Chevron-Down_Black-16px.svg);
                  background-repeat: no-repeat;
                  background-size: 8px;
                  content: " ";
                  display: inline-block;
                  margin: 0 0 0 8px;
                  width: 8px;
                  height: 8px;
                  vertical-align: middle;
              }
          }
      }
  }

.dropdown {
    display: flex;
    flex-direction: column;

    .link {
        display: block;
        margin: 0;
        padding: 12px 32px 12px 32px;
        text-align: left;
        text-decoration: none;

        @include default-transition;
        @include design {
            color: var(--color-text-3);
            font-family: Roboto;
            font-size: 0.875em;
            letter-spacing: 0.2px;
            line-height: 16px;
        }

        &.active {
            @include design {
                color: rgb(255, 205, 0);
            }
        }

        &:hover {
            transform: translateX(4px);

            &:not(.active) {
                @include design {
                    color: white;
                }
            }
        }
    }
}
