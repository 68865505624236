@import "../../../Layout.scss";
.manage-address {
    @include grid($cols: 1fr auto, $rows: 1fr);
    margin: 0px 0px 0px 0px;
    background: white;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
    position: relative;
    @include mobile {
        @include grid($cols: 1fr, $rows: 1fr auto);
        margin: 0px 16px 0px 16px;
        box-shadow: none;
    }
}

.manage-address-address-container {
    @include in-grid($horizontal: stretch, $vertical: stretch);
}

.manage-address-content {
    @include grid($cols: 1fr, $rows: 1fr);
}

.manage-address-loader {
    @include in-grid();
    height: 100px;
}

.manage-address_label {
    @include in-grid($horizontal: start);
    margin: 32px 0px 0px 32px;
    @include mobile {
        margin: 0px;
    }
}

.manage-address_label-img {
    width: 38px;
    height: 38px;
    vertical-align: middle;
}

.manage-address_label-text {
    @include in-grid($horizontal: start, $col-start: 2);
    margin: 0px 0px 0px 12px;
    vertical-align: middle;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.5px;
        line-height: 24px;
    }
}

.manage-address_button-change {
    @include in-grid($horizontal: end, $col-start: 2);
    margin: 32px 0px 0px 0px;
    padding: 10px 32px 10px 32px;
    box-sizing: border-box;
    @include mobile {
        margin: 0px;
    }
}

.manage-address_text {
    @include in-grid($horizontal: start);
    margin: 24px 0px 0px 32px;
    @include mobile {
        margin: 24px 0px 0px 0px;
    }
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: 0.29px;
        line-height: 27px;
    }
}

.manage-address_text-top {
    margin: 0px;
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: 0.29px;
        line-height: 27px;
    }
}

.manage-address_text-bot {
    margin: 16px 0px 0px 0px;
    @include design {
        color: #484848;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.2px;
        line-height: 22px;
    }
}

.manage-address_map {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2);
    width: 480px;
    height: 267px;
    margin: 0px 0px 0px 32px;
    background: rgb(231, 231, 231);
    @include mobile {
        @include in-grid($horizontal: stretch, $vertical: stretch, $row-start: 2);
        width: auto;
        margin: 32px 0px 0px 0px;
    }
}

.address-marker {
    width: 28px;
    height: 32px;
    transform: translateY(-32px) translateX(-14px);
}