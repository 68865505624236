@import "../../../Layout.scss";
@import "../../../Animation.scss";
.id-credit_expandable-collection {
    &:first-child {
        margin: 0px 0px 48px 0px;
        &.collapsed {
            margin: 0px 0px 0px 0px;
        }
    }
    &:not(:first-child) {
        margin: 0px 0px 48px 0px;
        &.collapsed {
            margin: 0px 0px 0px 0px;
        }
    }
    @include mobile {
        margin: 48px 0px 0px 0px;
        &.no-invoices {
            display: none;
        }
        &.no-incomings {
            display: none;
        }
    }
}

.id-credit_expandable-collection_title {
    margin: 0px;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.59px;
        line-height: 29px;
    }
    @include mobile {
        @include design {
            color: #000000;
        }
    }
}

.id-credit_collections {
    @include in-grid($horizontal: stretch, $col-span: 2, $row-start: 2);
    display: flex;
    flex-wrap: wrap;
}

.id-credit-collection {
    flex: 1 1 480px;
    &:first-child {
        margin: 16px 32px 0px 0px;
        @include mobile {
            margin: 0px;
        }
    }
    &:last-child {
        margin: 16px 0px 0px 32px;
        @include mobile {
            margin: 0px;
        }
    }
}

.id-credit-collection_title {
    margin: 0px;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.49px;
        line-height: 24px;
    }
    @include mobile {
        display: none;
    }
}

.id-credit-collection_total {
    margin: 0px;
    @include design {
        color: #C1C1C1;
        font-family: "Bebas Neue";
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.49px;
        line-height: 24px;
    }
    @include mobile {
        display: none;
    }
}

.id-credit-collection-items {
    display: flex;
    flex-direction: column;
    margin: 16px 0px 0px 0px;
    @include mobile {
        margin: 0px;
    }
}

.bill-item-outer {
    @include grid($cols: 1fr, $rows: 1fr);
    &:not(:first-child) {
        margin: 8px 0px 0px 0px;
    }
    box-sizing: border-box;
    background: white;
}

.bill-item {
    @include in-grid($horizontal: stretch, $vertical: stretch);
    @include grid($cols: auto auto 1fr auto, $rows: auto 1fr auto auto);
    height: 165px;
    @include mobile {
        &.pay-button {
            height: 165px+56px;
        }
        @include grid($cols: auto auto 1fr auto, $rows: auto 1fr auto auto auto);
    }
    padding: 24px;
    box-sizing: border-box;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
    &.fade {
        box-shadow: none;
    }
    &:hover {
        .bill-item_hover {
            opacity: 1;
        }
    }
}

.bill-item-fade {
    @include in-grid($horizontal: stretch, $vertical: stretch);
    background-image: linear-gradient(to bottom, rgba(250, 250, 250, 0), rgba(250, 250, 250, 1) 50%);
}

.bill-item_img {
    @include in-grid($vertical: start, $row-span: 2);
    width: 56px;
    height: 56px;
}

.bill-item_type {
    @include in-grid($horizontal: start, $vertical: center, $col-start: 2, $col-span: 2);
    margin: 0px 0px 0px 16px;
}

.bill-item_type-text {
    vertical-align: middle;
    @include design {
        color: #909090;
        font-family: "Bebas Neue";
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.44px;
        line-height: 23px;
    }
}

.bill-item_download-button {
    margin: 0px 0px 0px 8px;
    vertical-align: middle;
    &:hover {
        .bill-item_download-button_img {
            opacity: 1;
        }
    }
}

.bill-item_download-button_img {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    @include fast-transition(opacity);
    opacity: 0.24;
}

.bill-item_title {
    @include in-grid($horizontal: start, $vertical: center, $col-start: 2, $col-span: 3, $row-start: 2);
    margin: 0px 0px 0px 16px;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.59px;
        line-height: 31px;
    }
    @include mobile {
        @include design {
            font-size: 20px;
            letter-spacing: 0.49px;
            line-height: 24px;
        }
    }
}

.bill-item_total-info {
    @include in-grid($horizontal: end, $col-start: 4);
    margin: 0px 0px 0px 0px;
    text-align: end;
    @include design {
        color: #909090;
        font-family: "Bebas Neue";
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.44px;
        line-height: 23px;
    }
}

.bill-item_total {
    @include in-grid($horizontal: end, $col-start: 4, $row-start: 2);
    margin: 0px;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.59px;
        line-height: 31px;
    }
    @include mobile {
        @include design {
            font-size: 20px;
            letter-spacing: 0.49px;
            line-height: 24px;
        }
    }
}

.bill-item_date-info {
    @include in-grid($horizontal: start, $vertical: end, $col-span: 2, $row-start: 3);
    min-width: 128px;
    margin: 18px 0px 0px 0px;
    @include mobile {
        min-width: 90px;
    }
    @include design {
        color: #C1C1C1;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.17px;
        line-height: 14px;
    }
}

.bill-item_date {
    @include in-grid($horizontal: start, $vertical: start, $col-span: 2, $row-start: 4);
    min-width: 128px;
    margin: 4px 0px 0px 0px;
    @include mobile {
        min-width: 90px;
        margin: 6px 0px 0px 0px;
    }
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.23px;
        line-height: 19px;
        @include mobile {
            font-size: 14px;
            letter-spacing: 0.2px;
            line-height: 16px;
        }
    }
}

.bill-item_payment-date-info {
    @include in-grid($horizontal: start, $vertical: end, $col-start: 3, $col-span: 1, $row-start: 3);
    min-width: 128px;
    margin: 18px 0px 0px 24px;
    @include mobile {
        min-width: 93px;
        margin: 18px 0px 0px 8px;
    }
    @include design {
        color: #C1C1C1;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.17px;
        line-height: 14px;
    }
}

.bill-item_payment-date {
    @include in-grid($horizontal: start, $vertical: start, $col-start: 3, $col-span: 1, $row-start: 4);
    min-width: 128px;
    margin: 4px 0px 0px 24px;
    @include mobile {
        min-width: 93px;
        margin: 6px 0px 0px 8px;
    }
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.23px;
        line-height: 19px;
        @include mobile {
            font-size: 14px;
            letter-spacing: 0.2px;
            line-height: 16px;
        }
    }
}

.bill-item_paid-info {
    @include in-grid($horizontal: end, $vertical: end, $col-start: 4, $col-span: 1, $row-start: 3);
    margin: 18px 0px 0px 24px;
    @include mobile {
        margin: 18px 0px 0px 8px;
    }
    @include design {
        color: #C1C1C1;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.17px;
        line-height: 14px;
    }
}

.bill-item_paid {
    @include in-grid($horizontal: end, $vertical: start, $col-start: 4, $col-span: 1, $row-start: 4);
    margin: 6px 0px 0px 24px;
    @include mobile {
        // min-width: 93px;
        margin: 4px 0px 0px 8px;
    }
    text-align: right;
    @include design {
        color: #C1C1C1;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.23px;
        line-height: 19px;
        @include mobile {
            font-size: 14px;
            letter-spacing: 0.2px;
            line-height: 16px;
        }
    }
}

.bill-item_hover {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 4, $row-start: 3, $row-span: 2);
    margin: 16px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    opacity: 0;
    @include fast-transition(opacity);
    @include mobile {
        opacity: 1;
        @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 4, $row-start: 5, $row-span: 2);
    }
}

.bill-item_pay-button {
    flex: 1 1 50%;
    margin: 0px 16px 0px 0px;
    padding: 8px 32px 8px 32px;
    background: rgb(100, 180, 41);
    border: none;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.3s;
    &:active {
        opacity: 0.4;
    }
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
        text-align: center;
    }
}

.bill-item_pay-info {
    flex: 1 1 50%;
    margin: 0px 0px 0px 16px;
    @include design {
        color: #909090;
        font-family: Roboto;
        font-size: 12px;
        letter-spacing: 0.17px;
        line-height: 14px;
    }
}

.received-item {
    @include grid($cols: auto 1fr auto, $rows: auto 1fr auto auto);
    height: 165px;
    &:not(:first-child) {
        margin: 8px 0px 0px 0px;
    }
    padding: 24px;
    box-sizing: border-box;
    background: white;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.05);
}

.received-item_img {
    @include in-grid($row-span: 2);
    width: 56px;
    height: 56px;
}

.received-item_type {
    @include in-grid($horizontal: start, $vertical: center, $col-start: 2);
    margin: 0px 0px 0px 16px;
    @include design {
        color: #909090;
        font-family: "Bebas Neue";
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.44px;
        line-height: 23px;
    }
}

.received-item_title {
    @include in-grid($horizontal: start, $vertical: center, $col-start: 2, $row-start: 2);
    margin: 0px 0px 0px 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 220px;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.59px;
        line-height: 31px;
        @include mobile {
            font-size: 20px;
            letter-spacing: 0.49px;
            line-height: 24px;
        }
    }
}

.received-item_sum-info {
    @include in-grid($horizontal: end, $col-start: 3);
    margin: 0px 0px 0px 0px;
    text-align: end;
    @include design {
        color: #909090;
        font-family: "Bebas Neue";
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.44px;
        line-height: 23px;
    }
}

.received-item_sum {
    @include in-grid($col-start: 3, $row-start: 2);
    margin: 0px;
    @include design {
        color: #64B429;
        font-family: "Bebas Neue";
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.59px;
        line-height: 31px;
        @include mobile {
            font-size: 20px;
            letter-spacing: 0.49px;
            line-height: 24px;
        }
    }
}

.received-item_date-info {
    @include in-grid($horizontal: start, $vertical: end, $col-span: 2, $row-start: 3);
    margin: 18px 0px 0px 0px;
    @include design {
        color: #C1C1C1;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.17px;
        line-height: 14px;
    }
}

.received-item_date {
    @include in-grid($horizontal: start, $vertical: start, $col-span: 2, $row-start: 4);
    margin: 0px;
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.23px;
        line-height: 19px;
        @include mobile {
            font-size: 14px;
            letter-spacing: 0.2px;
            line-height: 16px;
        }
    }
}

.desktop-text {
    display: initial;
    @include mobile {
        display: none;
    }
}

.mobile-text {
    display: none;
    @include mobile {
        display: initial;
    }
}

.id-credit-collection-bills {
    @include mobile {
        &.collection-hidden {
            display: none;
        }
    }
}

.id-credit-collection-invoices {
    @include mobile {
        &.collection-hidden {
            display: none;
        }
    }
}