@import "../Layout.scss";
@import "../Animation.scss";
.co-pagination {
    height: 64px;
    margin: 48px 0px 0px 0px;
    display: flex;
    align-self: center;
}

.co-pagination_button {
    width: 64px;
    height: 64px;
    margin: 0px;
    padding: 0px;
    background: none;
    border: none;
    user-select: none;
    cursor: pointer;
    transition: all 300ms $default-animation-curve;
    &.left {
        transform: scaleX(-1);
    }
    &.text {
        background: rgb(255, 255, 255);
        box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.05);
        &:first-of-type {
            border-radius: 2px 0px 2px 0px;
        }
        &:last-of-type {
            border-radius: 0px 2px 0px 2px;
        }
        &:not(:first-of-type) {
            margin: 0px 0px 0px 1px;
        }
        @include design {
            color: #909090;
            font-family: "Bebas Neue";
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 0.59px;
            line-height: 31px;
            text-align: center;
        }
        &:hover {
            color: #000000;
        }
        &.active {
            background: rgb(255, 205, 0);
            @include design {
                color: #000000;
                font-family: "Bebas Neue";
                font-size: 24px;
                font-weight: bold;
                letter-spacing: 0.59px;
                line-height: 31px;
                text-align: center;
            }
        }
    }
    @include mobile {
        width: 48px;
        height: 48px;
        &:nth-child(4),
        &:nth-child(5) {
            display: none;
        }
    }
}

.co-pagination_ellipsis {
    @include grid($cols: 1fr, $rows: 1fr);
    width: 64px;
    height: 64px;
    background: rgb(255, 255, 255);
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.05);
    @include mobile {
        width: 48px;
        height: 48px;
    }
}

.co-pagination_ellipsis-img {
    @include in-grid();
}

.co-pagination_ellipsis-form {
    @include in-grid();
}

.co-pagination_ellipsis-input {
    cursor: pointer;
    opacity: 0;
    &:focus {
        cursor: text;
        opacity: 1;
    }
    transition: all 300ms $default-animation-curve;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.59px;
        line-height: 31px;
        text-align: center;
    }
}