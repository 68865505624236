@import "../Layout.scss";

.stats {
    height: 110px;
    display: flex;
    justify-content: space-evenly;
    padding: 28px 32px;
    box-sizing: border-box;
    background: white;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.05);
    min-width: min-content;

    .separator {
        width: 2px;
        margin: 0 32px;
        align-self: stretch;
        background: rgba(0, 0, 0, 0.075);
        flex: 0 0 2px;
    }
}

.item {
    @include grid($cols: auto 1fr, $rows: auto 1fr);
    flex: 1 0 20%;

    .img {
        @include in-grid($row-span: 2);
        width: 32px;
        height: 32px;
    }

    .info {
        @include in-grid($horizontal: start, $col-start: 2);
        margin: 0 0 0 24px;
        white-space: nowrap;

        @include design {
            color: #909090;
            font-family: Roboto;
            font-size: 14px;
            letter-spacing: 0.2px;
            line-height: 16px;
        }
    }

    .title {
        @include in-grid($horizontal: start, $col-start: 2, $row-start: 2);
        margin: 10px 0 0 24px;
        white-space: nowrap;

        @include design {
            color: #000000;
            font-family: "Bebas Neue";
            font-size: 28px;
            letter-spacing: 0.68px;
            line-height: 31px;
        }
    }
}
