@import "../../../Layout.scss";
@import "../../../Animation.scss";
$user-card-height: 172px;
$subscriptions-card-height: 117px;
$subscriptions-card-height-ru: 196px;
$edit-profile-card-height: 216px;
$edit-contact-card-height: 216px;
$edit-sub-action-card-height: 228px;
$edit-sub-action-card-height-ru: 278px;
.user-details {
    margin: 0px 12px 0px 0px;
    flex: 1 0 480px;
    @include tablet {
        margin: 48px 16px 0px 16px;
        flex: 0 0 auto;
    }
}

.user-details_title {
    margin: 0px;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.59px;
        line-height: 20px;
    }
}

.user-details_edit-button {
    @include design {
        color: #C1C1C1;
        font-family: "Bebas Neue";
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.49px;
        line-height: 24px;
        text-align: right;
        &.editing {
            color: #F0B423;
        }
    }
    &:hover {
        color: black;
    }
}

.user-details-cards {
    @include grid($cols: 1fr, $rows: auto auto auto);
    @include default-transition();
}

.details-user-card {
    @include in-grid($horizontal: stretch, $vertical: stretch);
    @include grid($cols: auto 1fr, $rows: auto auto auto);
    height: $user-card-height;
    box-sizing: border-box;
    margin: 16px 0px 12px 0px;
    padding: 32px 32px 24px 32px;
    background-color: white;
    border-radius: 2px;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
    &.has-username {
        height: 195px;
        @include mobile-s {
            height: auto;
        }
    }
}

.card-user-image {
    @include in-grid($row-span: 2);
    width: 48px;
    height: 48px;
}

.card-user-name {
    @include in-grid($horizontal: start, $vertical: start, $col-start: 2);
    margin: 0px 0px 0px 20px;
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: 0.29px;
        line-height: 27px;
    }
}

.card-user-id {
    @include in-grid($horizontal: start, $vertical: end, $col-start: 2, $row-start: 2);
    margin: 0px 0px 0px 20px;
    @include design {
        color: #909090;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.2px;
        line-height: 22px;
    }
}

.card-field {
    margin: 0px;
    .card-field_img-left {
        max-width: 16px;
        max-height: 16px;
        vertical-align: middle;
    }
    .card-field_text {
        margin: 0px 0px 0px 12px;
        vertical-align: middle;
        @include design {
            color: #000000;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0.2px;
            line-height: 22px;
        }
    }
    .card-field_img-right {
        width: 13px;
        height: 13px;
        vertical-align: middle;
        margin: 0px 0px 0px 8px;
    }
}

.card-field {
    &.email {
        @include in-grid($horizontal: start, $col-span: 2, $row-start: 3);
        margin: 22px 0px 0px 0px;
    }
    &.phone {
        @include in-grid($horizontal: start, $col-span: 2, $row-start: 4);
        margin: 2px 0px 0px 0px;
    }
}

.details-subscriptions-card {
    @include in-grid($horizontal: stretch, $vertical: stretch, $row-start: 2);
    @include grid($cols: 1fr, $rows: auto auto auto);
    height: $subscriptions-card-height;
    &.ru {
        height: $subscriptions-card-height-ru;
    }
    box-sizing: border-box;
    margin: 0px;
    padding: 24px;
    border-radius: 2px;
    border: 1px solid #E7E7E7;
    box-sizing: border-box;
    @include mobile-s {
        padding: 16px;
    }
}

.card-field {
    &.newsletter {
        @include in-grid($horizontal: start);
    }
    &.offers-email {
        @include in-grid($horizontal: start, $row-start: 2);
        margin: 2px 0px 0px 0px;
    }
    &.offers-sms {
        @include in-grid($horizontal: start, $row-start: 3);
        margin: 2px 0px 0px 0px;
    }
}

.text-edit-field {
    display: flex;
    flex-direction: column;
    &.server-error-message {
        @include in-grid($col-span: 2, $row-start: 3);
        justify-self: flex-start;
    }
    .text-edit-field_title {
        @include design {
            color: #000000;
            font-family: Roboto;
            font-size: 14px;
            letter-spacing: 0.2px;
            line-height: 24px;
        }
    }
    .text-edit-field_input {
        margin: 4px 0px 0px 0px;
        padding: 8px 0px 8px 16px;
        background: rgb(248, 248, 248);
        border: 1px solid rgb(220, 220, 220);
        border-radius: 2px;
        @include design {
            color: #000000;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 300;
            letter-spacing: 0.23px;
            line-height: 24px;
            &:disabled {
                background: white;
                color: #909090;
            }
        }
        &.first-name {
            border-radius: 2px 0px 0px 2px;
            border-right: 0px;
        }
        &.last-name {
            border-radius: 0px 2px 2px 0px;
        }
    }
    &.invalid {
        .text-edit-field_input {
            border: 1px solid #F10000;
        }
    }
    .text-edit-field_error {
        color: #F10000;
        font-size: 12px;
        padding: 2px;
        margin-bottom: -19px;
    }
}

.details-edit-info-card {
    @include in-grid($horizontal: stretch, $vertical: stretch);
    @include grid($cols: 1fr 1fr, $rows: auto 1fr);
    height: $edit-profile-card-height;
    box-sizing: border-box;
    margin: 16px 0px 0px 0px;
    padding: 24px;
    background-color: white;
    border-radius: 2px;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
    @include mobile-s {
        padding: 16px;
    }
}

.text-edit-field {
    &.first-name {
        @include in-grid($vertical: start, $horizontal: stretch);
        .field-input {
            border-radius: 2px 0px 2px 0px;
        }
    }
    &.last-name {
        @include in-grid($vertical: start, $horizontal: stretch, $col-start: 2);
        .field-input {
            border-radius: 0px 2px 0px 2px;
        }
    }
    &.password1 {
        @include in-grid($vertical: start, $horizontal: stretch, $row-start: 2);
        margin: 24px 0px 0px 0px;
        .field-input {
            border-radius: 2px 0px 2px 0px;
        }
    }
    &.password2 {
        @include in-grid($vertical: start, $horizontal: stretch, $col-start: 2, $row-start: 2);
        margin: 24px 0px 0px 0px;
        .field-input {
            border-radius: 0px 2px 0px 2px;
        }
    }
    &.username {
        @include in-grid($vertical: start, $horizontal: stretch, $row-start: 1);
    }
    &.id-number {
        @include in-grid($vertical: start, $horizontal: stretch, $row-start: 2);
        margin: 24px 0px 0px 0px;
    }

}

.details-edit-contact-card {
    @include in-grid($horizontal: stretch, $vertical: stretch, $row-start: 2);
    @include grid($cols: 1fr 1fr, $rows: auto 1fr);
    height: $edit-contact-card-height;
    box-sizing: border-box;
    margin: 1px 0px 0px 0px;
    padding: 24px;
    background-color: white;
    border-radius: 2px;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
    @include mobile-s {
        padding: 16px;
    }
}
.details-edit-password-card {
    @include in-grid($horizontal: stretch, $vertical: stretch, $row-start: 3);
    @include grid($cols: 1fr 1fr, $rows: auto 1fr);
    height: $edit-contact-card-height;
    box-sizing: border-box;
    margin: 1px 0px 0px 0px;
    padding: 24px;
    background-color: white;
    border-radius: 2px;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
    @include mobile-s {
        padding: 16px;
    }
}

.text-edit-field {
    &.email {
        @include in-grid($vertical: start, $horizontal: stretch, $col-span: 2);
        .text-edit-field_input {
            padding: 8px 0px 8px 42px;
            background-image: url(../assets/Icon_Mail_Gray-16px.svg);
            background-size: 16px auto;
            background-position: center left 16px;
            background-repeat: no-repeat;
        }
    }
    &.username {
        @include in-grid($vertical: start, $horizontal: stretch, $col-span: 2);
        .text-edit-field_input {
            padding: 8px 0px 8px 42px;
            background-image: url(../assets/Icon_Username.svg);
            background-size: 16px auto;
            background-position: center left 16px;
            background-repeat: no-repeat;
        }
    }
    &.phone {
        @include in-grid($vertical: start, $horizontal: stretch, $row-start: 2);
        margin: 24px 0px 0px 0px;
        .text-edit-field_input {
            padding: 8px 0px 8px 42px;
            background-image: url(../assets/Icon_Phone_Gray-16px.svg);
            background-size: 16px auto;
            background-position: top 50% left 16px;
            background-repeat: no-repeat;
        }
        @include mobile {
            @include in-grid($vertical: start, $horizontal: stretch, $row-start: 2, $col-span: 2);
        }
    }
}

.text-edit-field_title {
    margin: 0px;
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 24px;
    }
}

.checkbox-field {
    display: flex;
    align-items: center;
    .checkbox-field_title {
        flex: 1 1 auto;
        margin: 0px 0px 0px 16px;
        @include design {
            color: #000000;
            font-family: Roboto;
            font-size: 14px;
            letter-spacing: 0.2px;
            line-height: 24px;
        }
    }
}

.green-checkbox {
    width: 32px;
    height: 32px;
    flex: 0 0 auto;
    .green-checkbox_input {
        position: absolute;
        width: 32px;
        height: 32px;
        margin: 0px;
        opacity: 0;
        &:checked {
            +.green-checkbox_img {
                background: rgb(100, 180, 41);
                border: 1px solid rgb(100, 180, 41);
                padding: 6px;
            }
        }
    }
    .green-checkbox_img {
        position: absolute;
        width: 32px;
        height: 32px;
        padding: 12px 0px;
        box-sizing: border-box;
        background: rgb(248, 248, 248);
        border: 1px solid rgb(220, 220, 200);
        border-radius: 2px;
        overflow: hidden;
        user-select: none;
        pointer-events: none;
    }
}

.details-edit-other-card {
    @include in-grid($horizontal: stretch, $vertical: stretch, $row-start: 3);
    &.has-username {
        @include in-grid($horizontal: stretch, $vertical: stretch, $row-start: 4);
    }
    @include grid($cols: 1fr 1fr, $rows: auto auto auto 1fr);
    height: $edit-sub-action-card-height;
    &.ru {
        height: $edit-sub-action-card-height-ru;
    }
    box-sizing: border-box;
    margin: 1px 0px 0px 0px;
    padding: 24px;
    background-color: white;
    border-radius: 2px;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
    @include mobile-s {
        padding: 16px;
    }
}

.checkbox-field {
    &.email-newsletter {
        @include in-grid($vertical: start, $horizontal: stretch, $col-span: 2);
    }
    &.email-offers {
        @include in-grid($vertical: start, $horizontal: stretch, $col-span: 2, $row-start: 2);
        margin: 8px 0px 0px 0px;
    }
    &.sms-offers {
        @include in-grid($vertical: start, $horizontal: stretch, $col-span: 2, $row-start: 3);
        margin: 8px 0px 0px 0px;
    }
}

.details-edit-other-card_cancel-button {
    @include in-grid($vertical: stretch, $horizontal: stretch, $row-start: 4);
    margin: 24px 12px 0px 0px;
    padding: 14px 0px 14px 0px;
    background: white;
    border: 1px solid rgb(231, 231, 231);
    border-radius: 2px;
    @include design {
        color: #1E1E1E;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
        text-align: center;
    }
}

.details-edit-other-card_save-button {
    @include in-grid($vertical: stretch, $horizontal: stretch, $col-start: 2, $row-start: 4);
    margin: 24px 0px 0px 12px;
    padding: 14px 0px 14px 0px;
    background: rgb(255, 205, 0);
    border: 1px solid transparent;
    border-radius: 2px;
    @include design {
        color: #1E1E1E;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
        text-align: center;
    }
}

.user-card-enter {
    opacity: 0.01;
    height: $edit-profile-card-height;
    &.user-card-enter-active {
        opacity: 1;
        height: $user-card-height;
        @include default-transition();
    }
}

.user-card-first-enter {
    .card-user-image,
    .card-user-name,
    .card-user-id,
    .card-field {
        opacity: 0.01;
    }
    .card-user-name,
    .card-user-id,
    .card-field {
        transform: translateX(16px);
    }
    &.user-card-first-enter-active {
        .card-user-image,
        .card-user-name,
        .card-user-id,
        .card-field {
            opacity: 1;
            @include default-transition();
        }
        .card-user-name,
        .card-user-id,
        .card-field {
            transform: translateX(0px);
            @include default-transition();
        }
    }
}

.user-card-exit {
    opacity: 1;
    &.user-card-exit-active {
        opacity: 0.01;
        @include default-transition(); // transition-property: opacity;
    }
}

.subscriptions-card-first-enter {
    opacity: 0;
    .card-field {
        opacity: 0;
        transform: translateX(16px);
    }
    &.subscriptions-card-first-enter-active {
        opacity: 1;
        @include default-transition();
        .card-field {
            opacity: 1;
            transform: translateX(0px);
            @include default-transition();
            @include mobile-s {
              white-space: nowrap;
            }
        }
    }
}

.subscriptions-card-enter {
    opacity: 0.01;
    height: $edit-contact-card-height;
    &.subscriptions-card-enter-active {
        opacity: 1;
        height: $subscriptions-card-height;
        &.ru {
            height: $subscriptions-card-height-ru;
        }
        @include default-transition();
    }
}

.subscriptions-card-exit {
    opacity: 1;
    &.subscriptions-card-exit-active {
        opacity: 0.01;
        @include default-transition();
    }
}

.edit-profile-card-enter {
    opacity: 0.01;
    height: $user-card-height;
    &.edit-profile-card-enter-active {
        opacity: 1;
        height: $edit-profile-card-height;
        @include default-transition();
    }
}

.edit-profile-card-exit {
    opacity: 1;
    height: $edit-profile-card-height;
    &.edit-profile-card-exit-active {
        opacity: 0.01;
        height: 0px;
        @include default-transition();
    }
}

.edit-contact-card-enter {
    opacity: 0.01;
    height: $subscriptions-card-height;
    &.ru {
        height: $subscriptions-card-height-ru;
    }
    &.edit-contact-card-enter-active {
        opacity: 1;
        height: $edit-contact-card-height;
        @include default-transition();
    }
}

.edit-contact-card-exit {
    opacity: 1;
    height: $edit-contact-card-height;
    &.edit-contact-card-exit-active {
        opacity: 0.01;
        height: 0px;
        @include default-transition();
    }
}

.edit-password-card-enter {
    opacity: 0.01;
    height: $subscriptions-card-height;
    &.ru {
        height: $subscriptions-card-height-ru;
    }
    &.edit-password-card-enter-active {
        opacity: 1;
        height: $edit-contact-card-height;
        @include default-transition();
    }
}
.edit-password-card-exit {
    opacity: 1;
    height: $edit-contact-card-height;
    &.edit-password-card-exit-active {
        opacity: 0.01;
        height: 0px;
        @include default-transition();
    }
}
.edit-subscriptions-card-enter {
    opacity: 0.01;
    height: 0px;
    &.edit-subscriptions-card-enter-active {
        opacity: 1;
        height: $edit-sub-action-card-height;
        &.ru {
            height: $edit-sub-action-card-height-ru;
        }
        @include default-transition();
    }
}

.edit-subscriptions-card-exit {
    opacity: 1;
    height: $edit-sub-action-card-height;
    &.ru {
        height: $edit-sub-action-card-height-ru;
    }
    &.edit-subscriptions-card-exit-active {
        opacity: 0.01;
        height: 0px;
        padding: 0px 24px 0px 24px;
        @include default-transition();
    }
}

.user-details-user-card-loader {
    @include in-grid();
}

.user-details-privacy-policy {
    margin: 8px 0px 0px 24px + 11px + 12px;
    @include mobile-s {
        margin: 8px 0px 0px 16px + 11px + 12px;
    }
    @include design {
        color: #909090;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.2px;
        line-height: 22px;
    }
}
