@import "../../../Layout.scss";
.vouchers_expandable-collection {
    &:first-child {
        margin: 48px 0px 48px 0px;
        &.collapsed {
            margin: 48px 0px 0px 0px;
        }
    }
    &:not(:first-child) {
        margin: 0px 0px 48px 0px;
        &.collapsed {
            margin: 0px 0px 0px 0px;
        }
    }
    @include mobile {
        margin: 48px 16px 0px 16px;
    }
}

.co-expandable-content_title {
    &:hover {
        .vouchers_expandable-collection_title {
            color: black;
        }
    }
}

.vouchers_expandable-collection_title {
    margin: 0px 0px 0px 0px;
    @include design {
        color: #909090;
        font-family: "Bebas Neue";
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.59px;
        line-height: 29px;
    }
}

.vouchers_collection {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 2, $row-start: 2);
    margin: 16px 0px 0px 0px;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    @include mobile {
        margin: 0px;
    }
}

.voucher-item {
    @include grid($cols: 128px auto 1fr auto, $rows: 1fr auto);
    margin: 12px 12px 0px 0px;
    @include mobile {
        @include grid($cols: 88px 1fr, $rows: 80px auto 1fr);
        width: auto;
        height: auto;
        margin: 16px 0px 0px 0px;
        flex: 0 0 auto;
    }
}

.voucher-item_background {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 4, $row-span: 3);
    background: white;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
}

.voucher-item_background-border {
    @include in-grid($horizontal: end, $vertical: stretch, $col-span: 4, $row-span: 3);
    border-right: 15px solid transparent;
    border-image: url(../assets/Sakk_Right.svg) 30 round;
    transform: translateX(15px);
    @include mobile {
        @include in-grid($horizontal: stretch, $vertical: end, $col-span: 4, $row-span: 3);
        border-right: none;
        border-bottom: 15px solid transparent;
        border-image: url(../assets/Sakk_Down.svg) 30 round;
        transform: translateY(15px);
    }
}

.voucher-item_img {
    @include in-grid($row-span: 2);
    width: 128px;
    height: 128px;
    pointer-events: none;
    @include mobile {
        @include in-grid();
        max-width: 80px;
        height: 80px;
        transform: translateX(-4px);
    }
}

.voucher-item_img-hover {
    @include in-grid($horizontal: stretch, $vertical: stretch, $row-span: 2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    background: rgba(255, 255, 255, 0.75);
    opacity: 0;
    transition: all 0.3s;
    &:hover {
        opacity: 1;
    }
    @include mobile {
        display: none;
    }
}

.voucher-item_img-hover_button {
    background: none;
    border: none;
    margin: 0px;
    padding: 0px;
    cursor: pointer;
    transition: all 0.3s;
    &:active {
        opacity: 0.4;
    }
}

.voucher-item_img-hover_button_img {
    width: 24px;
    height: 24px;
}

.voucher-item_img-hover_divider {
    width: 2px;
    height: 24px;
    background: rgba(0, 0, 0, 0.5);
}

.voucher-item_comment {
    @include in-grid($horizontal: start, $col-start: 2, $col-span: 2);
    margin: 24px 0px 0px 16px;
    @include mobile {
        @include in-grid($horizontal: start, $vertical: center, $col-start: 2);
        margin: 18px 0px 0px 14px;
        overflow: hidden;
        max-height: 58px;
    }
    @include mobile-s {
        white-space: nowrap;
    }
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.66px;
        line-height: 29px;
    }
}

.voucher-item_value {
    @include in-grid($horizontal: end, $vertical: center, $col-start: 4);
    min-width: 80px;
    margin: 24px 24px 0px 24px;
    padding: 6px;
    background: rgb(100, 180, 41);
    border-radius: 2px;
    box-sizing: border-box;
    @include mobile {
        @include in-grid($horizontal: start, $vertical: start, $col-start: 1, $row-start: 2);
        width: 88px;
        margin: 0px;
        padding: 8px 8px 2px 8px;
        transform: translateX(-3px);
    }
    @include design {
        color: #FFFFFF;
        font-family: "Bebas Neue";
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.55px;
        line-height: 24px;
        text-align: center;
    }
}

.voucher-item_info {
    padding: 14px 0px 0px 0px;
    box-sizing: border-box;
    border-top: 1px solid rgb(231, 231, 231);
    &.barcode {
        @include in-grid($horizontal: stretch, $col-start: 2, $row-start: 2);
        margin: 0px 0px 14px 16px;
        text-align: start;
        @include mobile {
            @include in-grid($horizontal: stretch, $col-start: 2, $row-start: 2);
            height: 34px;
            margin: 0px 16px 0px 14px;
            padding: 5px 0px 5px 0px;
            border-bottom: 1px solid rgb(231, 231, 231);
        }
        @include mobile-s {
            white-space: nowrap;
        }
    }
    &.expiry {
        @include in-grid($horizontal: stretch, $col-start: 3, $col-span: 2, $row-start: 2);
        margin: 0px 24px 14px 0;
        padding: 14px 0px 0px 48px;
        @include mobile {
            display: none;
        }
    }
}

.voucher-item_info_img {
    width: 16px;
    height: auto;
    vertical-align: middle;
}

.voucher-item_info_text {
    margin: 0px 0px 0px 8px;
    vertical-align: middle;
    @include design {
        color: #909090;
        font-family: Roboto;
        font-size: 12px;
        letter-spacing: 0.17px;
        line-height: 14px;
    }
}

.voucher-item_barcode {
    display: none;
    @include mobile {
        @include in-grid($col-span: 2, $row-start: 3);
        display: initial;
        margin: 28px 16px 28px 16px;
    }
}
