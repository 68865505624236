@import "../../../Layout.scss";
@import "../../../Animation.scss";
.transactions-header-switches {
    margin: 0px 0px 0px 0px;
    display: flex;
    align-items: center;
}

.transactions-header-switch {
    width: 24px;
    height: 24px;
    opacity: 0.24;
    @include fast-transition(opacity);
    &.table {
        margin: 0px 0px 0px 16px;
    }
    &.active {
        opacity: 1;
    }
    &:hover {
        opacity: 1;
    }
}