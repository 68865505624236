@import '../../Layout.scss';
@import '../../Animation.scss';

.electricity-page {
    @include in-grid($horizontal: stretch, $vertical: stretch, $row-start: 2);
    @include grid(
        $cols: minmax(16px, 1fr) auto minmax(16px, 1fr),
        $rows: auto auto auto 1fr
    );

    background: rgb(250, 250, 250);
    @include tablet {
        @include grid($cols: 16px 1fr 16px, $rows: auto 1fr);
    }
    @include mobile {
        @include grid($cols: 16px 1fr 16px, $rows: auto 1fr);
    }

    .electricity-header-shadow {
        @include in-grid(
            $horizontal: stretch,
            $vertical: stretch,
            $col-span: 3,
            $row-span: 2
        );
        background: rgb(255, 205, 0);
    }

    .packet-calculator-container{
        display: grid;
        grid-template-columns: minmax(16px, 1fr) minmax(992px, 1440px) minmax(16px, 1fr);
        grid-template-rows: 1fr;
        grid-column: 1/span 3;
        @include mobile() {
            grid-template-columns: auto;
        }
        @include tablet() {
            grid-template-columns: auto;
            .energy-consumption.energy-consumption-year,
            .energy-consumption.energy-consumption-day{
                flex: 1 1 100%;
            }
        }
        .energy-consumption-year{
            @include mobile() {
                padding-bottom: 64px;
            }
        }
    }

    .packet-calculator-background {
        @include in-grid(
            $horizontal: stretch,
            $vertical: stretch,
            $col-span: 3,
            $row-span: 1,
            $row-start: 3
        );
        background: #1e1e1e;
    }

    .electricity-header {
        @include in-grid(
            $horizontal: stretch,
            $vertical: stretch,
            $col-start: 2
        );
        padding: 24px 0px 24px 0px;
        @include mobile {
            padding: 0px;
        }
    }

    .electricity-header-title {
        margin: 0px;
        @include design {
            color: #000000;
            font-family: 'Bebas Neue';
            font-size: 40px;
            font-weight: 300;
            letter-spacing: 0.8px;
            line-height: 48px;
        }
    }
    .packet-selection {
        @include in-grid(
            $horizontal: stretch,
            $vertical: stretch,
            $row-start: 2,
            $col-start: 2
        );

        @include mobile() {
            display: block;
            padding: 0 0 32px 0;
        }
        padding-bottom: 48px;
        padding-top: 24px;
        display: flex;
        flex-wrap: nowrap;
        //align-items: flex-start;
        margin-left: -12px;
        margin-right: -12px;
        @include tablet {
            flex-wrap: wrap;
        }
        .step-container {
            padding-left: 12px;
            padding-right: 12px;
            flex: 1 1 20%;
            display: flex;
            @include tablet {
                flex: 1 1 100%;
            }
        }
        .step {
            flex: 1 1 auto;
            min-width: 250px;
            max-width: 276px;
            box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
            border-radius: 2px;
            //display: inline-block;
            display: flex;
            flex-direction: column;
            text-align: center;
            padding: 24px;
            padding-top: 0;
            &:last-child {
                margin-right: 0;
            }
            @include design {
                color: #000000;
                font-family: 'Bebas Neue';
                font-size: 16px;
                letter-spacing: 0.8px;
                line-height: 18px;
            }
            background: white;

            .step-mobile-header{
                display: flex;
                flex-direction: row;
                h2{
                    flex-grow: 1;
                    text-align: left;
                    padding: 22px 0 0 10px;
                    margin: 0;
                }
            }

            .step-number {
                background: #f0b423;
                color: black;
                border-radius: 50%;
                height: 64px;
                width: 64px;
                margin-left: auto;
                margin-right: auto;
                margin-top: -32px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 32px;
                font-weight: bold;

                @include mobile() {
                    margin-top: 16px;
                    margin-left: 0;
                    height: 32px;
                    width: 32px;
                    font-size: 20px;
                }
            }
            p {
                font-family: 'Roboto';
                font-size: 14px;
                font-weight: 300;
                flex-grow: 1;
                @include mobile() {
                    text-align: left;
                }
            }
            .buttons {
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                .btn {
                    &:last-child {
                        color: black;
                        background: white;
                        border: 1px solid #e7e7e7;
                    }
                }
            }
            .step-done {
                padding-left: 36px;
                position: relative;
                font-family: 'Roboto';
                font-size: 14px;

                display: inline-block;
                height: 42px;
                line-height: 42px;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 6px;
                    background: url('../overview/assets/Icon_Check_Green-9px.svg')
                        no-repeat center;
                    background-size: 16px;
                    background-position-y: 51%;
                    width: 24px;
                    height: 24px;
                    display: inline-block;
                    border-radius: 50%;
                    border: 2px solid #64b429;
                }
            }
            .step-todo {
                font-family: 'Roboto';
                font-size: 14px;
                color: #909090;
                height: 42px;
                display: block;
                line-height: 42px;
            }
            &.active {
                .step-number {
                    background: #1e1e1e;
                    color: #fcd000;
                }
            }

            .selected-title-container{
                display: flex;
                height: 42px;
                padding: 0 14px;
                margin: auto 0;
                position: relative;

                .selected-reset,
                .step-done {
                    flex-grow: 1;
                }
                .step-done{
                    padding: 0 0 0 24px;
                    &::before {
                        left: initial;
                        right: 0;
                    }
                }
                .selected-reset{
                    display: flex;
                    cursor: pointer;
                    svg{
                        margin: auto 0;
                        #Dashboard{
                            transition: stroke $default-animation-speed $default-animation-curve;
                            stroke: #C1C1C1;
                        }
                    }
                }
                .selected-reset:hover{
                    svg{
                        #Dashboard{
                            stroke: black;
                        }
                    }
                }

                .selected-title{
                    //flex-grow: 1;
                    margin: auto 0;
                    //padding-left: 36px;
                    //padding-right: 14px;
                    padding: 0 14px;
                    position: relative;
                    font-family: 'Roboto';
                    font-size: 14px;
                    display: inline-block;
                    height: 42px;
                    line-height: 42px;
                    cursor: pointer;
                }
            }

            @include tablet {
                width: auto;
                max-width: 100%;
                margin-bottom: 48px;
                .buttons {
                    justify-content: center;
                    .btn:last-child {
                        margin-left: 12px;
                    }
                }
            }
        }

        .current-step {
            @include mobile() {
                margin: 16px 0 8px 0;
            }
        }
        .mobile-hidden-step{
            @include mobile() {
                background-color: transparent;
                box-shadow: none;
                margin: 0;
                padding: 0 24px;
                p,
                button,
                .step-done,
                .step-todo{
                    display: none;
                }
            }
        }
    }
    .packet-calculator {
        @include in-grid(
            $horizontal: stretch,
            $vertical: stretch,
            $row-start: 3,
            $col-start: 2,
            $col-span: 1
        );
        padding-top: 16px;
        padding-bottom: 64px;
        //padding-left: 64px;
        //padding-right: 64px;
        padding-left: 0;
        padding-right:0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;
        @include mobile {
            padding: 0 16px;
        }
        .energy-consumption {
            display: inline-block;
            text-align: center;
            //padding-left: 12px;
            //padding-right: 12px;
            padding-left: 22px;
            padding-right: 22px;
            padding-top: 48px;
            flex: 1 1 28%;
            min-width: 260px;

            .amount {
                @include design {
                    color: #fcd000;
                    font-family: 'Bebas Neue';
                    font-weight: 300;
                    font-size: 68px;
                }
                padding-left: 12px;
                padding-right: 12px;
            }
            .unit-container {
                display: inline-block;
                max-width: 100px;
                @include design {
                    color: white;
                    font-weight: 300;
                    font-size: 20px;
                    line-height: 20px;
                }
                text-align: left;
                &.text-right {
                    text-align: right;
                }
                .unit {
                    display: block;
                }
            }
            .divider {
                display: inline-block;
                color: #484848;
                font-size: 100px;
                font-weight: 100;
                line-height: 1;
                padding-bottom: 12px;
            }
            .slider {
                margin-top: 24px;
                width: 100%;
                height: 2px;
                background: #fcd000;
                position: relative;
                .slider-handle {
                    position: absolute;
                    //left: 35%;
                    margin-left: 4px;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    display: block;
                    height: 10px;
                    width: 10px;
                    border-radius: 50%;
                    background: #1e1e1e;
                    border: 2px solid #fcd000;
                    cursor: pointer;
                    transition: height $default-animation-speed $default-animation-curve, width $default-animation-speed $default-animation-curve;
                    &:focus {
                        outline: none;
                    }
                    &::before{
                      position: absolute;
                      content: '';
                      width: 44px;
                      height: 44px;
                      display: block;
                      //border: 2px solid #fcd000; // debug only
                      left: 50%;
                      top: 50%;
                      transform: translate(-50%, -50%);
                    }
                    &:hover,
                    &.active{
                        height: 18px;
                        width: 18px;
                    }
                }
                .track-1 {
                    height: 2px;
                    background: #484848;
                }
            }
        }

        .energy-consumption-day{
            .slider-result-container{
                display: block;
                width: 100%;
                white-space: nowrap;
                .unit-amount-container{
                    display: inline-block;
                    width: calc(50% - 14px);
                    white-space: nowrap;
                }
                .unit-amount-container.text-right{
                    text-align: right;
                    margin-right: -2px;
                }
                .unit-amount-container.text-left{
                    text-align: left;
                    margin-left: -2px;
                }
                .divider{
                    width: 28px;
                }
            }

        }
    }

    .packet-options-container{
        position: relative;
        @include in-grid(
                $horizontal: stretch,
                $vertical: stretch,
                $row-start: 4,
                $col-start: 2,
                $col-span: 1
        );

        @include mobile() {
            padding-top: 36px;
            margin: 0 -16px;
            //overflow-x: scroll;
            overflow: hidden;
            position: inherit;
        }
    }

    .packet-options {
        @include in-grid(
            $horizontal: stretch,
            $vertical: stretch,
            $row-start: 4,
            $col-start: 2,
            $col-span: 1
        );
        display: flex;
        flex-wrap: wrap;

        @include mobile {
            width: 250vw;
            flex-wrap: nowrap;
            padding: 0 32px 34px 32px;
        }

        .packet {
            flex: 1 1 20%;
            min-height: 200px;
            position: relative;
            padding: 48px 24px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 0;

            transition: background-color $default-animation-speed $default-animation-curve, $default-animation-speed $default-animation-curve;

            &::before {
                //transition: opacity $default-animation-speed $default-animation-curve,
                //            height $fast-animation-speed $default-animation-curve,
                //            width $fast-animation-speed $default-animation-curve;
                transition: height $default-animation-speed $default-animation-curve,
                            width $default-animation-speed $default-animation-curve;
                //opacity: 0;
                content: '';
                background: url('./../../assets/Star_Complete_SVG.svg') no-repeat
                center;
                background-size: contain;
                width: 0px;
                height: 0px;
                display: block;
                position: absolute;
                transform: translate(-50%, -50%);
                top: 0;
                left: 50%;
            }

            @include tablet {
                flex: 1 1 100%;
            }
            @include mobile {
                flex: 1 1 100%;
                margin: 0 8px;
                background: white;
                box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.05);
                padding: 32px 8px;
            }
            h3 {
                font-family: 'Bebas Neue';
                font-size: 24px;
                letter-spacing: 0.6px;
            }
            &.packet-recommended {
                &::before {
                    opacity: 1;
                    width: 48px;
                    height: 48px;
                }
            }

            .packet-prices {
                display: flex;
                text-align: left;
                .divider {
                    display: inline-block;
                    color: #909090;
                    font-size: 48px;
                    font-weight: 100;
                    line-height: 1;
                    margin-top: -6px;
                    padding-left: 6px;
                    padding-right: 6px;
                    opacity: 0.5;
                }
                .packet-price-container {
                    .price-value {
                        font-family: 'Bebas Neue';
                        font-size: 24px;
                        font-weight: bold;
                    }
                    .price-unit {
                        padding-left: 6px;
                        font-size: 12px;
                        color: #909090;
                        font-family: 'Roboto';
                        font-weight: 500;
                    }
                    .description {
                        display: block;
                        font-size: 12px;
                        color: #909090;
                        font-family: 'Roboto';
                        font-weight: 300;
                        padding-top: 2px;
                    }
                    .divider {
                        font-size: 28px;
                        font-weight: 200;
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }
            }
            .packet-title-mobile{
                display: flex;
                flex-direction: row;
                width: 100%;

                .chevron-icon-container {
                    margin-top: 16px;
                    width: 44px;
                    height: 44px;
                    cursor: pointer;
                }

                .chevron-icon-placeholder {
                    margin-top: 16px;
                    width: 44px;
                    height: 44px;
                    opacity: 0;
                }

                .chevron-left-icon,
                .chevron-right-icon{
                    margin-top: 17px;
                    background: url("../../assets/Icon_Chevron-Down_Gray-16px.svg") center;
                    width: 18px;
                    height: 11px;
                    opacity: 0.3;
                }
                .chevron-left-icon{
                    transform: rotate(90deg) scaleY(0.6);
                    margin-righ: auto;

                }
                .chevron-right-icon{
                    transform: rotate(270deg) scaleY(0.6);
                    margin-left: auto;
                }
                .chevron-icon-container:hover {
                    .chevron-left-icon,
                    .chevron-right-icon{
                        opacity: 1;
                    }
                }
                .packet-title{
                    flex-grow: 1;
                }
            }
        }
        .btn {
            width: 180px;
        }
        .price-options {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            span {
                display: block;
                font-family: 'Roboto';
                color: #1e1e1e;
                font-size: 14px;
                line-height: 22px;
                padding-bottom: 16px;
                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
    }

    .packet-options-bg-transition {
        position: absolute;
        width: 0;
        opacity: 1;
        height: 100%;
        left: 0;
        background-color: white;
        box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.05);
        @include mobile(){
            display: none;
        }
        &.loc-1-1,
        &.loc-2-2,
        &.loc-3-3{
            width: calc(100% / 3);
        }
        &.loc-1-2,
        &.loc-2-3{
            width: calc(200% / 3);
        }
        &.loc-1-3{
            width: 100%;
        }
        &.loc-2-2,
        &.loc-2-3{
            left: calc(100% / 3);
        }
        &.loc-3-3{
            left: calc(200% / 3);
        }
        &.bg-fadeout{
            opacity: 0;
        }
        &.bg-fadeout.prev-loc-3-3.loc-1-3.prev-loc-changed{
            opacity: 0;
            animation: from-33-to-11 0.7s $default-animation-curve;
        }
        &.bg-fadeout.prev-loc-3-3.loc-2-3.prev-loc-changed{
            opacity: 1;
            animation: from-33-to-23 0.7s $default-animation-curve;
        }
        &.bg-fadeout.prev-loc-3-3.loc-3-3{
            opacity: 1;
        }
        transition: all $default-animation-speed $default-animation-curve, opacity #{$default-animation-speed * 2} $default-animation-curve;
    }

    .chevron-bottom {
        padding: 24px 12px;

        @include mobile() {
            padding: 16px 12px;
        }
    }
    .chevron-bottom::before {
        border-style: solid;
        border-width: 1px 1px 0 0;
        content: '';
        display: inline-block;
        height: 15px;
        left: 0.15em;
        position: relative;
        top: 0.15em;
        transform: rotate(155deg) skewX(40deg);
        vertical-align: top;
        width: 21px;
        color: #909090;
        @include mobile() {
            display: none;
        }
    }
    .chevrons {
        margin: auto 0;
        padding-bottom: 32px;
        @include tablet {
            display: none;
        }
    }
    .chevron-right {
        padding: 20px 0;
        position: relative;
        &:first-child {
            padding-top: 24px;
        }
    }
    .chevron-right::before {
        position: absolute;
        border-style: solid;
        border-width: 1.5px 1.5px 0 0;
        content: '';
        display: inline-block;
        height: 6px;
        left: -6px;
        bottom: 0;
        transform: rotate(64deg) skewX(40deg);
        vertical-align: top;
        width: 9px;
        color: #e7a300;
    }
    &-inline{
        grid-column: 1/span 3;
        position: relative;
        grid-template-columns: inherit;

        @include tablet {
            @include grid($cols: 16px 1fr 16px, $rows: auto 1fr);
        }
        @include mobile {
            @include grid($cols: 16px 1fr 16px, $rows: auto 1fr);
        }

        .electricity-header-shadow {
            grid-row: 1/span 3;
        }
        .packet-selection{
            padding-top: 48px;
            grid-row: 1/span 1;
        }
    }
}
.btn {
    background: #64b429;
    color: white;
    padding: 14px 28px;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
    font-size: 14px;
    line-height: 1;
    font-family: 'Roboto';
    white-space: nowrap;
    &.btn-block {
        display: block;
        width: 100%;
    }
    &.btn-white {
        color: black;
        background: white;
        border: 1px solid #e7e7e7;
    }
    &.btn-narrow {
        display: block;
        width: 100%;
        height: 32px;
        padding: 0px 32px;
        border-radius: 2px;
    }
    &.btn-chevron-down {
        &::before,
        &::after {
            content: '';
            background: url('../../assets/Icon_Chevron-Down_White.svg') center
                no-repeat;
            background-size: contain;
            width: 9px;
            height: 9px;
            margin-right: 24px;
            display: inline-block;
        }
        &::after {
            margin-right: 0;
            margin-left: 24px;
        }
    }
}

.clickable-modal-background{
    position: absolute;
    width: 100%;
    height: 100%;
}



@keyframes from-33-to-11 {
    0% { opacity: 1; width: calc(100% / 3); left: calc(200% / 3)}
    88%   { opacity: 1; width: 50%; left: 50%}
    100%   { opacity: 0; width: 50%; left: 50%}
}

@keyframes from-33-to-23 {
    0% { opacity: 1; width: calc(100% / 3); left: calc(200% / 3)}
    72%   { opacity: 1; width: calc(100% - (250% /3)); left: calc(250% /3)}
    100%   { opacity: 0; width: calc(100% - (250% /3)); left: calc(250% /3)}
}

.electricity-page-full{
    @include tablet() {
        @include not-mobile() {
            .packet-options-container{
                padding-top: 36px;
                margin: 0 -16px;
                overflow: hidden;
                position: inherit;
                display: flex;
                .packet-options{
                    flex-wrap: nowrap;
                    padding: 0 32px 34px 32px;
                    .packet-options-bg-transition{
                        display: none;
                    }
                    .packet{
                        flex-grow: 0;
                        max-width: 50vw;
                        min-width: 512px;
                        margin: 0 5vw;
                        background: white;
                        box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.05);
                        padding: 32px 8px;
                    }
                    .packet:nth-child(3) {
                        margin-left: 20vw;
                    }
                    .packet:nth-child(5) {
                        margin-right: 20vw;
                    }
                }
            }
        }

    }
}

.electricity-page-inline{
    @include tablet() {
        .packet-options-container{
            .packet-options{
                .packet{
                    flex: 1 1 20%;
                }
            }
        }
        .packet-selection{
            flex-wrap: nowrap;
            .step-container{
                flex: 1 1 auto;
                .step{

                }
            }
        }
        .packet-calculator-container{
            .packet-calculator{
                .energy-consumption.energy-consumption-day,
                .energy-consumption.energy-consumption-year{
                    flex: 1 1 28%;
                }
            }
        }
    ;
    }
}
