@import "../../../Layout.scss";
@import "../../../Animation.scss";
.invoicing-transactions {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3, $row-start: 2);
    @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: auto 1fr);
    background: rgb(250, 250, 250);
    @include mobile {
        @include grid($cols: auto 1fr auto, $rows: auto 1fr);
    }
    .table-header-row {
        &.fixed {
            z-index: 0;
            position: fixed;
            top: 152px;
            @include mobile {
                top: 132px;
                width: 1024px;
            }
            &.background {
                left: 0px;
                right: 0px;
            }
            &.animated {
                @include fast-transition(transform, opacity);
            }
            &.alt {
                transform: translateY(-72px);
                @include mobile {
                    transform: translateY(-52px);
                }
            }
        }
    }
}

.invoicing-transactions-header-placeholder {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3);
    height: 72px;
    background: white;
    @include mobile {
        height: 52px;
    }
}

.invoicing-transactions-header-static {
    @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: 1fr);
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3);
    z-index: 1;
    @include mobile {
        @include grid($cols: auto 1fr auto, $rows: 1fr);
    }
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.05);
    &.fixed {
        position: fixed;
        position: sticky; //override with sticky on browsers which support it
        left: 0px;
        right: 0px;
        top: 80px;
        &.animated {
            @include fast-transition(transform);
        }
        &.hiding {
            transform: translateY(-100%);
        }
    }
}

.invoicing-transactions-header-background {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3);
    background: white;
}

.invoicing-transactions-header {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2);
    height: 72px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 0;
    @include mobile {
        height: auto;
    }
}

.invoicing-transactions-header-items {
    display: flex;
    align-items: center;
    &.left {
        justify-content: flex-start;
    }
    &.right {
        justify-content: flex-end;
    }
    @include mobile {
        display: none;
    }
}

.invoicing-transactions-header_separator-right {
    margin: 0px 24px 0px 24px;
}

.invoicing-transactions-header-switches {
    margin: 0px 0px 0px 0px;
    display: flex;
    align-items: center;
}

.invoicing-transactions-header-switch {
    width: 24px;
    height: 24px;
    margin: 0px;
    padding: 0px;
    background: none;
    border: none;
    user-select: none;
    cursor: pointer;
    &.list {
        margin: 0px 0px 0px 32px;
    }
    &.table {
        margin: 0px 0px 0px 8px;
    }
}

.invoicing-transactions-header-mobile {
    @include mobile {
        box-shadow: none;
        width: 100%;
    }
}

.invoicing-transactions-body-background {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3, $row-start: 2);
    display: flex;
    flex-direction: column;
}

.invoicing-transactions-body {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 2);
    display: flex;
    flex-direction: column;
}

.invoicing-transactions-pagination {
    margin-bottom: 48px;
    @include mobile {
        margin-bottom: 24px;
    }
}

.invoicing-transactions-loader {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 2);
}