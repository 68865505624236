@import "../Layout.scss";
@import "../Animation.scss";
.co-dropdown-filter-tippy-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.co-btn-dropdown {
    margin: 0px;
    padding: 26px 0px 26px 0px;
    position: relative;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    &.tippy {
        padding: 26px 32px 26px 32px;
    }
}

.co-btn-dropdown_img-left {
    width: 20px;
    height: 20px;
    vertical-align: middle;
}

.co-btn-dropdown_text {
    margin: 0px 0px 0px 12px;
    vertical-align: middle;
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
    }
    &.tippy {
        @include design {
            color: #FFFFFF;
            font-family: Roboto;
            font-size: 14px;
            letter-spacing: 0.2px;
            line-height: 16px;
        }
    }
}

.co-btn-dropdown_img-right {
    width: 6px;
    height: 6px;
    margin: 0px 0px 0px 8px;
    vertical-align: middle;
    @include mobile() {
        &::before{
            display:none;
        }
    }
}

.co-dropdown-filter_dropdown {
    padding: 0px 0px 24px 0px;
    display: flex;
    flex-direction: column;
    max-height: 50vh;
    &.with-scroll {
        overflow-y: scroll;
        overflow-x: hidden;
        width: 100%;
        scrollbar-color: #FFCD00 black;
        &::-webkit-scrollbar {
            background: black;
        }
        &::-webkit-scrollbar-thumb {
            background: #FFCD00;
            border-radius: 16px;
            border: 5px solid black;
        }
        &::-webkit-scrollbar-track {
            border-radius: 90px;
        }
    }
}

.co-dropdown-filter_dropdown_option-container {
    text-align: left;
}

.co-dropdown-filter_dropdown_option-button {
    margin: 0px;
    padding: 12px 32px 12px 32px;
    background: none;
    border: none;
    user-select: none;
    cursor: pointer;
    text-align: left;
    @include default-transition;
    @include design {
        color: #909090;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
    }
    &.active {
        @include design {
            color: rgb(255, 205, 0);
        }
    }
    &:hover {
        transform: translateX(4px);
        &:not(.active) {
            @include design {
                color: white;
            }
        }
    }
}