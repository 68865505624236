@import "../../../Layout.scss";
@import "../../../Animation.scss";
.game-progress-container {
    @include in-grid($horizontal: stretch, $vertical: stretch);
    @include grid($cols: auto 1fr, $rows: auto auto auto auto);
    max-height: 500px;
    box-sizing: border-box;
    margin: 16px 0px 24px 0px;
    padding: 32px 32px 24px 32px;
    background-color: white;
    border-radius: 2px;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
    position: relative;
    flex: 1 0 480px;
    &::after {
      content: '';
      position: absolute;
      bottom: 24px;
      left: 0;
      right: 20px;
      height: 60px;
      background: linear-gradient(rgba(255, 255, 255, 0.1), #fff);
    }    
    .game-progress-scrollable {
      margin-right: -20px;
      padding-right: 24px;
      overflow: auto;
      overflow-x: hidden;
      max-height: 400px;
      padding-bottom: 40px;
    }
}
.game-progress-scrollable::-webkit-scrollbar-track
    {
      border-radius: 8px;
      background-color: #ddd;
    }

.game-progress-scrollable::-webkit-scrollbar
    {
      width: 8px;
      background-color: white;
    }

.game-progress-scrollable::-webkit-scrollbar-thumb
    {
      border-radius: 8px;
      background-color: #ffcd00;
    }
.game-progress-header-text {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 6px;
}
.game-progress-instructions-text {
  font-size: 14px;
}
.game-progress-item {
  margin-top: 6px;
  hr {
    border: none;
    border-top: 2px solid #eee;
    margin-top: 12px;
  }
}
.ticket-header {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  margin-top: 16px;
}
.ticket-stamps-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -4px;
  margin-right: -8px;
}

.single-stamp {
  padding: 4px;
  .single-stamp-img {
    max-width: 75px;
  }
  outline: none;
}
.stamp-tooltip {
  text-align: left;
  p {
    margin: 6px;
  }
}