@import "../../Animation.scss";
@import "../../Layout.scss";

.page {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 2);
    padding: 40px 0px 40px 0px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    @include mobile {
        background: #FAFAFA;
        padding: 0 0 32px 0;
    }
}

.calculatorContainer {
    background: white;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
    margin-bottom: 40px;
    position: relative;

    @include mobile {
        margin: 0;
        box-shadow: none;
    }
}

.calculatorContent {
    display: flex;
    gap: 12px;

    @include mobile {
        flex-direction: column;
    }

    h2 {
        color: #000;
        font-size: 1.5em;
        font-family: Bebas Neue;
        font-weight: 700;
        letter-spacing: 0.587px;
        text-transform: uppercase;
    }

    .controlsSide {
        padding: 24px 38px 32px 24px;
        width: 713px;

        @include mobile {
            padding: (24px + 16px) 16px 16px 16px;
            width: auto;
        }

        .section {
            padding-bottom: 32px;

            @include mobile {
                padding-bottom: 24px;
            }

            &:last-child {
                padding-bottom: 0;
            }

            h2 {
                margin: 0 0 16px 0;

                @include mobile {
                    margin: 0 0 24px 0;
                }
            }
        }
    }

    .resultsSide {
        display: flex;
        flex-direction: column;
        width: 299px;

        @include mobile {
            width: auto;
        }

        .balancePane {
            align-items: center;
            display: flex;
            justify-content: space-between;
            padding: 24px;

            @include design {
                background: var(--color-black);
                border-top-left-radius: 2px;
                border-top-right-radius: 2px;
            }

            @include mobile {
                border-radius: 0;
            }

            h2 {
                color: var(--color-bg);
                margin: 0;
            }

            .value {
                color: #fff;
                font-family: Bebas Neue;
                font-size: 1.5em;
                font-weight: 300;
                letter-spacing: 0.684px;
            }

            & ~ .pricePane {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }

        .pricePane {
            box-sizing: border-box;
            display: flex;
            flex: 1 1 0px;
            flex-direction: column;
            gap: 24px;
            justify-content: center;
            padding: 48px 24px 24px 24px;

            @include design {
                background: var(--color-black);
                border-radius: 2px;
            }

            @include mobile {
                width: auto;
            }

            h2 {
                color: var(--color-bg);
            }

            .price {
                text-align: center;

                .value {
                    color: var(--color-main);
                    font-size: 2.5em;
                    font-family: Bebas Neue;
                    font-weight: 400;
                    letter-spacing: 0.8px;
                }

                .note {
                    color: var(--color-bg);
                    font-size: 0.75em;
                    font-family: Roboto;
                    font-weight: 400;
                    letter-spacing: 0.171px;
                }
            }

            .explain {
                color: var(--color-bg);
                font-size: 0.875em;
                font-family: Roboto;
                font-style: normal;
                font-weight: 300;
                line-height: 18px;
                letter-spacing: 0.2px;
            }
        }

        .offerPane {
            display: flex;
            flex: 1 1 0px;
            flex-direction: column;
            gap: 8px;
            padding: 48px 24px 24px 24px;

            @include design {
                border-radius: 2px;
                background: va(--color-bg);
            }

            h2 {
                color: var(--color-text-1);
                margin: 0 0 (32px - 8px) 0;
            }
        }
    }
}

.radioRow {
    box-sizing: border-box;
    display: flex;
    margin-bottom: 12px;
    overflow: auto;
    row-gap: 16px;

    @include mobile {
        flex-direction: column;
        margin: 0 -16px 12px -16px;
        padding: 0 16px;
        max-width: 100vw;
    }

    &.fuelTypes {
        @include mobile {
            justify-content: stretch;
            flex-wrap: nowrap;
            overflow: auto;

            &::after {
                content: '';
                position: absolute;
                right: 0;
                width: 22px;
                height: 60px;
                background: linear-gradient(90deg, transparent 0%, #FAFAFA 100%);
            }

            &::before {
                content: '';
                position: absolute;
                left: 0;
                width: 22px;
                height: 60px;
                background: linear-gradient(270deg, transparent 0%, #FAFAFA 100%);
            }
        }
    }

    .group {
        border-right: 1px solid #000;
        display: flex;
        flex-wrap: nowrap;
        gap: 16px 8px;
        padding: 0 35px;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            border-right: 0;
            padding-right: 0;
        }

        @include mobile {
            border-right: 0;
            padding: 0;
        }
    }
}

.radio {
    span {
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        line-height: 36px;
        padding: 0 4px;
        width: 124px;

        @include fast-transition();
        @include design {
            border-radius: 2px;
            background: #E4E7EC;
            color: var(--color-text-3);
            font-size: 24px;
            font-family: Bebas Neue;
            font-weight: 700;
            letter-spacing: 0.587px;
            text-align: center;
        }
    }

    input {
        display: none;
    }

    input:checked + span,
    span:hover {
        background: var(--color-black);
        color: #fff;
    }
}

.vehicleRadio {
    > span {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: 67px;
        gap: 21px;
        width: 322px;

        @include fast-transition();
        @include design {
            border-radius: 2px;
            border: 1px solid var(--color-main);
            background: #FFF;
        }

        .icon {
            background-position: center right;
            background-repeat: no-repeat;
            background-size: auto 46px;
            display: inline-block;
            flex: 0 0 98px;
            height: 46px;

            &.small {
                background-image: url('../assets/Vehicle_Small_Black.svg');
            }

            &.big {
                background-image: url('../assets/Vehicle_Big_Black.svg');
            }
        }

        .text {
            box-sizing: border-box;
            display: inline-block;

            @include design {
                color: var(--color-black);
                font-size: 0.75em;
                font-family: Roboto;
                font-weight: 400;
                letter-spacing: 0.171px;
            }
        }
    }

    input {
        display: none;
    }

    input:checked + span,
    > span:hover {
        background: var(--color-black);
        border-color: transparent;
        color: #fff;

        .icon.small {
            background-image: url('../assets/Vehicle_Small_Yellow.svg');
        }

        .icon.big {
            background-image: url('../assets/Vehicle_Big_Yellow.svg');
        }

        .text {
            color: #FFF;
        }
    }
}

.vehicleRadioRow {
    display: flex;
    gap: 8px;
    margin-bottom: 12px;

    @include mobile {
        flex-direction: column;
    }
}

.checkbox {
    align-items: center;
    display: flex;
    gap: 24px;

    @include design {
        color: var(--color-black);
        font-family: Roboto;
        font-size: 0.875em;
        font-weight: 400;
        letter-spacing: 0.2px;
    }

    .box {
        content: "";
        display: inline-block;
        flex: 0 0 auto;
        height: 20px;
        width: 20px;

        @include default-transition();
        @include design {
            background: var(--color-bg);
            border: 1px solid var(--color-text-3);
            border-radius: 6px;
        }
    }

    .label {
        line-height: 22px;
    }

    input {
        display: none;
    }

    input:checked + .box {
        background-color: var(--color-main);
        background-image: url('../assets/Icon_Check_White.svg');
        background-position: center;
        background-repeat: no-repeat;
        border-color: var(--color-main);
    }
}

.addressSection {
    display: flex;
    justify-content: space-between;
    gap: 57px;

    .address {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;

        .titleBar {
            align-items: baseline;
            display: flex;
            justify-content: space-between;

            button.edit {
                height: 38px;
                line-height: 38px;
                width: 112px;

                @include design {
                    border-radius: 2px;
                    border: 1px solid #E7E7E7;
                    box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.07);
                    color: var(--color-text-3);
                    font-family: Roboto;
                    font-size: 0.875em;
                    font-weight: 400;
                    letter-spacing: 0.2px;
                    text-align: center;
                }
            }
        }

        .addr {
            color: #000;
            font-family: Roboto;
            font-size: 1.25em;
            font-style: normal;
            font-weight: 300;
            line-height: 27px;
            letter-spacing: 0.286px;
        }

        .subaddr {
            color: var(--color-text-1);
            font-family: Roboto;
            font-size: 0.875em;
            font-weight: 300;
            line-height: 22px;
            letter-spacing: 0.2px;
        }
    }

    .map {
        background: #eee;
        height: 201px;
        line-height: 201px;
        text-align: center;
        width: 293px;

        @include mobile {
            display: none;
        }
    }
}

.manager {
    display: flex;
    flex: 1 1 0px;
    justify-content: space-between;
    margin-bottom: 32px;

    @include design {
        border-radius: 2px;
        background: #FFF;
        box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.07);
    }

    @include mobile {
        flex-direction: column;
        margin: 16px;

        @include design {
            border-radius: 2px;
            background: #FFF;
            box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.07);
        }
    }

    .detailsPane {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 32px;
        gap: 35px;

        h2 {
            margin: 0;

            @include design {
                color: #000;
                font-family: Bebas Neue;
                font-size: 1.25em;
                font-weight: 700;
                letter-spacing: 0.5px;
            }
        }

        .person {
            display: flex;
            gap: 16px;

            img {
                background: #eee;
                grid-area: img;
                height: 87px;
                width: 87px;
            }

            .details {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .name {
                    @include design {
                        color: #000;
                        font-family: Roboto;
                        font-size: 1.25em;
                        font-weight: 300;
                        line-height: 27px;
                        letter-spacing: 0.286px;
                    }
                }

                .contacts {
                    @include design {
                        color: var(--color-text-1);
                        font-family: Roboto;
                        font-size: 0.875em;
                        font-weight: 300;
                        line-height: 22px;
                        letter-spacing: 0.2px;
                    }
                }
            }
        }
    }

    .contactsPane {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        justify-content: center;
        padding: 32px;

        @include design {
            background: var(--color-bg);
        }

        @include mobile {
            gap: 8px;
            padding: 15px 7px;
        }

        .item {
            align-items: center;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            padding: 24px 16px;
            width: 160px;

            @include design {
                border-radius: 2px;
                border: 1px solid rgba(30, 30, 30, 0.08);
            }

            .icon {
                background-size: 42px 42px;
                height: 42px;
                margin-bottom: 15px;
                width: 42px;

                &.email {
                    background-image: url("../../assets/Icon_Manager_Email.svg");
                }

                &.phone {
                    background-image: url("../../assets/Icon_Manager_Phone.svg");
                }

                &.hours {
                    background-image: url("../../assets/Icon_Manager_Hours.svg");
                }
            }

            .action {
                color: #000;
                text-align: center;
                font-family: Roboto;
                font-size: 0.875em;
                font-weight: 300;
                line-height: 22px;
                letter-spacing: 0.2px;
            }

            .subtext {
                color: #000;
                text-align: center;
                font-family: Roboto;
                font-size: 0.75em;
                font-weight: 300;
                line-height: 22px;
                letter-spacing: 0.171px;
            }
        }
    }
}
