@import "../../Layout.scss";
@import "../../Animation.scss";

.error-modal {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    overflow-y: auto;
    max-height: 100vh;
    z-index: 1;
    background: rgb(250, 250, 250);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.error-modal-img {
    width: 128px;
    height: 128px;
}

.error-modal-title {
    margin: 32px 0px 0px 0px;
    padding: 0px;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 40px;
        font-weight: 300;
        letter-spacing: 0.8px;
        line-height: 48px;
        text-align: center;
    }
}

.error-modal-text {
    width: 256px;
    margin: 12px 0px 0px 0px;
    padding: 0px;
    @include design {
        color: #484848;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.23px;
        line-height: 27px;
        text-align: center;
    }
}

.error-modal-button {
    height: 44px;
    margin: 32px 0px 0px 0px;
    padding: 0px 48px 0px 48px;
    background: white;
    border: 1px solid #E7E7E7;
    border-radius: 2px;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.05);
}

.error-modal-button-img {

}

.error-modal-button-text {
    margin: 0px 0px 0px 8px;
    @include design {
        color: #1E1E1E;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
        text-align: center;
    }
}