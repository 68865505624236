@import "../../../Layout.scss";
@import "../../../Animation.scss";

.invoices {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3, $row-start: 2);
    @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: auto auto 1fr);
    background: rgb(250, 250, 250);
    @include mobile {
        @include grid($cols: 16px 1fr 16px, $rows: auto auto 1fr);
    }
}

.headerShadow {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3, $row-span: 1);
    background: white;
}

.headerPlaceholder {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3); // height: 235px;
    width: 100%;
    height: 72px;
    @include mobile {
        height: 52px;
    }
    background: white;
}

.headerStatic {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3);
    @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: 1fr);
    @include mobile {
        @include grid($cols: auto 1fr auto, $rows: 1fr);
    }

    z-index: 1;

    &.fixed {
        position: fixed;
        position: sticky; // Override with sticky on browsers which support it
        left: 0px;
        right: 0px;
        top: 80px;
        &.animated {
            @include fast-transition(transform);
        }

        &.hiding {
            transform: translateY(-100%);
        }
    }

    .background {
        @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3);
        background: white;
        box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.05);
    }

    .header {
        @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2);
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include mobile {
            display: none;
        }
    }

    .items {
        display: flex;
        align-items: center;

        &.left {
            justify-content: flex-start;
        }

        &.right {
            justify-content: flex-end;
        }

        .termsOfSaleLink {
            text-decoration: none;

            @include design {
                color: #c1c1c1;
                font-family: Bebas Neue;
                font-size: 20px;
                font-weight: 700;
                letter-spacing: .49px;
                line-height: 24px;
            }

            &:hover {
                color: black;
            }

            &:visited {
                color: #c1c1c1;
            }
        }
    }

    .mobileFilters {
        @include not-mobile {
            display: none;
        }
        @include mobile {
            @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3, $row-start: 2);
        }
    }
}

.stats {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 2);

    box-sizing: border-box;
    margin: 0 -16px;
    padding: 16px;

    @include mobile {
        overflow-x: auto;

        .inner {
            padding-right: 16px;
            min-width: 1024px;
        }
    }
}

.loader {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 3);
}

.body {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 3);
    margin: 12px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    @include mobile {
        margin: 48px 0px 48px 0px;
        padding-bottom: 0px;
    }
}

.content {
    &:first-child {
        margin: 0px;
    }
}


