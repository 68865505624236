@import '../dashboard/Layout.scss';
@import '../dashboard/Animation.scss';

.register-modal {
    min-height: 100%;
    width: 100%;
    background: rgb(240, 180, 36);
    @include grid($cols: 1fr 1024px 1fr, $rows: 1fr);
    @include mobile {
        @include grid($cols: 16px 1fr 16px, $rows: 1fr);
        padding: 0px 0px 32px 0px;
        box-sizing: border-box;
    }
}

.register-modal_left-img {
    @include in-grid($horizontal: start, $col-start: 2);
    margin: 42px;
    display: none;
}

.register-modal_right-img {
    @include in-grid($horizontal: end, $col-start: 2);
    margin: 42px;
    display: none;
}

.register {
    @include in-grid($col-start: 2,$horizontal: start,  $vertical: start);
    @include grid($cols: 1fr, $rows: auto auto auto auto auto auto);
    max-width: 560px;
    margin: auto;
    @include mobile {
        width: auto;
    }
}

.register-logo {
    @include in-grid($horizontal: center, $col-span: 2);
    background: none;
    width: auto;
    height: auto;
    .header-logo {
        height: 18px;
        width: auto;
    }
}

.register-card-top {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 2, $row-start: 3);
    margin: 48px 8px 0;
    border-radius: 2px 2px 0px 0px;
    background: white;
    padding: 32px 16px;
    box-sizing: border-box;
    @include mobile {
        padding: 16px;
    }
}

.register-title {
    margin: 0px 0px 24px 0px;
    text-align: center;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 40px;
        font-weight: 300;
        letter-spacing: 1.28px;
        line-height: 48px;
    }
}

.register-card-top_text {
    margin: 0px 0px 0px 16px;
    text-align: left;
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.23px;
        line-height: 27px;
    }
}

.register-card-mid {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 2, $row-start: 4);
    @include grid($cols: 1fr 1fr, $rows: 1fr 1fr);
    border-radius: 2px;
    background: #fafafa;
    border: 1px solid rgba(0, 0, 0, .05);
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, .07);
    box-sizing: border-box;
    .first-name-field {
        @include in-grid($horizontal: stretch);
        @include mobile {
            @include in-grid($horizontal: stretch, $col-span: 2);
            border-right: none;
        }
    }
    .last-name-field {
        @include in-grid($horizontal: stretch, $col-start: 2);
        @include mobile {
            @include in-grid($horizontal: stretch, $col-span: 2, $row-start: 2);
        }
    }
    .personalcode-field {
        @include in-grid($horizontal: stretch, $row-start: 2);
        @include mobile {
            @include in-grid($horizontal: stretch, $col-span: 2, $row-start: 3);
        }
    }
    .phone-field {
        @include in-grid($horizontal: stretch, $col-start: 2, $row-start: 2);
        @include mobile {
            @include in-grid($horizontal: stretch, $col-span: 2, $row-start: 4);
        }
    }
    .email-field {
        @include in-grid($horizontal: stretch, $col-span: 2, $row-start: 3);
        @include mobile {
            @include in-grid($horizontal: stretch, $col-span: 2, $row-start: 5);
        }
    }

}
.register_text-field {
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
    @include mobile {
        padding: 16px;
    }
    &.error {
        .register_text-field-label {
            color: tomato;
        }
        .register_text-field-text {
            color: tomato;
        }
    }
}

.register_text-field-label {
    margin: 0px 12px;
    @include design {
        color: #909090;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 24px;
    }
}

.register_text-field-text {
    margin: 4px 12px;
    background-color: transparent;
    border: 0;
    border-bottom:  1px solid rgba(0, 0, 0, .3);
    text-indent: 5px;
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 24px;
        font-weight: 300;
        letter-spacing: 0.34px;
        line-height: 27px;
    }
}

.register-card-bot {
    @include in-grid($horizontal: stretch, $vertical: stretch,$col-span: 2, $row-start: 5);
    margin: 0px 8px 0px 8px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 32px 8px;
    box-sizing: border-box;
    @include mobile {
        padding: 16px 16px 0px 16px;
    }
}

.register_checkbox-field {
    display: flex;
    align-items: center;
    &:not(:first-child) {
        margin: 16px 0px 0px 0px;
    }
}

.register_checkbox-text {
    margin: 0px 0px 0px 16px;
    @include design {
        color: black;
        font-family: Roboto;
        font-size: 16px;
        letter-spacing: 0.2px;
        line-height: 16px;
        text-align: center;
        font-weight: 300;
    }
    @include mobile {
        @include design {
            font-size: 14px;
        }
    }
}

.register-card-buttons {
    @include in-grid($row-start: 6, $horizontal: stretch);
    margin: 0px 8px 0px 8px;
    padding: 0px 32px 32px;
    border-radius: 0px 0px 2px 2px;
    background: white;
    display: flex;
    justify-content: space-between;
    @include mobile {
        padding: 8px 16px 16px 16px;
    }
}

.register-modal_cancel-button {
    height: 48px;
    margin: 16px 0 0 0;
    border-radius: 2px;
    padding: 16px 32px 16px 32px;
    border: 1px solid black;
    box-sizing: border-box;
    @include mobile {
        padding: 16px;
    }
}

.register-modal_cancel-button_text {
    margin: 0px 0px 0px 12px;
    @include design {
        color: black;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
        text-align: center;
    }
}

.register-modal_join-button {
    height: 48px;
    margin: 16px 0 0 0;
    border-radius: 2px;
    padding: 16px 32px 16px 32px;
    background: black;
    @include mobile {
        padding: 16px;
    }
}


.register-modal_join-button_text {
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
        text-align: center;
    }
}

.register-modal_join-button_img {
    margin: 0px 0px 0px 12px;
}

.register-card-info {
    @include in-grid($row-start: 7);
    margin: 0px 8px 0px 8px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}