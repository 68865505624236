@import "../../Layout.scss";
.business-overview {
    @include in-grid($horizontal: stretch, $vertical: stretch, $row-start: 2);
    @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: auto auto auto 1fr 24px auto);
    -ms-grid-rows: auto auto auto auto 24px auto; //flexbox in 1fr row has 0 height on ie11
    background: rgb(250, 250, 250);
    @include mobile {
        @include grid($cols: 16px 1fr 16px, $rows: auto auto auto 1fr auto auto);
    }
}

.business-overview-header-shadow {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3, $row-span: 2);
    background: white;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.05);
}

.business-overview-header {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2);
    height: 312px;
    box-sizing: border-box;
    padding: 60px 0px 84px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mobile {
        height: auto;
        padding: 24px 0px 24px 0px;
        flex-direction: column;
        align-items: flex-start;
    }
}

.business-overview-header-loader {
    @include in-grid($col-start: 2);
}

.business-overview-header-overhang {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 2, $row-span: 2);
}

.business-overview-body {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 4);
    padding: 64px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include mobile {
        padding: 48px 0px 48px 0px;
        flex-direction: column;
    }

    .co-content-container.bills,
    .co-content-container.cards {
        // Compensate for the space taken up by the faded item
        margin-bottom: -64px;
    }
}

.business-overview-footer-shadow {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 1, $col-span: 3, $row-start: 5, $row-span: 2);
    box-shadow: 0 -7px 10px 0 rgba(0, 0, 0, 0.05);
    z-index: 1;
}

.business-overview-footer {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 5, $row-span: 2);
    z-index: 1;
}

.business-overview-ad-background {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3, $row-start: 5, $row-span: 2);
    background: black;
    z-index: 1;
}

.business-overview-ad {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 5, $row-span: 2);
    z-index: 1;
    padding: 48px 0px 48px 0px;
    @include mobile {
        padding: 16px 0px 16px 0px;
    }
}

.business-overview-ad_img {
    width: 100%;
    user-select: none;
    cursor: pointer;
    z-index: 2;
}
