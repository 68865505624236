@import "../../../Layout.scss";
@import "../../../Animation.scss";
.co-btn-outline {
    padding: 0;
    .co-btn-outline{
        //padding: 12px 32px 12px 32px;
        position: relative;
        height: 32px;
        width: 32px;
        background: rgb(254, 254, 254);
        border: 1px solid rgb(231, 231, 231);
        box-sizing: border-box;
        border-radius:2px;
        box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
        margin: 0;
        @include fast-transition(color, border-color);
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: none;
            box-shadow: none;
        }
        .active{
            display: none;
        }
        .inactive{
            display: block;
        }
        &:hover{
            color: black;
            border: 1px solid black;
            .active{
                display: block;
            }
            .inactive{
                display: none;
            }
        }
    }
}
.tippy-popper{
    .contract-item-button,
    .metering-point-item-button{
        //display: none;
        opacity: 0;
        padding: 0;
    }
    .co-dropdown-filter_dropdown{
        padding: 24px 0;
    }
}
