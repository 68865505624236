@import '../../../Layout.scss';
@import '../../../Animation.scss';

.electricity-page-contracts{
  .electricity-consumption{
    grid-column: 1/span 3;
    .electricity-filter-row{
      position: absolute;
      background-color: white;
      grid-column: 1/span 3;
      height: 68px;
      width: 100%;
      border-bottom: 1px solid #e7e7e7;
      box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.05);
      display: grid;
      grid-template-columns: minmax(16px, 1fr) 1024px minmax(16px, 1fr);
      z-index: 1; // if removed, the shadow appears after scrolling
      @include mobile() {
        height: 52px;
        display: inline-flex;
      }

      &.fixed {
        z-index: 1;
        position: fixed;
        top: 176px;
        @include mobile {
          top: 80px;
          width: 100%;
        }
        &.background {
          left: 0px;
          right: 0px;
        }
        &.animated {
          @include fast-transition(transform, opacity);
        }
        &.alt {
          transform: translateY(-96px);
          @include mobile {
            transform: translateY(0px);
          }
        }
      }
      &-content{
        grid-column: 2/span 1;
        align-self: stretch;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @include mobile(){
          flex-grow: 1;
        }
      };

      &-content-mobile{
        display:none;
        @include mobile() {
          display: flex;
          flex-grow: 1;
          .filters-button-mobile_right-img{
            flex-grow: 1;
          }
        }
      }

      &-content-right,
      &-content-left{
        display: flex;
        flex-direction: row;
        @include mobile() {
          display: none;
        }
      }
      &-content-left{
        justify-content: left;
      }
      &-content-right{
        justify-content: right;
      }

      .electricity-filter-separator{
        margin-top: auto;
        margin-bottom: auto;
      }

      .electricity-filter-header{
        display: flex;
        margin: auto 8px;
        @include design {
          color: #909090;
          font-family: "Bebas Neue";
          font-size: 17px;
          font-weight: 600;
          letter-spacing: 0.8px;
        }
        .electricity-filter-header-icon{
          position: relative;
          margin-right: 8px;
          background-image: url("../../../assets/Icon_Filters_Gray.svg");
          height: 20px;
          width: 22px;
          background-size: 22px;
        }
      }


      .electricity-filter-button{
        display: flex;
        color: #909090;
        font-family: Roboto;
        font-size: 14px;
        margin: auto 8px;
        span{
          position: relative;
          &::after{
            display: block;
            content: attr(title);
            font-weight: 500;
            height: 0px;
            color: transparent;
            overflow: hidden;
            visibility: hidden;
          }
        }
        .electricity-filter-button-icon{
          background-image: url("../../../assets/Icon_Download_Gray.svg");
          height: 26px;
          width: 26px;
          background-size: 26px;
        }
      }

      .electricity-filter-button{
        @include mobile() {
          display: none;
        }
      }

      .electricity-filter-button.button-padding{
        span{
          top: 6px;
        }
      }


      .electricity-filter-button:hover,
      .electricity-filter-button:active,
      .electricity-filter-button.active{
        span{
          font-weight: 500;
          color: black;
        }
        .electricity-filter-button-icon{
          background-image: url("../../../assets/Icon_Download.svg");
        }
      }
    }

    .electricity-sections-container {
      position: relative;
      top: 69px;
      @include mobile() {
        top: 48px;
      }
      .electricity-footer-section,
      .electricity-even-section,
      .electricity-odd-section{
        display: grid;
        grid-template-columns: minmax(16px, 1fr) 1024px minmax(16px, 1fr);

        @include mobile() {
          width: 100vw;
          overflow: hidden;
        }

        .consumption-chart-header{
          margin-top: 32px;
          grid-column: 2/span 1;

          @include mobile() {
            padding-left: 16px;
            grid-column: 1/span 3;
            margin-top: 8px;
          }

          h2{
            @include design {
              margin: 0;
              margin-bottom: 8px;
              color: #000000;
              font-family: "Bebas Neue";
              font-size: 24px;
              font-weight: 600;
              letter-spacing: 0.4px;
              line-height: 52px;
              @include mobile() {
                line-height: 20px;
                margin-top: 16px;
                margin-bottom:0;
              }
            }
          }
        }

        @include mobile() {
          display: inline-grid;
          .electricity-body-scrollable{
            display: inline-grid;
            grid-template-columns: minmax(16px, 1fr) 1024px minmax(16px, 1fr);
            width: 100vw;
            overflow-x: scroll;
            grid-column: 1/span 1;
            .electricity-body{
              margin-left:0;
            }
          }
        }
        .electricity-body{
          grid-row: initial;
          padding-bottom: 0;
          .electricity-body-content{
            margin-top: 0;
            @include mobile() {
              margin-right: 16px;
            }
          }
        }
      }

      .electricity-body-scrollable{
        grid-column: 2/span 1;
      }

      .electricity-footer-section{
        .electricity-body{
          @include mobile() {
            width: calc(100vw - 32px);
            margin:0;
            .electricity-body-content{
              @include mobile() {
                margin-right: 0;
              }
            }
          }
        }
        @include mobile() {
          padding-top: 8px;
          padding-bottom: 16px !important;
        }
      }

      .electricity-even-section{
        background-color: #fafafa;
      }
      .electricity-odd-section{
        background-color: #ffffff;
        //padding-bottom: 42px;
      }
      .electricity-footer-section{
        background-color: #1e1e1e;
        color: white;
        padding-bottom: 48px;
      }

      .electricity-usage-data{
        display: flex;
        flex-direction: row;

        @include mobile() {
          flex-direction: column;
        }

        .electricity-usage-container{
          flex-grow: 1;
          width: 33.333%;
          margin: 0 16px;

          @include mobile() {
            width: 100%;
            margin: 0;
          }

          .electricity-usage-header{
            margin: 0;
            margin-bottom: 8px;
            color: #facd00;
            font-family: "Bebas Neue";
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 0.4px;
            line-height: 52px;
          }
        }
      }

      .electricity-usage-container:first-child{
        margin-left: 0;
      }

      .electricity-usage-container:last-child{
        margin-right: 0;
      }

      .electricity-usage-box{
        background-color: #ffffff;
        color: #080808;
        display: flex;
        flex-direction: row;
        padding: 24px;
        border-radius: 24px;
        margin-bottom: 24px;
        position: relative;

        .electricity-usage-number{
          width: 60%;
          margin-top: auto;
          margin-bottom: auto;
          text-align: right;
          padding-right: 16px;

          color: #000000;
          font-family: "Bebas Neue Light";
          font-size: 64px;

          @include mobile() {
            font-size: 48px;
          }
        }
        .electricity-usage-unit{
          width: 40%;
          margin-top: auto;
          margin-bottom: auto;
          .electricity-usage-unit-top,
          .electricity-usage-unit-bottom {
            font-family: Roboto;
            color: #909090;
            font-size: 20px;
            font-weight: 300;
            letter-spacing: 0.29px;
            line-height: 18px;
            @include mobile() {
              font-size: 16px
            }
          }
        }

        .tilted-spacer{
          border: 1px solid #e7e7e7;
          margin-top: -8px;
          margin-bottom: -8px;
          transform: rotate(16deg);
          margin-right: 16px;
        }

        .electricity-usage-box-left,
        .electricity-usage-box-right{
          flex-grow: 1;
          display: flex;
          .electricity-usage-number {
            width: auto;
          }
          .electricity-usage-unit{
            flex-grow: 1;
          }
        }

        .electricity-usage-box-left{
          text-align: right;
          .electricity-usage-number{
            padding-left: 10px;
          }
        }
        .electricity-usage-box-right{
          text-align: left;
          .electricity-usage-number{
            padding-right: 10px;
          }
        }
      }

      .electricity-usage-description{
        font-family: Roboto;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.2px;
        line-height: 22px;
      }
    }
    .consumption-chart{
      padding-bottom: 48px;
      @include mobile() {
        padding-top: 24px;
        padding-bottom: 24px;
      }
      .VictoryContainer{
        svg {
          g:last-child,
          g:nth-last-child(2) {
            g {
              text {
                tspan {
                  pointer-events: none;
                }
              }
            }
          }
        }
        div:last-child{
          z-index: 0 !important;
          svg {
            g {
              animation-name: graphTooltipOpen;
              transition-timing-function: ease-out;
              animation-duration: 0.4s;
            }
          }
        }
      }
    }
  }
}
