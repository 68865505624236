@import "../../../Layout.scss";
.metering-points-tab-select-footer {
    @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: auto);
    @include mobile {
        @include grid($cols: auto 1fr auto, $rows: auto);
    }
    height: 92px;
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgb(34, 34, 34);

}

.metering-points-tab-select-footer-content {
    @include in-grid($horizontal: stretch, $vertical: center, $col-start: 2);
    display: flex;
    align-items: center;
}

.metering-points-tab-select-footer_text {
    @include design {
        color: #FFCD00;
        font-family: "Bebas Neue";
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.44px;
        line-height: 23px;
    }
}

.metering-points-tab-select-footer_close-button {
    background: none;
    border: none;
    padding: 0px;
    margin: 0px 0px 0px 8px;
    user-select: none;
    cursor: pointer;
}

.metering-points-tab-select-footer_close-button_img {
    width: 12px;
    height: 12px;
}

.metering-points-tab-select-buttons {
    margin-left: auto;
}

//.selected-button {
//    width: 248px;
//    height: 44px;
//    margin: 0px 0px 0px 24px;
//    background: rgb(34, 34, 34);
//    user-select: none;
//    cursor: pointer;
//    @include design {
//        border: 1px solid rgba(255, 255, 255, 0.3);
//        border-radius: 2px;
//        box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
//    }
//}
//
//.selected-button_img {
//    width: 16px;
//    height: 16px;
//    vertical-align: middle;
//}
//
//.selected-button_text {
//    margin: 0px 0px 0px 12px;
//    vertical-align: middle;
//    @include design {
//        color: #FFFFFF;
//        font-family: Roboto;
//        font-size: 14px;
//        letter-spacing: 0.2px;
//        line-height: 16px;
//    }
//}
