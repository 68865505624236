@import "../Layout.scss";
@import "../Animation.scss";
.co-expandable-content_title {
    padding: 16px 0px 16px 0px;
    display: flex;
    user-select: none;
    cursor: pointer;
    &:hover {
        .co-expandable-content_expand-button_img {
            opacity: 1;
        }
    }
    @include mobile {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.co-expandable-content_expand-button {
    @include in-grid($horizontal: end, $col-start: 2);
    background: none;
    border: none;
    margin: 0px 0px 0px auto;
    padding: 0px;
    user-select: none;
    cursor: pointer;
    transform: rotateX(-180deg);
    &.collapsed {
        transform: rotateX(0deg);
    }
}

.co-expandable-content_expand-button_img {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    opacity: 0.24;
}

.co-expandable-content_content {
    padding: 0px 0px 16px 0px;
}

.co-expandable-content_content-clipping {
    //1px extra on the right and 16px extra on the left edge to not clip translated elements and borders during the collapse animation
    //16px extra on bottom to not clip shadows
    width: 100%;
    height: 100%;
    padding: 0px 1px 16px 16px;
    transform: translateX(-16px);
    overflow: hidden;
    @include mobile {
        padding: 0px 16px 16px 16px;
        transform: translateX(-16px);
    }
}