@import "../../Layout.scss";
@import "../../Animation.scss";

.search {
    background: rgb(255, 205, 0);
    height: 48px;
    flex: 1;
    display: flex;
    z-index: 1;
    @include default-transition();
    min-width: 24px;
    &.open {
        min-width: 100%;
    }
    &:not(.open) {
        .search-input-form {
            display: none;
        }
        .search-close-button {
            display: none;
        }
    }
}