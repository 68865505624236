@import "../../Layout.scss";
@import "../../Animation.scss";

.modal {
    width: 576px;
    margin: auto;
    padding: 32px;
    box-sizing: border-box;
    background: rgb(30, 30, 30);

    @include mobile {
        width: 100%;
        max-width: 576px;
    }

    .title {
        margin: 0px;

        @include design {
            color: #FFCD00;
            font-family: "Bebas Neue";
            font-size: 32px;
            font-weight: bold;
            letter-spacing: 0.78px;
            line-height: 38px;
        }
    }

    .content {
        @include grid($cols: 1fr 1fr, $rows: auto 1fr);

        > .inner {
            @include in-grid($horizontal: stretch, $col-span: 2);
            @include default-transition();
            @include design {
                color: #FFFFFF;
                font-family: Roboto;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.23px;
                line-height: 24px;
            }

            .text {
                margin: 32px 0px 0px 0px;

                @include design {
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.23px;
                    line-height: 24px;
                }

                &.success {
                    color: #FFFFFF;
                }

                &.error {
                    color: red;
                }
            }
        }
    }

    button.close > img {
        width: 12px;
        height: 12px;
    }
}
