@import "../Layout.scss";
@import "../Animation.scss";
.select-modal {
    @include grid($cols: 1fr auto, $rows: auto 1fr);
    width: 512px;
    margin: auto;
    border-radius: 2px;
    @include mobile {
        width: 100%;
    }
}

.select-modal-header-background {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 2);
    background: white;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
}

.select-modal-title {
    @include in-grid($horizontal: start);
    margin: 24px;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 42px;
        font-weight: 300;
        letter-spacing: 1.03px;
        line-height: 52px;
    }
}

.select-modal-close-button {
    @include in-grid($horizontal: end, $col-start: 2);
    padding: 24px;
    &:hover {
        .select-modal-close-button_img {
            opacity: 1;
        }
    }
}

.select-modal-close-button_img {
    width: 16px;
    height: 16px;
    opacity: 0.24;
    @include fast-transition(opacity);
}

.select-modal-collection {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 2, $row-start: 2);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 24px;
    background: rgb(250, 250, 250);
}

.select-modal-collection-bank-links-checkbox {
    display: flex;
    align-items: center;
}

.select-modal-collection-bank-links-checkbox-text {
    margin: 0px 0px 0px 8px;
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 24px;
    }
}

.select-modal-collection-bank-links-checkbox-text-url {
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 24px;
    }
    &:visited {
        color: #000000;
    }
}

.select-modal-item {
    @include grid($cols: auto 1fr auto, $rows: 1fr);
    margin: 8px 0px 0px 0px;
    padding: 16px;
    background: white;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
    &:hover {
        .select-modal-item-chevron {
            opacity: 1;
        }
    }
    &.disabled {
        filter: brightness(0.9) grayscale(1);
        cursor: not-allowed;
        &:hover {
            .select-modal-item-chevron {
                opacity: 0.24;
            }
        }
    }
}

.select-modal-item-img {
    @include in-grid();
    height: 48px;
    &.notext {
        @include in-grid($horizontal: start, $col-start: 1, $col-span: 2);
        justify-self: center;
    }
}

.select-modal-item-text {
    @include in-grid($horizontal: start, $col-start: 2);
    margin: 0px 0px 0px 16px;

}
.select-modal-item-text-primary {
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.59px;
        line-height: 29px;
    }
}
.select-modal-item-text-secondary {
    @include design {
        color: #909090;
        font-family: Roboto;
        font-size: 12px;
        letter-spacing: 0.17px;
        line-height: 14px;
    }
}

.select-modal-item-chevron {
    @include in-grid($horizontal: end, $col-start: 3);
    margin: 0px 0px 0px 16px;
    opacity: 0.24;
    @include fast-transition(opacity);
}