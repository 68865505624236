@import "./Layout.scss";
@import "./Animation.scss";
@import "./Animations.scss";
.dashboard {
    @include grid($cols: 1fr, $rows: auto 1fr);
    height: 100%;
}

.header-placeholder {
    @include in-grid($horizontal: stretch, $row-start: 1);
    height: 80px;
}

.header {
    @include in-grid($horizontal: stretch, $row-start: 1);
    @include grid($cols: minmax(16px, 1fr) minmax(1024px - 32px, 1440px) minmax(16px, 1fr), $rows: 1fr);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 20;
    background: white;
    &.yellow-header {
        background: rgb(255, 205, 0);
    }
    @include tablet {
        @include grid($cols: auto 1fr auto, $rows: 1fr);
    }
}

.nav-user-dropdown-header-fade {
    background: hsla(0, 0%, 40%, 0.75);
    z-index: 2;
    &.left {
        @include in-grid($horizontal: stretch, $vertical: stretch);
    }
    &.right {
        @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 3);
    }
}

.nav-user-dropdown-content-fade {
    @include in-grid($horizontal: stretch, $vertical: stretch, $row-span: 2);
    background: hsla(0, 0%, 40%, 0.75);
    z-index: 2;
}

.mobile-filters-content-fade {
    @include in-grid($horizontal: stretch, $vertical: stretch, $row-span: 2);
    background: hsla(0, 0%, 40%, 0.75);
    z-index: 3;
}

.modal-open {
    overflow: hidden;
    @include tablet {
        overflow: hidden;
    }
    @include mobile {
        overflow: hidden;
    }
}

.modal-translucent {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    overflow-y: auto;
    max-height: 100vh;
    z-index: 20;
    background: hsla(0, 0%, 40%, 0.75);
    display: flex;
    flex-direction: column;
    &.modal-translucent-white {
        background: rgba(0,0,0, 0.5);
    }
    &.modal-translucent-dark {
        background: rgba(0,0,0, 0.75);
    }
    &.modal-transparent{
        opacity: 0;
    }
}

.modal-enter {
    opacity: 0.01;
    >* {
        &:first-child {
            transform: scale(0.8);
        }
    }
    &.modal-enter-active {
        opacity: 1;
        >* {
            &:first-child {
                transform: scale(1);
                @include default-transition(transform);
            }
        }
        @include default-transition(opacity);
    }
}

.modal-exit {
    opacity: 1;
    >* {
        &:first-child {
            transform: scale(1);
        }
    }
    &.modal-exit-active {
        opacity: 0.01;
        >* {
            &:first-child {
                transform: scale(0.8);
                @include default-transition(transform);
            }
        }
        @include default-transition(opacity);
    }
}

.filters-enter {
    opacity: 0.01;
    transform: translateY(100%);
    &.filters-enter-active {
        opacity: 1;
        transform: translateY(0%);
        @include default-transition(opacity, transform);
    }
}

.filters-exit {
    opacity: 1;
    transform: translateY(0%);
    &.filters-exit-active {
        opacity: 0.01;
        transform: translateY(100%);
        @include default-transition(opacity, transform);
    }
}

.mobile-dropdown-button{
    justify-items: center;
    .mobile-dropdown-button-content{
        cursor: pointer;
        border-top: 2px solid white;
        border-bottom: 2px solid white;
        height: 38px;
        .mobile-dropdown-button-text{
            margin-right: 8px;
            line-height: 38px;
            color: #000000;
            font-family: 'Bebas Neue';
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0.8px;
        }
        .mobile-dropdown-button-chevron{
            padding-bottom: 2px;
        }
    }
}

.mobile-dropdown{
    position: absolute;
    left: 0;
    right: 0;
    top: 160px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    z-index: 2;

    .mobile-dropdown-white-background{
        background-color: white;
    }
    .mobile-dropdown-translucent-backdrop{
        background-color: hsla(0, 0%, 40%, 0.75);
        width: 100%;
        height: 100%;
    }

    .nav-item-appear {
        margin: 0px 0px 32px 0px;
        &.nav-item-appear-active {
            margin: 0px 0px 4px 0px;
            @include default-transition(margin);
        }
    }
    .white-circle-24px{
        height: 24px;
        width: 24px;
        margin-right: 8px;
        &-dark-outline{
            border: 1px solid #000000;
            border-radius: 13px;
        }
    }
}

.mobile-dropdown-item {
    height: 48px;
    margin: 0 0 4px 0;
    //padding: 0px 32px 0px 16px;
    padding: 0 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    cursor: pointer;
    user-select: none;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
    //flex-direction: row-reverse;
    flex-direction: row;
    color: black;
    text-decoration: none;
    .mobile-dropdown-item-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        .mobile-dropdown-item-title{
            color:#484848;
            font-family: Roboto;
            font-size: 14px;
            letter-spacing: 0.2px;
            line-height: 16px;
            font-weight: normal;
        }
    }
}

.mobile-dropdown-item:after{
    content: "";
    display: block;
    height: 18px;
    width: 11px;
    background: url("./assets/Icon_Chevron-Right_Gray-16px.svg") center;
}

.mobile-dropdown-item:last-child{
    margin: 0 !important;
}

.mobile-dropdown-chevron {
    width: 8px;
    height: 8px;
    align-self: center;
    pointer-events: none;
}


.mobile-dropdown-button{
}
