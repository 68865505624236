@import "../../../Layout.scss";
.cards-page-select-footer {
    @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: auto);
    @include mobile {
        @include grid($cols: auto 1fr auto, $rows: auto);
    }
    background: rgb(34, 34, 34);
}

.cards-page-select-footer-content {
    @include in-grid($horizontal: center, $vertical: center, $col-start: 1, $col-span: 3);
    display: flex;
    align-items: center;
}

.cards-page-select-footer_text {
    @include design {
        color: #FFCD00;
        font-family: "Bebas Neue";
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.44px;
        line-height: 23px;
    }
}

.cards-page-select-footer_close-button {
    background: none;
    border: none;
    padding: 0px;
    margin: 0px 0px 0px 8px;
    user-select: none;
    cursor: pointer;
}

.cards-page-select-footer_close-button_img {
    width: 12px;
    height: 12px;
}

.cards-page-select-buttons {
    margin-left: auto;
    display: flex;
    min-width: 800px;
}

.selected-button {
    flex: 1 1 0px;
    height: 44px;
    margin: 0px 0px 0px 24px;
    background: rgb(34, 34, 34);
    user-select: none;
    cursor: pointer;
    @include design {
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 2px;
        box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
    }
}

.selected-button_img {
    width: 16px;
    height: 16px;
    vertical-align: middle;
}

.selected-button_text {
    margin: 0px 0px 0px 12px;
    vertical-align: middle;
    @include design {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
    }
}
