@import "../../../Animation.scss";
@import "../../../Layout.scss";

.header {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 1, $col-span: 3);
}

.search {
    @include in-grid($horizontal: end, $vertical: start);
}
