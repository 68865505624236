@import "../../../Layout.scss";
@import "../../../Animation.scss";
.filters {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0px 24px 0px;
    white-space: nowrap;
    @include tablet {
        display: none;
    }
}

.filters-title_img {
    width: 20px;
    height: 16px;
    margin: 0px 0px 4px 0px;
    vertical-align: middle;
}

.filters-title_text {
    margin: 0px 0px 0px 8px;
    vertical-align: middle;
    @include design {
        color: #909090;
        font-family: "Bebas Neue";
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.44px;
        line-height: 23px;
    }
}

.filters-separator {
    width: 1px;
    height: 24px;
    background-color: rgba(0, 0, 0, 0.25);
    margin: 0px 0px 0px 28px;
}

.filters-order-by-button {
    margin: 0px 0px 0px 28px;
    flex: 0 0 auto;
    @include design {
        color: #909090;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
    }
    &.active {
        @include design {
            color: #000000;
            font-weight: 500;
        }
    }
    &:hover {
        @include design {
            color: #000000;
        }
    }
}

.filters-button-small {
    margin: 0px 0px 0px 28px;
    flex: 0 0 auto;
    &:hover {
        .filters-button-small-img {
            opacity: 1;
        }
        .filters-button-small-text {
            @include design {
                color: #000000;
            }
        }
    }
    &.active {
        .filters-button-small-img {
            opacity: 1;
        }
    }
}

.filters-button-small-img {
    width: 12px;
    height: 12px;
    vertical-align: middle;
    opacity: 0.24;
    @include fast-transition(opacity);
}

.filters-button-small-text {
    margin: 0px 0px 0px 8px;
    vertical-align: middle;
    @include fast-transition(color, font-weight);
    @include design {
        color: #909090;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
    }
    &.active {
        @include design {
            color: #000000;
            font-weight: 500;
        }
    }
}
