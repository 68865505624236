@import "../Layout.scss";
.co-content-container {
    @include grid($cols: 1fr auto, $rows: auto 1fr);
}

.co-content-container_title {
    @include in-grid($horizontal: stretch);
}

.co-content-container_action {
    @include in-grid($horizontal: end, $col-start: 2);
}

.co-content-container_content {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 2, $row-start: 2);
}