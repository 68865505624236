@import "../../../Layout.scss";
@import "../../../Animation.scss";

.table-wrapper {
    @include mobile {
        width: 100vw;
        overflow-y: auto;
        .table-margin-wrapper {
            margin: 0px 16px 0px 16px;
            display: inline-block;
        }
        .table {
            width: 1024px;
        }
    }
}

.table-header-row {
    &.small {
        border-left: 3px solid black;
        border-right: 3px solid black;
    }
    // &.fixed {
    //     position: fixed;
    //     top: 80px + 72px;
    // }
}

.table-body-row {
    &.small {
        border-left: 3px solid black;
        border-right: 3px solid black;
        &:last-child {
            border-bottom: 3px solid black;
        }
    }
}

.table-header-cell {
    &.left {
        text-align: left;
    }
    &.right {
        text-align: right;
    }
    &.small-table-first-col {
        padding: 0px 0px 0px 24px;
        @include mobile {
            padding: 0px 0px 0px 16px;
        }
    }
    &.small {
        .table-header-cell_filter-button {
            cursor: default;
            .table-header-cell_filter-button_img {
                visibility: hidden;
            }
        }
    }
    &.card {
        width: 128px;
    }
    &.product {
        width: 256px;
    }
    &.station {
        width: 156px;
    }
    &.time {
        width: 96px;
    }
    &.quantity {
        width: 96px
    }
    &.sum {
        width: 146px;
    }
    &.sumvat {
        width: 146px;
    }
}

.table-data-cell {
    max-width: 180px;
    height: 80px;
    padding: 0px 2px 0px 0px;
    box-sizing: border-box;
    &.left {
        text-align: left;
    }
    &.right {
        text-align: right;
        padding: 0px 16px 0px 0px;
    }
    &.small-table-first-col {
        padding: 0px 0px 0px 24px;
        @include mobile {
            padding: 0px 0px 0px 16px;
        }
    }
}
