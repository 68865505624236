$default-animation-speed: 600ms;
$fast-animation-speed: 300ms;
$slow-animation-speed: 1000ms;
$default-animation-curve: cubic-bezier(.1, .6, 0, 1);
@mixin default-transition($type...) {
    @if length($type)==0 {
        $type: all;
    }
    transition-property: $type;
    transition-duration: $default-animation-speed;
    transition-timing-function: $default-animation-curve;
}

@mixin fast-transition($type...) {
    @if length($type)==0 {
        $type: all;
    }
    transition-property: $type;
    transition-duration: $fast-animation-speed;
    transition-timing-function: $default-animation-curve;
}

@mixin slow-transition($type: all) {
    transition: $type $slow-animation-speed $default-animation-curve;
}

@mixin transition-800ms($type...) {
    @if length($type)==0 {
        $type: all;
    }
    transition-property: $type;
    transition-duration: 800ms;
    transition-timing-function: linear;
}