@import "../../../Layout.scss";
@import "../../../Animation.scss";
.manage-services {
    margin: 16px 0px 0px 0px;
    background: white;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
    position: relative;
    @include mobile {
        margin: 48px 16px 0px 16px;
        box-shadow: none;
    }
}

.manage-services-content {
    @include grid($cols: 1fr auto, $rows: auto 1fr);
}

.manage-services-loader {
    @include in-grid($row-span: 2);
    height: 156px;
    @include mobile {
        height: auto;
    }
}

.manage-services_button-add {
    @include in-grid($horizontal: end, $col-start: 2);
    margin: 32px 32px 0px 0px;
    @include mobile {
        margin: 0px;
        padding: 10px 32px 10px 32px;
    }
}

.manage-services_collection {
    @include in-grid($horizontal: stretch, $vertical: stretch, $row-start: 2, $col-span: 2);
    margin: 32px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    @include mobile {
        margin: 24px 0px 0px 0px;
    }
}

.manage-services_collection-item-wrap { //flexbugs #7, ie11 flex-basis doesn't account for box-sizing: border-box
    margin: 0px 0px 32px 0px;
    &:nth-child(3n+2) {
        margin: 0px 32px 32px 32px;
    }
    flex: 0 1 calc(100% / 3 - 64px / 3 - 0.11px); //ie11 correction by 0.11px
    @include mobile {
        flex: 0 1 100%;
        margin: 6px 0px 0px 0px;
        &:nth-child(3n+2) {
            margin: 6px 0px 0px 0px;
        }
    }
}

.manage-services_collection-item {
    @include grid($cols: 1fr auto 1fr, $rows: auto auto 1fr);
    width: 100%;
    padding: 32px 0px 32px 0px;
    box-sizing: border-box;
    border: 1px solid rgb(231, 231, 231);
    cursor: pointer;
    @include fast-transition(border);
    &:hover {
        border: 1px solid black;
        .manage-services_collection-item-button {
            opacity: 0.24;
            transform: translateX(0px);
            &:hover {
                opacity: 1;
            }
        }
        .manage-services_collection-item_text-activated { 
            opacity: 0;
        }
        .manage-services_collection-item_text-activated-hover {
            opacity: 1;
        }
    }
    @include mobile {
        @include grid($cols: auto 1fr, $rows: auto auto 1fr);
        border: none;
        &:hover {
            border: none;
        }
        padding: 24px;
        box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
    }
}

.manage-services_collection-item_img {
    @include in-grid($col-start: 2);
    width: 48px;
    height: 48px;
    z-index: 0;
    @include mobile {
        @include in-grid($row-span: 3);
    }
}

.manage-services_collection-item_text-name {
    @include in-grid($col-span: 3, $row-start: 2);
    margin: 12px 0px 0px 0px;
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: 0.29px;
        line-height: 27px;
        text-align: center;
    }
    @include mobile {
        @include in-grid($horizontal: start, $col-start: 2);
        margin: 0px 0px 0px 16px;
        @include design {
            text-align: left;
        }
    }
}

.manage-services_collection-item_text-activated {
    @include in-grid($col-span: 3, $row-start: 3);
    margin: 4px 0px 0px 0px;
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
    }
    @include mobile {
        @include in-grid($horizontal: start, $col-start: 2, $row-start: 2, $row-span: 2);
        margin: 0px 0px 0px 16px;
        @include design {
            text-align: left;
        }
    }
    @include fast-transition(opacity);
}

.manage-services_collection-item_text-activated-hover {
    @include in-grid($col-span: 3, $row-start: 3);
    margin: 4px 0px 0px 0px;
    @include design {
        color: #ffcd00;
        font-family: "Bebas Neue";
        font-size: 28px;
        font-weight: 300;
        letter-spacing: 0.2px;
        text-align: center;
    }
    @include mobile {
        @include in-grid($horizontal: start, $col-start: 2, $row-start: 2, $row-span: 2);
        margin: 0px 0px 0px 16px;
        @include design {
            text-align: left;
        }
    }
    opacity: 0;
    @include fast-transition(opacity);
}

.services-modal-result {
    margin: 16px 0px 0px 0px;
}