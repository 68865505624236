@import "../Layout.scss";
@import "../Animation.scss";
.mobile-filters {
    @include grid($cols: 1fr auto, $rows: auto 1fr);
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 16px 16px 48px 16px;
    background: white;
    z-index: 20;
}

.mobile-filters-title {
    @include in-grid($horizontal: start);
    margin: 0px;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 38px;
        font-weight: 300;
        letter-spacing: 1.14px;
        line-height: 47px;
    }
}

.mobile-filters-close-button {
    @include in-grid($horizontal: end, $col-start: 2);
    width: 48px;
    height: 48px;
    &:hover {
        .mobile-filters-close-button-img {
            opacity: 1;
        }
    }
}

.mobile-filters-close-button-img {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    opacity: 0.24;
    @include fast-transition(opacity);
}

.mobile-filters-items {
    @include in-grid($horizontal: stretch, $vertical: stretch, $row-start: 2, $col-span: 2);
    max-height: 160px;
    overflow-y: auto;
    margin: 32px 0px 0px 0px;
    display: flex;
    flex-direction: column;
}

.mobile-filter {
    &:not(:first-child) {
        margin: 24px 0px 0px 0px;
    }
}

.mobile-filter-item {
    width: 100%;
    margin: 0px;
    padding: 0px;
    background: none;
    border: none;
    user-select: none;
    cursor: pointer;
    &.list {
        margin: 8px 0px 0px 8px;
    }
}

.mobile-filter-item_left-img {
    width: 15px;
    height: 20px;
    vertical-align: middle;
    float: left;
}

.mobile-filter-item_text {
    margin: 0px 0px 0px 14px;
    vertical-align: middle;
    float: left;
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 16px;
        letter-spacing: 0.23px;
        line-height: 19px;
    }
}

.mobile-filter-item_right-img {
    margin-left: auto;
    vertical-align: middle;
    float: right;
}

.mobile-filter-list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.mobile-filter-list-item {
    margin: 8px 0px 0px 16px;
    &:hover, &.selected {
        .mobile-filter-list-item_text {
            color: #000000;
        }
    }
    &:hover {
        .mobile-filter-list-item_text {
            transform: translateX(16px);
        }
    }
}

.mobile-filter-list-item_text {
    margin: 0px 0px 0px 14px;
    vertical-align: middle;
    float: left;
    @include design {
        color: #909090;
        font-family: Roboto;
        font-size: 16px;
        letter-spacing: 0.23px;
        line-height: 19px;
    }
    @include mobile-s {
        white-space: nowrap;
    }
    @include default-transition(transform, opacity);
}

.mobile-filter-list-item_right-img {
    margin-left: auto;
    vertical-align: middle;
    float: right;
}
