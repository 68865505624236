@import "../../../Layout.scss";
.manage-manager {
    @include grid($cols: 1fr auto, $rows: 1fr);
    margin: 16px 0px 0px 0px;
    background: white;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
    position: relative;
    @include mobile {
        @include grid($cols: 1fr, $rows: 1fr auto);
        margin: 32px 16px 0px 16px;
        box-shadow: none;
    }
}

.manage-manager-address-container {
    @include in-grid($horizontal: stretch, $vertical: stretch);
}

.manage-manager-content {
    @include grid($cols: 1fr, $rows: 1fr);
    padding: 24px 32px 32px 32px;
    @include mobile {
        padding: 24px 0px 24px 0px;
    }
}

.manage-manager-loader {
    @include in-grid();
    height: 100px;
}

.manage-manager_label {
    @include in-grid($horizontal: start);
    margin: 32px 0px 0px 32px;
    @include mobile {
        margin: 0px;
    }
}

.manage-manager_label-img {
    width: 38px;
    height: 38px;
    vertical-align: middle;
}

.manage-manager_label-text {
    @include in-grid($horizontal: start, $col-start: 2);
    margin: 0px 0px 0px 12px;
    vertical-align: middle;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.5px;
        line-height: 24px;
    }
}

.manage-manager_profile {
    @include grid($cols: auto 1fr, $rows: 1fr auto auto);
}

.manage-manager_profile-img {
    @include in-grid($row-span: 3, $horizontal: start, $vertical: start);
    width: 87px;
    height: 87px;
    border-radius: 2px;
    margin: 0px 16px 0px 0px;
}

.manage-manager_profile-name {
    @include in-grid($col-start: 2, $horizontal: start, $vertical: start);
    margin: 0px 0px 0px 0px;
    @include design {
        font-family: Roboto;
        font-weight: 300;
        font-size: 20px;
        color: #000000;
        letter-spacing: 0.29px;
        line-height: 27px;
    }
}

.manage-manager_profile-email {
    @include in-grid($col-start: 2, $row-start: 2, $horizontal: start, $vertical: start);
    margin: 0px 0px 0px 0px;
    @include design {
        font-family: Roboto;
        font-weight: 300;
        font-size: 14px;
        color: #484848;
        letter-spacing: 0.2px;
        line-height: 22px;
    }
}

.manage-manager_profile-phone {
    @include in-grid($col-start: 2, $row-start: 3, $horizontal: start, $vertical: start);
    margin: 0px 0px 0px 0px;
    @include design {
        font-family: Roboto;
        font-weight: 300;
        font-size: 14px;
        color: #484848;
        letter-spacing: 0.2px;
        line-height: 22px;
    }
}

.manage-manager-right_items {
    display: flex;
    align-items: center;
    padding: 32px 24px 32px 24px;
    box-sizing: border-box;
    background: #FFCD00;
    @include mobile {
        flex-direction: column;
        align-items: stretch;
        padding: 0px;
    }
}

.manage-manager-right_items-item {
    width: 160px;
    height: 149px;
    box-sizing: border-box;
    margin: 0px 8px 0px 8px;
    padding: 24px 16px 24px 16px;
    border: 1px solid rgba(30,30,30,0.08);
    border-radius: 2px;
    @include grid($cols: 1fr, $rows: 1fr auto auto);
    @include mobile {
        width: auto;
        margin: 8px 0px 8px 0px;
        &:first-of-type {
            margin: 0px 0px 8px 0px;
        }
        &:last-of-type {
            margin: 8px 0px 0px 0px;
        }
    }
}

.manage-manager-right_items-item-img {
    @include in-grid();
}

.manage-manager-right_items-item-top_text {
    @include in-grid($row-start: 2);
    margin: 16px 0px 0px 0px;
    @include design {
        font-family: Roboto;
        font-weight: 300;
        font-size: 14px;
        color: #000000;
        letter-spacing: 0.2px;
        text-align: center;
        line-height: 22px;
    }
}

.manage-manager-right_items-item-bot_text {
    @include in-grid($row-start: 3);
    margin: 0px;
    @include design {
        opacity: 0.5;
        font-family: Roboto;
        font-weight: 300;
        font-size: 12px;
        color: #000000;
        letter-spacing: 0.17px;
        text-align: center;
        line-height: 22px;
    }
}