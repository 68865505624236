@import "../../../Layout.scss";
@import "../../../Animation.scss";

.checkbox-field-common{
    display: flex;
    align-items: center;
    .green-checkbox:hover,
    .green-checkbox{
        cursor: pointer;
        input,
        img{
            cursor: pointer;
        }
    }
    .green-checkbox_img{
        border: 2px solid #d5d5d5;
        background-color: #ffffff;
    }
    .checkbox-field-common-title {
        flex: 1 1 auto;
        margin: 0px 0px 0px 16px;
        @include design {
            color: #000000;
            font-family: Roboto;
            font-size: 14px;
            letter-spacing: 0.2px;
            line-height: 24px;
        }
    }
}
