@import "../../../Layout.scss";
@import "../../../Animation.scss";

.card-groups {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    &.ungrouped {
        margin: 64px 0px 0px 0px;
    }
    @include tablet {
        .black-checkbox {
            display: none;
        }
    }
}

.cards_expandable-collection {
    &:first-child {
        margin: 48px 0px 48px 0px;
        &.collapsed {
            margin: 48px 0px 0px 0px;
        }
    }
    &:not(:first-child) {
        margin: 0px 0px 48px 0px;
        &.collapsed {
            margin: 0px 0px 0px 0px;
        }
    }
    @include tablet {
        margin: 48px 16px 0px 16px;
    }
}

.cards_expandable-collection_title {
    @include in-grid($horizontal: start);
    display: flex;
    align-items: center;
    margin: 0px;
}

.cards_expandable-collection_title_text {
    margin: 5px 0px 0px 12px;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.49px;
        line-height: 24px;
    }
}

.cards_expandable-collection_title_count {
    width: 32px;
    height: 32px;
    vertical-align: middle;
    text-align: center;
    margin: 0px 0px 0px 12px;
    padding: 5px 0px 0px 0px;
    box-sizing: border-box;
    border-radius: 2px;
    @include design {
        font-family: "Bebas Neue";
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.49px;
        line-height: 24px;
        text-align: center;
    }
}

.cards_collection {
    @include in-grid($col-span: 2, $row-start: 2, $horizontal: stretch, $vertical: stretch);
    margin: 0px;
    display: flex;
    flex-direction: column;
}

.cards-collection-item-large {
    @include grid($cols: 1fr auto, $rows: auto auto 1fr);
    @include default-transition();

    margin: 8px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    box-sizing: border-box;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
    background: white;
    border: 2px solid transparent;
    border-radius: 2px;

    .black-checkbox {
        @include in-grid($horizontal: start, $vertical: center, $row-start: 2);
        transform: translateX(0px);
        margin: 20px 0px 0px 0px;
        visibility: hidden;
        opacity: 0;
    }

    &:hover,
    &.checked {
        border: 2px solid black;
        @include tablet {
            border: 2px solid transparent;
        }
        .black-checkbox {
            visibility: visible;
            opacity: 1;
            transform: translateX(-16px);
            @include tablet {
                transform: translateX(8px);
            }
        }
        .cards-collection-item-large_text {
            margin: 24px 0px 0px 32px;
            @include tablet {
                margin: 24px 24px 0px 24px;
            }
        }
    }
}

.cards-collection-item-large_header {
    @include in-grid($col-span: 2, $horizontal: stretch, $vertical: stretch);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 2px 2px 0px 0px;
}

.cards-collection-item-large_header_img {
    width: 16px;
    height: 16px;
    margin: 0px 0px 0px 12px;
    vertical-align: middle;
}

.cards-collection-item-large_header_text {
    margin: 10px 0px 10px 12px;
    vertical-align: middle;
    @include design {
        color: #FEFFFF;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
        text-align: right;
    }
    &.right {
        margin: 0px 16px 0px auto;
    }
    @include tablet {
        &.right { 
            display: none;
        }
    }
}

.cards-collection-item-large_text {
    @include grid($cols: auto auto auto, $rows: auto);
    @include in-grid($horizontal: stretch, $row-start: 2);
    @include default-transition();

    align-items: baseline;
    justify-self: start;
    margin: 24px 24px 0px 24px;
    gap: 0 10px;
    grid-template-areas: "title number users";

    @include tablet {
        @include grid($cols: auto auto, $rows: auto auto);
        @include in-grid($horizontal: stretch, $col-span: 2, $row-start: 2);

        align-items: baseline;
        grid-template-areas:
            "title  users"
            "number .";
    }
}

.cards-collection-item_large_title {
    margin: 0;
    grid-area: title;

    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 0.78px;
        line-height: 38px;
    }
    @include tablet {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 0 0 33%;
    }
}

.cards-collection-item_large_number {
    margin: 0;
    grid-area: number;

    @include design {
        color: #BDBDBD;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
    }
}

.cards-collection-item_large_users {
    margin: 0;
    grid-area: users;

    @include design {
        color: #BDBDBD;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
    }

    @include tablet {
        justify-self: end;
    }

    &::before {
        width: 16px;
        height: 22px;
        background-image: url('../../../assets/Smartphone.svg');
        background-size: 22px 22px;
        background-position: center;
        content: " ";
        display: inline-block;
        margin: 0 4px -5px 0;
    }

    button:hover {
        text-decoration: underline;
    }
}

.cards-collection-item-large_labels {
    @include in-grid($horizontal: stretch, $vertical: center, $col-start: 2, $row-start: 2);
    @include grid($cols: auto auto 90px, $rows: 1fr auto);

    margin: 24px 24px 0px 0px;
    gap: 4px 32px;
    grid-template-areas:
        "lexpiry llimit lfree"
        "vexpiry vlimit vfree";

    @include tablet {
        @include grid($cols: 1fr auto auto, $rows: 1fr auto);
        @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 2, $row-start: 3);

        margin: 24px 24px 24px 0px;
        gap: 4px 16px;
        grid-template-areas:
            "llimit lexpiry lfree"
            "vlimit vexpiry vfree";
    }
}

.cards-collection-item-large_expiry-label {
    @include design {
        color: #909090;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.17px;
        line-height: 14px;
        text-align: right;
    }

    margin: 0;
    grid-area: lexpiry;
}

.cards-collection-item-large_expiry {
    @include design {
        color: #FF5F5F;
        font-family: Roboto;
        font-size: 16px;
        letter-spacing: 0.23px;
        line-height: 19px;
        text-align: right;
    }

    margin: 0;
    grid-area: vexpiry;
}

.cards-collection-item-large_limit-label {
    @include design {
        color: #909090;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.17px;
        line-height: 14px;
        text-align: right;
    }

    margin: 0;
    grid-area: llimit;

    @include tablet {
        justify-self: start;
        margin-left: 24px;
    }

    &.expiring {
        @include design {
            color: #000000;
        }
    }
}

.cards-collection-item-large_limit {
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 16px;
        letter-spacing: 0.23px;
        line-height: 19px;
        text-align: right;
    }

    margin: 0;
    grid-area: vlimit;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include tablet {
        justify-self: start;
        margin-left: 24px;
    }

    &.expiring {
        @include design {
            color: #FF5F5F;
        }
    }

    > span {
        white-space: nowrap;

        &:not(:last-child):after {
            content: "/";
            color: #BDBDBD;
            margin: 0 6px;
        }
    }
}

.cards-collection-item-large_free-label {
    @include design {
        color: #909090;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.17px;
        line-height: 14px;
        text-align: right;
    }

    margin: 0;
    grid-area: lfree;
}

.cards-collection-item-large_free {
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 16px;
        letter-spacing: 0.23px;
        line-height: 19px;
        text-align: right;
    }

    margin: 0;
    grid-area: vfree;
    white-space: nowrap;
}

.cards-collection-item-large_users-label {
    @include in-grid($col-start: 4, $horizontal: stretch, $vertical: end);
    margin: 0px 0px 0px 32px;
    @include design {
        color: #909090;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.17px;
        line-height: 14px;
        text-align: right;
    }
    @include tablet {
        @include in-grid($col-start: 3, $horizontal: stretch, $vertical: end);
    }
}

.cards-collection-item-large_seek-large {
    @include in-grid($col-span: 2, $row-start: 3, $horizontal: stretch, $vertical: stretch);
    height: 16px;
    display: flex;
    align-items: center;
    margin: 8px 24px 18px 24px;
}

.cards-collection-item-large_seek-large_fill {
    flex: 0 0 auto;
    height: 3px;
}

.cards-collection-item-large_seek-large_button {
    margin: 0px;
    padding: 2px 8px 2px 8px;
    border-radius: 4px;
    flex: 0 0 24px;
    @include design {
        font-family: "Bebas Neue";
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0.5px;
        line-height: 16px;
        text-align: center;
    }
}

.cards-collection-item-large_seek-large_nofill {
    height: 3px;
    background: rgb(224, 224, 224);
    flex: 1 1 100%;
}

.cards-collection-item-large_dropdown {
    @include in-grid($col-span: 2, $row-start: 4, $horizontal: stretch, $vertical: stretch);
    padding: 24px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background: rgb(250, 250, 250);
    overflow: hidden;
    gap: 14px;
    @include tablet {
        flex-direction: column;
    }
}

.cards-card-dropdown-enter {
    height: 0px;
    padding: 0px 24px 0px 24px;
    opacity: 0;

    &.cards-card-dropdown-enter-active {
        height: inherit;
        padding: 24px;
        opacity: 1;
        @include default-transition();
    }
}

.cards-card-dropdown-exit {
    padding: 24px;
    opacity: 1;

    &.cards-card-dropdown-exit-active {
        height: 0px;
        padding: 0px 24px 0px 24px;
        opacity: 0;
        @include default-transition();
    }
}

.cards-collection-item_limits {
    > span {
        white-space: nowrap;

        &:not(:last-child):after {
            content: "/";
            color: #BDBDBD;
            margin: 0 6px;
        }
    }
}
