@import "../../Layout.scss";

.container {
	box-sizing: border-box;
	height: 136px;
	margin-top: 24px;
	padding-top: 52px;
}

.slider {
	position: relative;
	width: 100%;
	touch-action: none;

	:global(.slider-track),
	:global(.slider-rail) {
		position: absolute;
		background: #FFCD00;
		border-radius: 100px;
		height: 13px;
		top: 0;
	}

	:global(.slider-rail) {
		opacity: 0.2;
		width: 100%;
	}

	:global(.slider-handle) {
		background: #FFCD00;
		border: 2px solid #FAFAFA;
		border-radius: 50%;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
		cursor: pointer;
		cursor: -webkit-grab;
		cursor: grab;
		height: 29px;
		position: absolute;
		top: -10px;
		touch-action: pan-x;
		width: 29px;
	}

	:global(.slider-mark) {
		left: 0;
		position: absolute;
		top: 30px;
		width: 100%;
	}

	:global(.slider-mark-text) {
		cursor: pointer;
		display: inline-block;
		position: absolute;
		text-align: center;
		vertical-align: middle;
	}
}

@mixin tooltip-style($width) {
	margin-left: calc($width / -2);
	width: $width;
}

.tooltip {
	box-sizing: border-box;
	height: 37px;
	line-height: 37px;
	margin: -60px 0 0 0;
	position: absolute;
	left: 0;
	user-select: none;

	@include design {
		background: var(--color-main);
		border-radius: 3px;
		color: #1E1E1E;
		font-family: Bebas Neue;
		font-size: 20px;
		font-weight: 700;
		letter-spacing: 0.5px;
		text-align: center;
	}

	&.small {
		@include tooltip-style(69px);
	}

	&.big {
		@include tooltip-style(136px);
	}

	.tip {
		bottom: -5px;
		display: block;
		position: absolute;
		width: 12px;
		height: 12px;
		left: 50%;
		margin-left: -6px;

		@include design {
			background: var(--color-main);
			border-radius: 0 0 0 3px;
			clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
			transform: rotate(-45deg);
		}
	}
}

.mark {
	display: flex;
	flex-direction: column;
	align-items: center;

	.dot {
		width: 1px;
		height: 4px;
		border-radius: 1px;
		background: #484848;
	}

	&.big .dot {
		width: 2px;
	}

	.label {
		color: #1E1E1E;
		font-family: Roboto;
		font-size: 0.75em;
		font-weight: 400;
		letter-spacing: 0.171px;
	}
}
