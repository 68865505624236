@import "../../../Layout.scss";
@import "../../../Animation.scss";

.transaction-filter-items {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &.align-to-start {
        justify-content: flex-start;
    }

    @include default-transition();
}

.filter-enter {
    opacity: 0.01;
    max-width: 0px;

    &.filter-enter-active {
        opacity: 1;
        max-width: 256px;
        @include default-transition();
    }
}

.filter-exit {
    opacity: 1;
    max-width: 256px;

    &.filter-exit-active {
        opacity: 0.01;
        max-width: 0px;
        padding: 0px;
        @include default-transition();
    }
}

.align-to-start-filter-enter {
    opacity: 0.01;
    max-width: 0px;
    transform: translateX(64px); // overflow: visible;

    &.align-to-start-filter-enter-active {
        opacity: 1;
        max-width: 256px;
        transform: translateX(0px);
        @include default-transition();
    }
}

.align-to-start-filter-exit {
    opacity: 1;
    max-width: 256px;
    transform: translateX(0px); // overflow: visible;

    &.align-to-start-filter-exit-active {
        opacity: 0.01;
        max-width: 0px;
        padding: 0px;
        transform: translateX(64px);
        @include default-transition();
    }
}