@import "../../Layout.scss";
.vouchers {
    @include in-grid($horizontal: stretch, $vertical: stretch, $row-start: 2);
    @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: auto 1fr);
    background: rgb(250, 250, 250);
    @include mobile {
        @include grid($cols: 16px 1fr 16px, $rows: auto 1fr);
    }
}

.vouchers-header-shadow {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3, $row-span: 1);
    background: rgb(255, 205, 0);
}

.vouchers-header {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2);
    padding: 24px 0px 24px 0px;
    @include mobile {
        padding: 24px 16px 24px 16px;
    }
}

.vouchers-header-title {
    margin: 0px;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 40px;
        font-weight: 300;
        letter-spacing: 0.8px;
        line-height: 48px;
    }
}

.vouchers-loader {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 2);
}

.vouchers-body {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 2);
    @include grid($cols: 1fr, $rows: 1fr);
}

.vouchers-body-content {
    @include in-grid($horizontal: stretch, $vertical: stretch);
    display: flex;
    flex-direction: column;
}