@import "../../../Layout.scss";
@import "../../../Animation.scss";

.virtual_family-links {
    @include in-grid($horizontal: stretch, $vertical: stretch);
    @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: auto 1fr);
    -ms-grid-rows: auto auto; //flexbox in 1fr row has 0 height on ie11
    background: rgb(250, 250, 250);
    // min-height: 100%;
    @include mobile {
        @include grid($cols: auto 1fr auto, $rows: auto auto 1fr);
    }
}

.virtual_family-links-loader {
    @include in-grid($col-start: 2, $row-span: 2);
}

.virtual_family-links-no_content {
    @include in-grid($col-start: 2, $row-span: 2, $horizontal: center, $vertical: end);
}

.virtual_family-links-background_table {
    @include in-grid($horizontal: stretch, $vertical: start, $col-span: 3);
}

.virtual_family-links-table {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2);
}

.virtual_family-links-pagination {
    @include in-grid($horizontal: center, $vertical: start, $col-start: 2, $row-start: 2);
    margin-bottom: 48px;
    @include mobile {
        margin-bottom: 24px;
    }
}

.table-header-cell {
    &.members_table-header_row-toggle_button {
        width: 80px;
    }
    &.members_table-header_row-link {
        width: 400px;
    }
    &.members_table-header_row-expires {
        width: 128px;
    }
    &.members_table-header_row-description {
        width: 196px;
    }
    &.members_table-header_row-status {
        width: 96px;
    }
    &.members_table-header_row-action_buttons {
        width: 120px;
    }
}

.virtual_family-links-table {
    .table-data-cell {
        .expired {
            color: #F10000;
        }
        .inactive {
            color: #909090;
        }
    }
}

.links_table-actions_data_row-button {
    @include fast-transition(opacity);
    margin: 0px 0px 0px 32px;
    opacity: 0.24;
    &:hover {
        opacity: 1;
    }
}

.links_table-actions_data_row-button-img {
    width: 16px;
    height: 16px;
}

.links_table-actions_data_row {
    opacity: 0;
    @include default-transition(opacity);
}

.table-body-row {
    &.links {
        &:hover {
            .links_table-actions_data_row {
                opacity: 1;
            }
        }
    }
}

.links_table-data_row-textarea {
    resize: none;
    width: 100%;
    padding: 0px;
    border: none;
    background: none;
    &:focus {
        outline: none;
    }
}