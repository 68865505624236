@import "../../../Layout.scss";
@import "../../../Animation.scss";
.transactions_expandable-collection {
    &:first-child {
        margin: 48px 0px 48px 0px;
        &.collapsed {
            margin: 48px 0px 0px 0px;
        }
    }
    &:not(:first-child) {
        margin: 0px 0px 48px 0px;
        &.collapsed {
            margin: 0px 0px 0px 0px;
        }
    }
    @include mobile {
        margin: 48px 16px 0px 16px;
    }
}

.transactions_expandable-collection_title {
    margin: 0px;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.59px;
        line-height: 29px;
    }
}

.transactions_collection {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 2, $row-start: 2);
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-items: stretch;
    @include mobile {
        margin: 0px;
        flex-direction: column;
    }
}

.transaction-collection-item {
    @include grid($cols: auto 1fr auto auto auto, $rows: auto 1fr auto);
    height: 103px;
    width: 100%;
    margin: 8px 0px 0px 0px;
    background: white;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
    box-sizing: border-box;
    border-left: 3px solid transparent;
    border-top: 3px solid transparent;
    border-right: 3px solid transparent;
    &.expanded {
        border-left: 3px solid black;
        border-top: 3px solid black;
        border-right: 3px solid black;
    }
    transition: border-color $default-animation-speed $default-animation-curve;
    @include mobile {
        @include grid($cols: auto 1fr auto auto auto, $rows: auto 1fr auto);
        padding-left: 6px;
    }
}

.transaction-collection-item_title {
    @include in-grid($horizontal: start, $vertical: center);
    margin: 24px 0px 0px 24px;
    @include mobile {
        margin: 0;
    }
}

.transaction-collection-item_title-text {
    vertical-align: middle;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.59px;
        line-height: 31px;
    }
    @include mobile {
        font-size: 18px;
    }
}

.transaction-collection-item_download-button {
    vertical-align: middle;
    margin: 0px 0px 0px 8px;
    &:hover {
        .transaction-collection-item_download-button_img {
            opacity: 1;
        }
    }
}

.transaction-collection-item_download-button_img {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    @include fast-transition(opacity);
    opacity: 0.24;
}

.transaction-collection-item_time {
    @include in-grid($horizontal: start, $vertical: start, $row-start: 2);
    margin: 0px 0px 24px 24px;

    @include mobile-s {
        white-space: nowrap;
    }

    @include mobile {
        margin: 0;
    }

    @include design {
        color: #909090;
        font-family: Roboto;
        font-size: 12px;
        letter-spacing: 0.17px;
        line-height: 14px;
    }
}

.transaction-collection-item_text {
    @include in-grid($horizontal: start, $vertical: center, $col-start: 2, $row-span: 2);
    margin: 24px 32px 24px 64px;
    @include mobile {
        margin: 0;
    }

}
.transaction-collection-item_text-station_name {
    @include in-grid($horizontal: start, $vertical: end, $col-start: 2);
    margin: 24px 32px 0px 64px;
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 16px;
        letter-spacing: 0.23px;
        line-height: 31px;
    }
    @include mobile {
        margin: 0;
        font-size: 14px;
        line-height: 1.25;
        @include in-grid($horizontal: start, $vertical: center, $row-start: 2);
    }
    @include mobile-s {
        white-space: nowrap;
    }
}
.transaction-collection-item_text-refueling_parameter {
    @include in-grid($horizontal: start, $vertical: start, $col-start: 2, $row-start: 2);
    margin: 0px 32px 24px 64px;
    @include mobile {
        margin: 0px 0px 16px 16px;
    }
    @include design {
        color: #909090;
        font-family: Roboto;
        font-size: 12px;
        letter-spacing: 0.17px;
        line-height: 14px;
    }
}

.transaction-collection-item_price {
    @include in-grid($col-start: 3, $row-span: 2);
    margin: 0px 24px 0px 0px;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.59px;
        line-height: 31px;
        text-align: right;
    }
    @include mobile {
        margin: 0px;
        margin-right: 6px;
    }    
}

.transaction-collection-item_divider {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 4, $row-span: 2);
    width: 1px;
    background: rgba(0, 0, 0, 0.075);
    margin: 0px 0px 0px 0px;
}

.transaction-collection-item_dropdown-button {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 5, $row-span: 2);
    width: 100px;
    height: 100px;
    @include fast-transition(background-color);
    &.expanded {
        background: black;
        .transaction-collection-item_arrow {
            opacity: 1;
        }
    }
    &:not(.expanded) {
        &:hover {
            .transaction-collection-item_arrow {
                opacity: 1;
            }
        }
    }
}

.transaction-collection-item_arrow {
    vertical-align: middle;
    opacity: 0.24;
    @include fast-transition(opacity);
}

.transactions-table-small-container {
    width: 100%;
    overflow: hidden;
}
