@import '../../../../Layout.scss';
@import '../../../../Animation.scss';

.select-metering-point-modal {
  width: 760px;
  margin: auto;
  box-sizing: border-box;
  background: white;

  @include mobile() {
    width: 100%;
    min-height: 100%;
  }

  @include default-transition(width);

  &.small {
    width: 576px;
    box-sizing: border-box;
  }
  .select-metering-point-modal_title {
    color: black;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 42px;
    line-height: 1;
    font-weight: 300;
    padding-left: 32px;
    padding-top: 32px;
    padding-bottom: 32px;
    margin: 0;
    letter-spacing: 1px;
  }
  .select-metering-point-modal_close-button {
    margin-right: 32px;
    padding: 14px;
    @include mobile() {
      margin-right: 10px;
    }
  }

  .select-metering-point-modal_close-button_img {
    width: 18px;
    height: 18px;
  }
  .select-metering-point-modal-content {
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #E7E7E7;
    background: #fafafa;
    min-height: 200px;
    .select-metering-point-modal-content_content {
      padding: 24px;
      .select-metering-point-modal-content_search-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;

        @include mobile() {
          //flex-direction: column;
          button,
          .select-metering-point-modal-content_search-box{
            //width: 100%;
            margin-left: 0 !important;
          }
          button {
            padding: 14px 14px;
            min-width: 80px !important;
            width: auto;
            margin-top: 0;
          }
          .select-metering-point-modal-content_search-box{
            .search-box{
              width: calc(100% - 58px);
            }
            .cancel-search{
              padding-right: 8px;
            }
          }
        }

        .select-metering-point-modal-content_search-box{
          display: flex;
          flex-grow: 1;
          height: 44px;
          align-items: center;
          .cancel-search{
            height: 100%;
            display: flex;
            align-items: center;
          }
        }

        .btn {
          margin-left: 24px;
          min-width: 160px;
        }
      }
      .search-box {
        font-family: 'Bebas Neue', sans-serif;
        height: 32px;
        width: 100%;
        background: url('../../../../assets/Icon_Search_Gray-16px.svg')
        no-repeat left;
        background-size: 24px;
        padding-left: 32px;
        border: none;
        font-size: 24px;
        &:focus,
        &.has-value {
          background: url('../../../../assets/Icon_Search_Black-16px.svg')
          no-repeat left;
          background-size: 24px;
        }
      }
      .cancel-search {
        display: none;
        cursor: pointer;
      }
      .metering-point-row {
        border: 1.5px solid #E7E7E7;
        height: 48px;
        padding: 16px;
        padding-right: 24px;
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        @include mobile() {
          flex-direction: column;
          height: 96px;
        }

        .metering-point-details {
          padding-left: 60px;
          position: relative;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          &::before {
            content: '';
            background: url('../../Icon_Metering-Point_Inactive.svg') no-repeat center;
            background-size: contain;
            display: inline-block;
            width: 48px;
            height: 48px;
            position: absolute;
            left: 0;
          }
          .metering-point-title {
            font-family: 'Bebas Neue', sans-serif;
            width: 100%;
            font-size: 24px;
            letter-spacing: 0.6px;
            font-weight: bold;
          }
          .metering-point-info {
            font-family: 'Bebas Neue', sans-serif;
            width: 100%;
            font-size: 18px;
            letter-spacing: 0.44px;
            color: #909090;
            font-weight: bold;
          }
        }
        .metering-point-code {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          align-items: flex-end;
          justify-content: center;
          line-height: 1.5;

          @include mobile() {
            align-items: flex-start;
            padding-left: 60px;
          }

          .label {
            font-size: 12px;
            color: #909090;
            font-weight: 600;
          }
          .code {
            font-size: 16px;
            font-weight: 300;
          }
        }
        &.selected {
          border: 1.5px solid #000000;
          .metering-point-details::before {
            background: url('../../Icon_Metering-Point_Active.svg') no-repeat center;
            background-size: contain;
          }
        }

      }
    }
  }
  .select-metering-point-modal-footer {
    background: white;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .metering-points-selected {
      font-family: 'Bebas Neue', sans-serif;
      font-weight: bold;
      font-size: 18px;
      display: flex;
      img {
        margin-left: 24px;
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
    }
    .btn {
      min-width: 160px;
    }

    @include mobile() {
      //flex-direction: column;
      button{
        padding: 14px 14px;
        min-width: 80px !important;
        width: auto;
        margin-top: 0;
      }
      .metering-points-selected{
        height: 44px;
        align-items: center;
        flex-grow: 1;
        padding-right: 8px;
        img{
          margin-left: auto;
        }
      }
    }
  }
}
