@import "../../../Layout.scss";
@import "../../../Animation.scss";
.business-welcome {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.business-welcome-title {
    min-height: 47px;
    margin: 0px 0px 24px 0px;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 38px;
        font-weight: 300;
        letter-spacing: 1.14px;
        line-height: 47px;
    }
    @include default-transition(opacity, transform);
    &:empty {
        opacity: 0;
        transform: translateX(16px);
    }
}

.business-welcome-notification {
    margin: 8px 0px 0px 0px;
}

.business-welcome-notification-img {
    width: 32px;
    height: 32px;
    vertical-align: middle;
}

.business-welcome-notification-title {
    margin: 0px 0px 0px 12px;
    vertical-align: middle;
    @include default-transition(opacity, transform);
    &:empty {
        opacity: 0;
        transform: translateX(16px);
    }
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.2px;
        line-height: 16px;
    }
}