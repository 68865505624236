@import "../Layout";
.nav-title {
    @include in-grid($horizontal: start);
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.logo-container {
    width: 118px;
    height: 48px;
    display: flex;
    background: #ffcd00;
    .header-logo {
        width: 86px;
        height: 48px;
        padding: 0px;
        margin: 0px auto;
        transition: 0.25s;
    }
    &.slide-left {
        .header-logo {
            transform: translateX(-13px)
        }
    }

}

.menu-button {
    width: 48px;
    height: 48px;
    margin: 0px;
    position: relative;
    display: none;
    @include tablet {
        display: flex;
    }
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    .checkbox {
        position: absolute;
        width: 48px;
        height: 48px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0px;
        padding: 0px;
        opacity: 0;
        cursor: pointer;
        &:checked {
            ~.bar {
                &:nth-child(2) {
                    transform:rotate(45deg) scaleX(1.1);
                    transform-origin: 0%;
                }
                &:nth-child(3) {
                    background-color: rgba(0, 0, 0, 0);
                }
                &:nth-child(4) {
                    transform: rotate(-45deg) scaleX(1.1);
                    transform-origin: 0%;
                }
            }
        }
    }
    .bar {
        transition: all 0.3s;
        width: 16px;
        height: 2px;
        background-color: black;
        user-select: none;
        pointer-events: none;
        &:nth-child(2),
        &:nth-child(3) {
            margin-bottom: 4px;
        }
    }
}