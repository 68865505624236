@import "./Animation.scss";
.fade-enter {
    opacity: 0.01;
    &.fade-enter-active {
        opacity: 1;
        @include default-transition();
    }
}

.fade-exit {
    opacity: 1;
    &.fade-exit-active {
        opacity: 0.01;
        @include default-transition();
    }
}

.fast-fade-appear {
    opacity: 0.01;
    &.fast-fade-appear-active {
        opacity: 1;
        @include fast-transition();
    }
}

.fast-fade-enter {
    opacity: 0.01;
    &.fast-fade-enter-active {
        opacity: 1;
        @include fast-transition();
    }
}

.fast-fade-exit {
    opacity: 1;
    &.fast-fade-exit-active {
        opacity: 0.01;
        @include fast-transition();
    }
}

.height-enter {
    &:not(.height-enter-active) {
        height: 0px !important;
        opacity: 0;
        padding: 0px;
    }
    &.height-enter-active {
        @include default-transition(); // overflow: hidden;
    }
}

.height-exit {
    &.height-exit-active {
        @include default-transition();
        height: 0px !important;
        opacity: 0;
        padding: 0px; // overflow: hidden;
    }
}

.slide-bottom-enter {
    transform: translateY(100%);
    &.slide-bottom-enter-active {
        @include default-transition(transform);
        transform: translateY(0%);
    }
}

.slide-bottom-exit {
    transform: translateY(0%);
    &.slide-bottom-exit-active {
        @include default-transition(transform);
        transform: translateY(100%);
    }
}