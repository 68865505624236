@import "../../../Layout.scss";
@import "../../../Animation.scss";
.bills {
    margin: 64px 0px 0px 0px;
    flex: 0 0 calc(50% - 32px);
    @include mobile {
        flex: 0 0 auto;
        margin: 48px 0px 0px 0px;
    }
    &.invisible { 
        visibility: hidden;
    }
}

.bills_title {
    margin: 0px;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.59px;
        line-height: 29px;
    }
}

.bills_show-all-button {
    &:hover {
        .bills_show-all-button_img {
            opacity: 1;
        }
        .bills_show-all-button_text {
            @include design {
                color: black;
            }
        }
    }
}

.bills_show-all-button_text {
    vertical-align: middle;
    @include fast-transition(color);
    @include design {
        color: #C1C1C1;
        font-family: "Bebas Neue";
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.44px;
        line-height: 23px;
        text-align: right;
    }
}

.bills_show-all-button_img {
    vertical-align: middle;
    margin: 0px 0px 0px 8px;
    opacity: 0.24;
    @include fast-transition(opacity);
}

.business-overview-transactions-content {
    @include grid($cols: 1fr, $rows: 1fr);
}

.business-overview-transactions-loader {
    @include in-grid();
    height: 256px;
}

.business-overview-bills-collection {
    @include in-grid($horizontal: stretch, $vertical: stretch);
    margin: 24px 0px 0px 0px;
    @include mobile {
        margin: 16px 0px 0px 0px;
        .bill-item {
            @include grid($cols: auto 1fr auto, $rows: auto 1fr);
            height: auto;
        }
        .bill-item_type {
            @include in-grid($horizontal: start, $vertical: center, $col-start: 2);
        }
        .bill-item_download-button {
            display: none;
        }
        .bill-item_title {
            @include in-grid($horizontal: start, $vertical: center, $col-start: 2, $row-start: 2);
            @include design {
                font-size: 20px;
                letter-spacing: 0.49px;
                line-height: 24px;
            }
        }
        .bill-item_total-info {
            @include in-grid($horizontal: end, $col-start: 3);
        }
        .bill-item_total {
            @include in-grid($horizontal: end, $col-start: 3, $row-start: 2);
            @include design {
                font-size: 20px;
                letter-spacing: 0.49px;
                line-height: 24px;
            }
        }
        .bill-item_date-info {
            display: none;
        }
        .bill-item_date {
            display: none;
        }
        .bill-item_payment-date-info {
            display: none;
        }
        .bill-item_payment-date {
            display: none;
        }
        .bill-item_paid-info {
            display: none;
        }
        .bill-item_paid {
            display: none;
        }
        .bill-item_hover {
            display: none;
        }
    }
}