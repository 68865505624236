@import '../../Layout.scss';
@import '../../Animation.scss';

.overview {
    @include in-grid($horizontal: stretch, $vertical: stretch, $row-start: 2);
    @include grid($cols: minmax(16px, 1fr) 1024px minmax(16px, 1fr), $rows: auto auto auto 1fr 24px auto);
    -ms-grid-rows: auto auto auto auto 24px auto; //flexbox in 1fr row has 0 height on ie11
    background: rgb(250, 250, 250);
    @include tablet {
        @include grid($cols: 16px 1fr 16px, $rows: auto auto auto 1fr auto auto);
    }
}

.headerShadow {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3, $row-span: 2);
    background: white;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.05); // @include default-transition(height);

    &.topad {
        margin: 0px 0px 72px 0px;
        @include tablet {
            margin: 0px;
        }
    }
}

.header {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2);
    height: 248px;
    padding: 32px 0px 48px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include tablet {
        height: auto;
        padding: 24px 16px 24px 16px;
        flex-direction: column;
    }
}

.headerLoader {
    @include in-grid($horizontal: center, $vertical: center, $col-start: 2);
}

.headerOverhang {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 2, $row-span: 2);
    -ms-grid-row-span: 1; //ie11 overhang fix
    height: 144px;
    overflow: hidden;
    @include tablet {
        display: none;
    }
}

.body {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 4, $row-span: 2);

    flex-wrap: wrap;
    padding: 64px 0px 64px 0px;
    display: flex;
    gap: 64px;

    @include tablet {
        padding: 48px 0px 16px 0px;
        flex-direction: column;
    }
}

.body > :global(.olerex-plus) {
    width: 480px;

    @include tablet {
        width: auto;
    }
}

.body > :global(.user-details) {
    margin: 0; // TODO: Remove in UserDetails component?
    width: 480px;

    @include tablet {
        width: auto;
    }
}

.body > :global(.person-overview-cards) {
    margin: 0; // TODO: Remove in Cards/CreditCards component?
    width: 480px;

    @include tablet {
        width: auto;
    }
}

.footerShadow {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 1, $col-span: 3, $row-start: 6);
    box-shadow: 0 -7px 10px 0 rgba(0, 0, 0, 0.05);
    z-index: 0;
    background: white;
}

.footer {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 6);
}

:global(.welcome-text-enter) {
    .welcome-title,
    .welcome-text {
        @include default-transition;
        transform: translateX(16px);
    }

    .welcome-text-enter-active {
        .welcome-title,
        .welcome-text {
            transform: translateX(0px);
        }
    }
}

.adBackground {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-span: 3, $row-start: 7);
    background: black;
}

.ad {
    @include in-grid($horizontal: stretch, $vertical: stretch, $col-start: 2, $row-start: 7);
    padding: 48px 0px 48px 0px;
    @include tablet {
        padding: 16px 0px 16px 0px;
    }
}

.ad img {
    width: 100%;
    user-select: none;
    cursor: pointer;
}
