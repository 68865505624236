@import "../Layout.scss";
@import "../Animation.scss";
.nav-user-controls {
    @include in-grid($horizontal: end, $col-start: 3);
    height: 32px;
    position: relative;
    display: flex;
    align-items: stretch;
    justify-items: center;
    z-index: 2;
    @include tablet {
        height: 44px;
        align-self: center;
        &:not(.drawer-open) {
            display: none;
        }
    }
}

.user-notifications {
    width: 32px;
    background: rgb(255, 95, 95);
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include tablet {
        width: 44px;
        display: none;
    }
    visibility: hidden;
}

.user-notifications-img {
    width: 16px;
    height: 16px;
}

.user-dropdown-button {
    box-sizing: border-box;
    margin: 0px 0px 0px 8px;
    padding: 0px 12px 0px 12px;
    display: flex;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.075);
    border-radius: 2px;
    cursor: pointer;
    user-select: none;
    min-width: 148px;
    @include tablet {
        border: 1px solid rgb(230, 184, 0);
    }
    &.dropdown-open {
        background: black;
        .user-dropdown-button-content {
            .user-dropdown-button-text {
                color: white;
            }
        }
    }
    @include tablet {
        margin: 0px 26px 0px 26px;
    }
}

.user-dropdown-button-content {
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user-dropdown-button-icon {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    pointer-events: none;
}

.user-dropdown-button-text {
    margin: 0px 0px 0px 12px;
    vertical-align: middle;
    display: inline-block;
    min-width: 62px;
    max-width: calc(100vw / 2 - 460px);
    @include tablet {
        max-width: 30vw;
    }
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include design {
        color: #000000;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.35px;
        line-height: 16px;
    }
}

.user-dropdown-button-chevron {
    margin: 0px 0px 0px 8px;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    pointer-events: none;
}

.user-dropdown {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 52px;
    @include tablet {
        top: auto;
        bottom: 60px;
    }
    display: flex;
    flex-direction: column;
}

.user-dropdown-item {
    height: 48px;
    margin: 0px 0px 4px 0px;
    padding: 0px 16px 0px 0px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    border-radius: 2px;
    cursor: pointer;
    user-select: none;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.07);
}

.user-dropdown-item-img-container {
    width: 48px;
    min-width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(250, 250, 250);
}

.user-dropdown-item-img {
    width: 16px;
    height: 16px;
    pointer-events: none;
}

.user-dropdown-item-title {
    margin: 0px 0px 0px 16px;
    flex: 1 1 auto;
    user-select: none;
    @include design {
        color: #484848;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.2px;
        line-height: 16px;
        font-weight: normal;
    }
    @include sm-screen {
        margin-left: 12px;
    }
}

.user-dropdown-chevron {
    width: 8px;
    height: 8px;
    align-self: center;
    pointer-events: none;
}

.user-dropdown-counter-container {
    height: 24px;
    min-width: 24px;
    background: rgb(255, 205, 0);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-dropdown-count {
    margin: 2px 0px 0px 0px;
    box-sizing: border-box;
    @include design {
        color: #000000;
        font-family: "Bebas Neue";
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.39px;
        line-height: 19px;
        text-align: center;
    }
}

.nav-item-appear {
    margin: 0px 0px 32px 0px;
    &.nav-item-appear-active {
        margin: 0px 0px 4px 0px;
        @include default-transition(margin);
    }
}
