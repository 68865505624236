@import "../../../Animation.scss";
@import "../../../Layout.scss";

.modal {
    width: 576px;
    margin: auto;
    padding: 32px;
    box-sizing: border-box;
    background: rgb(30, 30, 30);

    @include mobile {
        width: 100%;
        max-width: 576px;
    }

    h1 {
        margin: 0px;

        @include design {
            color: var(--color-main);
            font-family: "Bebas Neue";
            font-size: 32px;
            font-weight: bold;
            letter-spacing: 0.78px;
            line-height: 38px;
        }
    }

    button.close img {
        width: 12px;
        height: 12px;
    }

    .content {
        .userRows {
            padding: 23px 0;

            .empty {
                @include design {
                    opacity: 0.6;
                    color: #fff;
                    font-family: "Roboto";
                    font-size: 1em;
                    font-weight: 300;
                    letter-spacing: 0.78px;
                    font-size: 0.875em;
                }
            }
        }

        .userRow {
            @include grid($cols: auto max-content, $rows: auto 1fr);
            align-items: center;
            grid-template-areas:
                "name remove"
                "code remove";

            padding: 10px 0;
            height: 38px;

            @include design {
                color: #fff;
                font-family: "Roboto";
                font-size: 1em;
                font-weight: 300;
                letter-spacing: 0.78px;
            }

            .name {
                grid-area: name;
            }

            .code {
                grid-area: code;

                @include design {
                    opacity: 0.6;
                    font-size: 0.875em;
                }
            }

            .remove {
                grid-area: remove;

                @include fast-transition();
                @include design {
                    color: #fff;
                    font-family: "Roboto";
                    font-size: 1em;
                    font-weight: 300;
                    letter-spacing: 0.78px;
                    text-align: right;
                }

                &:hover {
                    color: #FF5F5F;
                }
            }
        }

        .loader {
            height: 128px;
        }

        .options {
            border-top: 1px solid #393939;
            padding: 32px 0 0 0;
        }
    }
}

.checkbox {
    align-items: center;
    display: flex;
    gap: 24px;

    @include design {
        color: #fff;
        font-family: Roboto;
        font-size: 1em;
        font-weight: 300;
        letter-spacing: 0.2px;
    }

    .box {
        content: "";
        display: inline-block;
        flex: 0 0 auto;
        height: 20px;
        width: 20px;

        @include default-transition();
        @include design {
            background: var(--color-bg);
            border: 1px solid var(--color-text-3);
            border-radius: 6px;
        }
    }

    .label {
        color: #fff;
        line-height: 22px;
    }

    input {
        display: none;
    }

    input:checked + .box {
        background-color: var(--color-main);
        background-image: url('../../../wholesale/assets/Icon_Check_White.svg');
        background-position: center;
        background-repeat: no-repeat;
        border-color: var(--color-main);
    }
}

