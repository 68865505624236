@import "../Layout.scss";
@import "../Animation.scss";
.nav-items {
    @include in-grid($col-start: 2);
    margin: auto;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include tablet {
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        &:not(.drawer-open) {
            display: none;
        }
    }
    @include default-transition();
}

.nav-item {
    display: flex;
    align-items: center;
    margin: 0px 16px 0px 16px;
    text-decoration: none;
    user-select: none;
    flex: 1 0 auto;
    white-space: nowrap;
    @include sm-screen {
        margin: 0px 12px 0px 12px;
    }
    @include fast-transition();
    @include tablet {
        margin: auto 16px 0px 16px;
        .nav-item-title {
            margin: 0px 0px 0px 16px;
        }
    }
    &.desktop-only {
        @include tablet {
            display: none;
        }
    }
    &.mobile-only {
        display: none;
        @include tablet {
            display: flex;
        }
    }
    .nav-item-img {
        opacity: 0;
        &:not(.active) {
            opacity: 1;
        }
    }
    &:hover {
        .nav-item-title {
            opacity: 1;
        }
        .nav-item-img {
            opacity: 0;
            &.active {
                opacity: 1;
            }
        }
    }
    &.active {
        .nav-item-title {
            opacity: 1;
        }
    }
}

.nav-item-img-container {
    display: inline-grid;
    vertical-align: middle;
    @include tablet {
        width: 32px;
        height: 32px;
        background: white;
        border-radius: 2px;
    }
    &.active {
        .nav-item-img {
            opacity: 0;
            &.active {
                opacity: 1;
            }
        }
    }
}

.nav-item-img {
    @include in-grid();
    @include fast-transition();
}

.nav-item-title {
    margin: 0px 0px 0px 12px;
    text-decoration: none;
    vertical-align: middle;
    @include design {
        color: #1E1E1E;
        font-family: "Bebas Neue";
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.49px;
        &.inactive {
            opacity: 0.5;
        }
    }
    @include tablet {
        margin: 12px 0px 0px 0px;
        @include design {
            color: black;
            font-family: "Bebas Neue";
            font-size: 38px;
            font-weight: 300;
            letter-spacing: 0.93px;
        }
    }
    @include fast-transition();
}

.item-enter {
    opacity: 0.01; // flex: 0 0 0px;
    max-width: 0px;
    margin: 0px;
    transform: translateX(64px);
    &.item-enter-active {
        opacity: 1; // flex: 1 1 128px;
        max-width: 128px;
        margin: 0px 16px 0px 16px;
        transform: translateX(0px);
        @include default-transition();
    }
}

.item-exit {
    opacity: 1;
    max-width: 128px;
    &.item-exit-active {
        opacity: 0.01;
        max-width: 0px;
        margin: 0px;
        transform: translateX(64px);
        @include default-transition();
    }
}